import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router
} from '@angular/router';
import {
  Subscription
} from 'rxjs/Subscription';
import {
  NavigationService,
  Menu
} from 'src/app/services/navigation/navigation.service'

@Component( {
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: [ './menu.component.css' ]
} )

export class MenuComponent implements OnInit {

  //init
  menuInfos: Array < Menu > ;
  subscription: Subscription;

  constructor(
    private router: Router,
    private navigationService: NavigationService
  ) {}

  ngOnInit() {

    // init menu
    this.onMenuChange();

    // event get menuInfos
    this.subscription = this.navigationService.navigationEvent.subscribe( indexs => {

      this.onMenuChange( indexs.navigationIndex, indexs.menuIndex );
    } );
  }

  ngOnDestroy() {

    this.subscription.unsubscribe();
  }

  // get
  get getMenuIndex() {

    return this.navigationService.menuIndex;
  }

  get getNavigationIndex() {

    return this.navigationService.navigationIndex;
  }

  getActive( index: number ) {

    return index == this.getMenuIndex;
  }

  // action
  onMenuChange( navigationIndex: number = this.getNavigationIndex, menuIndex: number = this.getMenuIndex ) {

    this.menuInfos = this.navigationService.getMenus( navigationIndex );

    // init active
    this.onMenuSelect( menuIndex, this.menuInfos[ menuIndex ].router );
  }

  onMenuSelect( index: number, router: any ) {

    // set menu
    this.navigationService.setMenuIndex( index );

    // navigate router
    this.router.navigate( router );
  }
}
