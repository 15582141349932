import {
  Injectable,
  EventEmitter
} from '@angular/core';
import {
  Config
} from 'src/app/app.config';
import {
  TranslatedService
} from 'src/app/services/translated/translated.service';

export class Menu {

  constructor( public navigate: string, public menu: string, public router: any ) {}
}

export class Navigation {
  
  constructor( public navigate: string, public menus: Menu[] ) {}
}

@Injectable()
export class NavigationService {

  // init
  menuIndex: number;
  navigations: Array < Navigation > ;
  navigationIndex: number;
  navigationEvent: EventEmitter < any > ;

  constructor(
    private translatedService: TranslatedService
  ) {

    // init
    this.menuIndex = 0;
    this.navigations = new Array();
    this.navigationIndex = 0;
    this.navigationEvent = new EventEmitter();

    // navigate
    for ( let nk in Config.page ) {

      // get menu
      var menu = Config.page[ nk ];
      var menus: Array < Menu > = new Array();

      for ( let mk in menu ) {

        // add menu
        menus.push( new Menu( nk, mk, menu[ mk ].route || [ menu[ mk ].path ] ) );
      }

      // add navigation
      this.navigations.push( new Navigation( nk, menus ) );
    }
  }

  get getIndexs() {

    return {

      menuIndex: this.menuIndex,
      navigationIndex: this.navigationIndex
    }
  }

  getMenu(): Menu {

    return this.navigations[ this.navigationIndex ].menus[ this.menuIndex ];
  }

  getMenus( navigationIndex: number ): Array < Menu > {

    this.navigationIndex = navigationIndex;

    return this.navigations[ navigationIndex ].menus;
  }

  getNavigations(): Array < Navigation > {

    return this.navigations;
  }

  setMenuIndex( menuIndex ) {
    
    // set translate
    this.translatedService.setOpen( false );

    this.menuIndex = menuIndex;
  }

  setIndexs( menuIndex, navigationIndex ) {

    // set translate
    this.translatedService.setOpen( false );

    // set index
    this.menuIndex = menuIndex;
    this.navigationIndex = navigationIndex;
  }
}