import {
  OnInit,
  Component
} from '@angular/core';
import {
  Config
} from 'src/app/app.config';
import {
  DateTime
} from 'src/app/pipe/pipe';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  BoardInfo,
  CategoryInfo,
  StatisticInfo,
  StatisticInfos,
  StsBoardRequest,
  GetBoardsRequest,
  GetCategoriesRequest,
} from 'src/app/services/http/http.classes';
import {
  HIGH_FORMAT
} from 'src/app/directive/highchart.directive';
import {
  MaterializeClose
} from 'src/app/directive/materialize.directive';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-statistic-post',
  styleUrls: [ './statistic-post.component.css' ],
  templateUrl: './statistic-post.component.html'
} )
export class StatisticPostComponent implements OnInit {

  // enum
  HIGH_FORMAT = HIGH_FORMAT;

  // public
  public req: any;
  public res: any;
  public page: any;

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // data
    this.req = {

      getBoard: new StsBoardRequest(),
      getBoards: new GetBoardsRequest()
    }

    this.res = {

      boardInfo: new BoardInfo( {

        manager: this.translateService.instant( 'caption.column.statistic.date.total' )
      } ),
      boardInfos: new Array < BoardInfo > (),
      categoryInfos: new Array < CategoryInfo > (),
      statisticInfos: new Array < StatisticInfos > ()
    }

    this.page = {

      content: new PagerData()
    }

    // date
    var date = new DateTime().transform( new Date(), null, 'yyyy-MM-dd' );

    // init
    this.req.getBoard.onInit( {

      ended: date,
      started: date
    } );
  }

  ngOnInit() {

    // get chain
    this.getChain();
  }

  ngOnDateSet() {

    setTimeout( () => {

      this.ngOnDateClose( new MaterializeClose( $( '#started' ) ) );
      this.ngOnDateClose( new MaterializeClose( $( '#ended' ) ) );

    }, 10 );
  }

  ngOnDateClose( close: MaterializeClose ) {

    var date = $( close.target ).val();

    // check id
    switch ( $( close.target ).attr( 'id' ) ) {

      case 'ended': {

        // set max date 
        M.Datepicker.getInstance( $( '#started' ) ).options.maxDate = new Date( date );

        if ( close.update ) {

          this.getStatistic();
        }
        break;
      }
      case 'started': {

        // set min date 
        M.Datepicker.getInstance( $( '#ended' ) ).options.minDate = new Date( date );

        if ( close.update ) {

          this.getStatistic();
        }
        break;
      }
    }
  }

  ngOnDateOption( init: Function ) {

    var months = this.translateService.instant( 'caption.date.month' );
    var weekdays = this.translateService.instant( 'caption.date.weekday' );

    init( {

      i18n: {

        done: this.translateService.instant( 'caption.button.done' ),
        clear: this.translateService.instant( 'caption.button.clear' ),
        cancel: this.translateService.instant( 'caption.button.cancel' ),
        months: months,
        monthsShort: months,
        weekdays: weekdays,
        weekdaysShort: weekdays,
        weekdaysAbbrev: weekdays,
      },
      format: 'yyyy-mm-dd',
      minDate: '',
      maxDate: ''
    } );

    // set min & max date
    this.ngOnDateSet();
  }

  // get 
  getTotal( statisticInfos: Array < StatisticInfo > ) {

    if ( statisticInfos.empty() ) return 0;

    return statisticInfos.reduce( function( prev: StatisticInfo, current: StatisticInfo ) {

      return new StatisticInfo( {

        counted: prev.counted + current.counted
      } )
    } ).counted;
  }

  get getStatus() {

    if ( typeof this.req.getBoard.statisticType == 'string' ) {

      return parseInt( this.req.getBoard.statisticType );
    }

    return this.req.getBoard.statisticType;
  }
  
  // http
  getChain() {

    Promise.resolve().then( () => {

      return this.getCategories();

    } ).then( () => {

      // get board
      this.getBoards();

    } ).catch( ( message: string ) => {

      if ( message ) return alert( message );
    } );
  }

  getBoards( index: number = 1, limit: number = Config.limit ) {

    Promise.resolve().then( () => {

      return new Promise( ( resolve, reject ) => {

        // set contents
        this.req.getBoards.onInit( {

          index: limit * ( index - 1 ),
          limit: limit
        } );

        this.httpService.getBoards( this.req.getBoards ).subscribe( response => {

          if ( response.code ) return reject( response.message );

          // set data
          this.res.boardInfos = response.boardInfos;

          // set request
          this.req.getBoard.onInit( {

            boardId: Config.index
          } );

          // set page
          this.res.page = new PagerData( response.pageInfo, this.req.getBoards.index );

          resolve();
        } );
      } );
    } ).then( () => {

      // set board
      this.req.getBoard.boardId = Config.index;

      // set current
      this.res.boardInfo = new BoardInfo( {

        manager: this.translateService.instant( 'caption.column.statistic.date.total' )
      } );

      // get statistic
      this.getStatistic();

    } ).catch( ( message: string ) => {

      if ( message ) alert( message );
    } );
  }

  getStatistic() {

    // set category
    this.req.getBoard.categoryType = this.req.getBoards.categoryType;

    // get request
    this.httpService.stsBoard( this.req.getBoard ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.statisticInfos = response.statisticInfos;
    } );
  }

  getCategories() {

    return new Promise( ( resolve, reject ) => {

      this.httpService.getCategories( new GetCategoriesRequest() ).subscribe( response => {

        if ( response.code ) return reject( response.message );

        // set data
        this.res.categoryInfos = response.categoryInfos;

        resolve();
      } );
    } )
  }

  // action
  onBoard( boardInfo: BoardInfo = null ) {

    // set board
    this.req.getBoard.boardId = boardInfo ? boardInfo.boardId : Config.index;

    // set current
    this.res.boardInfo = boardInfo ? boardInfo : new BoardInfo( {

      manager: this.translateService.instant( 'caption.column.statistic.date.total' )
    } );

    // get statistic
    this.getStatistic();
  }
}