import {
  OnInit,
  Component
} from '@angular/core';
import {
  Subscription
} from 'rxjs';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  Result,
  EulaInfo,
  UpdEulaRequest,
  GetEulasRequest
} from 'src/app/services/http/http.classes';
import {
  TranslatedService
} from 'src/app/services/translated/translated.service';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-setting-eula',
  styleUrls: [ './setting-eula.component.css' ],
  templateUrl: './setting-eula.component.html'
} )
export class SettingEulaComponent implements OnInit {

  // data
  req: any;
  res: any;

  selected: number;
  subscripton: Subscription;

  constructor(
    private httpService: HttpService,
    private translatedService: TranslatedService
  ) {

    // data
    this.req = {

      updEula: new UpdEulaRequest(),
      getEulas: new GetEulasRequest()
    }

    this.res = {

      eulaInfos: new Array < EulaInfo > ()
    }

    // init
    this.selected = 0;

    // translate
    this.subscripton = this.translatedService.pull.subscribe( () => {

      this.getEulas();
    } );
  }

  ngOnInit() {

    // get eula
    this.getEulas();

    // set translate
    this.translatedService.setOpen( true );
  }

  ngOnDestroy() {

    this.subscripton.unsubscribe();
  }

  get getRequest() {

    return this.req.updEula;
  };

  get getSubject() {

    return this.res.eulaInfos.empty() ? null : this.res.eulaInfos[ this.selected ].name;
  }

  // http
  getEulas() {

    this.httpService.getEulas( this.req.getEulas ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.eulaInfos = response.eulaInfos;
    } );
  }

  // action
  onEula( eulaInfo ? : EulaInfo, selected ? : number ) {

    // update
    this.selected = selected;
    this.req.updEula = new UpdEulaRequest();
    this.req.updEula.onInit( eulaInfo );

    // open
    M.Modal.getInstance( $( '.modal' ) ).open();
  }

  onEulaSubmit( form: any ) {

    // check valid
    if ( form.valid == false ) {

      return;
    }

    this.httpService.updEula( this.req.updEula ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // update
      this.res.eulaInfos[ this.selected ] = response.eulaInfo;

      // close
      M.Modal.getInstance( $( '.modal' ) ).close();
    } );
  }
}