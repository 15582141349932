import {
  Router
} from '@angular/router';
import {
  OnInit,
  Component
} from '@angular/core';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  Result,
  GetInRequest
} from 'src/app/services/http/http.classes';
import {
  PreloaderService
} from 'src/app/services/preloader/preloader.service';

@Component( {
  selector: 'app-sign',
  styleUrls: [ './sign.component.css' ],
  templateUrl: './sign.component.html'
} )

export class SignComponent implements OnInit {

  constructor(
    private router: Router,
    private httpService: HttpService,
    private preloaderService: PreloaderService
  ) {}

  ngOnInit() {
  }

  onSubmitSignIn( {
    value,
    valid
  }: {
    value: GetInRequest,
    valid: boolean
  } ) {

    // check valid
    if ( valid == false ) {

      return;
    }

    this.preloaderService.start();
    this.httpService.getIn( value ).subscribe( response => {

      if ( response.code === Result.ADMIN_SUCCESS ) {

        this.router.navigate( [ Config.base.main.path ] );
      } else {

        alert( response.message );
      }
    }, error => {}, () => {

      this.preloaderService.stop();
    } );
  }
}
