import {
  Subject
} from "rxjs";

export class PreloaderService {

  public status: Subject < boolean > = new Subject();
  public active: boolean = false;
  private stack: number = 0;

  start() {

    this.stack++;

    if ( this.stack == 1 ) {

      this.active = true;
      this.status.next( true );
    }
  }

  stop() {

    this.stack--;

    if ( this.stack == 0 ) {

      this.active = false;
      this.status.next( false );
    }
  }
}