import {
  NgModule
} from '@angular/core';
import {
  DatePipe
} from '@angular/common';

import {
  AppRoutingModule
} from './app.routing.module';

import {
  PreloaderService
} from './services/preloader/preloader.service';

import {
  AppComponent
} from './components/root/app.component';
import {
  PreloaderComponent
} from './components/preloader/preloader.component'; // 로딩 UI

import './prototypes/array'; // Number 확장 함수
import './prototypes/number'; // Array 확장 함수

@NgModule( {
  imports: [
    AppRoutingModule
  ],
  exports: [],
  providers: [
    DatePipe,
    PreloaderService
  ],
  bootstrap: [
    AppComponent
  ],
  declarations: [
    AppComponent,
    PreloaderComponent
  ]
} )

export class AppModule {}