import {
  Input,
  Directive,
  OnChanges,
  ElementRef
} from '@angular/core';
import {
  PollInfo
} from 'src/app/services/http/http.classes';
import {
  Editor,
  EditorInfo,
  EditorService,
} from 'src/app/services/editor/editor.service';

@Directive( {
  selector: '[editorInit], [editorInit.data], [editorOption.poll], [editorOption.media], [editorOption.limit], [editorOption.readonly], [editorOption.imageonly], [editorOption.videoonly]'

} )
export class EditorInit implements OnChanges {

  // init
  private parent: Element;

  // editor
  @Input( 'editorInit' ) private data: EditorInfo[];
  @Input( 'editorOption.poll' ) private poll: PollInfo[];
  @Input( 'editorOption.limit' ) private limit: number = 9999;
  @Input( 'editorOption.media' ) private media: boolean = false;
  @Input( 'editorOption.readonly' ) private readonly: boolean = false;
  @Input( 'editorOption.imageonly' ) private imageonly: boolean = false;
  @Input( 'editorOption.videoonly' ) private videoonly: boolean = false;

  constructor(
    private elementRef: ElementRef,
    private editorService: EditorService,
  ) {

    // set element
    this.parent = this.elementRef.nativeElement

    // set uuid
    this.parent.setAttribute( 'data-uuid', this.generate );
  }

  ngOnChanges() {

    // set event
    this.editorService.onInit( new Editor( {

      uuid: this.uuid,
      data: this.data,
      poll: this.poll,
      limit: this.limit,
      media: this.media,
      parent: this.parent,
      readonly: this.readonly,
      imageonly: this.imageonly,
      videoonly: this.videoonly
    } ) );
  }

  get uuid() {

    return this.parent.getAttribute( 'data-uuid' );
  }

  get generate() {

    var serial = function() {

      return Math.floor( ( 1 + Math.random() ) * 0x10000 ).toString( 16 ).substring( 1 );
    }

    return `${ serial() + serial() }-${ serial() }-${ serial() }-${ serial() }-${ serial() + serial() + serial() }`;
  }
}