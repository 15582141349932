import {
  OnInit,
  Component
} from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl
} from '@angular/forms';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  Result,
  ManagerInfo,
  SetManagerRequest,
  DelManagerRequest,
  UpdManagerRequest,
  GetManagersRequest
} from 'src/app/services/http/http.classes';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-setting-manager',
  styleUrls: [ './setting-manager.component.css' ],
  templateUrl: './setting-manager.component.html'
} )
export class SettingManagerComponent implements OnInit {

  // data
  req: any;
  res: any;
  checked: boolean;
  selected: number;

  // form
  managerForm: FormGroup;

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService ) {

    // data
    this.req = {

      setManager: new SetManagerRequest(),
      updManager: new UpdManagerRequest()
    }

    this.res = {

      managerInfos: new Array < ManagerInfo > ()
    }

    // init
    this.checked = false;
    this.selected = 0;

    // form
    this.managerForm = new FormGroup( {

      checkBox: new FormArray( [] )
    } );
  }

  ngOnInit() {

    // get managers
    this.getManagers();
  }

  ngOnDestroy() {}

  // ui
  createdUserInterface() {

    this.managerForm.controls.checkBox = new FormArray( [] );

    // form array create
    this.res.managerInfos.forEach( () => {

      ( < FormArray > this.managerForm.controls.checkBox ).controls.push( new FormControl() );
    } );

    // init
    this.checked = false;
  }

  // get
  get getInsert() {

    return this.req.setManager ? true : false;
  }

  get getRequest() {

    return this.req.setManager ? this.req.setManager : this.req.updManager;
  }

  // http
  getManagers() {

    this.httpService.getManagers( new GetManagersRequest() ).subscribe( response => {

      if ( response.code ) return alert( response.message );
      
      // set data
      this.res.managerInfos = response.managerInfos;

      // update user interface
      this.createdUserInterface();
    } );
  }

  // action
  onRemove() {

    if ( confirm( this.translateService.instant( 'message.remove' ) ) == false ) {

      return;
    }

    // init
    var managerInfos = new Array < ManagerInfo > ();

    ( < FormArray > this.managerForm.controls.checkBox ).controls.forEach( ( checkbox, index ) => {

      // checked
      if ( checkbox.value ) {

        // push manager
        managerInfos.push( this.res.managerInfos[ index ] );
      }
    } );

    // check manager
    if ( managerInfos.empty() ) {

      return alert( this.translateService.instant( 'message.select' ) );
    }

    // delete manager
    this.httpService.delManager( new DelManagerRequest( managerInfos ) ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      this.getManagers();
    } );
  }

  onSubmit( form: any ) {

    // check valid
    if ( form.valid == false ) {

      return;
    }

    if ( this.getInsert ) {

      this.httpService.setManager( this.req.setManager ).subscribe( response => {

        if ( response.code ) {

          return alert( response.message );
        }

        // close
        M.Modal.getInstance( $( '.modal' ) ).close();

        // get managers
        this.getManagers();
      } );

      return;
    }

    this.httpService.updManager( this.req.updManager ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // update
      this.res.managerInfos[ this.selected ] = response.managerInfo;

      // close
      M.Modal.getInstance( $( '.modal' ) ).close();
    } );
  }

  onManager( managerInfo ? : ManagerInfo, selected ? : number ) {

    if ( managerInfo ) {

      // update
      this.selected = selected;
      this.req.updManager = new UpdManagerRequest();
      this.req.updManager.onInit( managerInfo );
      this.req.setManager = null;
    } else {

      // insert
      this.req.updManager = null;
      this.req.setManager = new SetManagerRequest();
    }

    // open
    M.Modal.getInstance( $( '.modal' ) ).open();
  }

  onCheckBox( event: any, total: boolean ) {

    let check: boolean = event.target.checked;

    for ( let checkbox of ( < FormArray > this.managerForm.controls.checkBox ).controls ) {

      if ( total ) {

        checkbox.setValue( event.target.checked );
      } else {

        if ( checkbox.value ) {

          check = true;
          break;
        }
      }
    }

    this.checked = check;
  }
}