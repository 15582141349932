import {
  NgModule
} from '@angular/core';
import {
  HttpModule
} from '@angular/http';
import {
  HttpClient,
  HttpClientModule
} from '@angular/common/http';
import {
  Routes,
  RouterModule
} from '@angular/router';
import {
  EditorModule
} from '@tinymce/tinymce-angular';
import {
  BrowserModule
} from '@angular/platform-browser'
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import {
  TranslateModule,
  TranslateLoader
} from '@ngx-translate/core';
import {
  TranslateHttpLoader
} from '@ngx-translate/http-loader'; // 언어팩 모듈 ( /web/admin.real/src/assets/i18n/* )

// config
import {
  Config
} from './app.config'; // 설정 파일

// root
import {
  SignComponent
} from './components/sign/sign.component'; // 로그인
import {
  MainComponent
} from './components/main/main.component'; // 메인 화면 구성

// public
import {
  MenuComponent
} from './components/menu/menu.component'; // 메인 화면 좌측
import {
  HeaderComponent
} from './components/header/header.component'; // 메인 화면 상단 아래 우측 언어 선택 ( CMS 설명 글 )

// editor
import {
  EditorComponent
} from './components/editor/editor.component'; 
/* 에디터 기능 
1. 텍스트, 이미지, 유튜브 혼합 ex: 게시물 → 포스트 → STAR → 등록, 내용 *
2. 이미지 전용 ex: 게시물 → 포스트 → STAR → 등록, 커버 이미지 *
3. 유튜브 전용 ex: 게시물 → 포스트 → THE STAR TV → 등록, 대표 미디어 * 
*/

// pagination
import {
  PaginationComponent
} from './components/pagination/pagination.component'; // 페이지 조회 기능 ( ex: 게시물 목록 하단 페이지 )
import {
  NavigationComponent
} from './components/navigation/navigation.component'; // 메인 화면 상단

// directive
import {
  EditorInit
} from './directive/editor.directive' // 에디터 디렉티브 ( web/admin.real/angular.json - https://www.tiny.cloud )
import {
  HighchartInit
} from './directive/highchart.directive'; // 통계 관련 디렉티브 ( web/admin.real/angular.json - https://www.highcharts.com )
import {
  PaginationInit
} from './directive/pagination.directive'; // 페이지 조회 디렉티브
import {
  MaterializeInit
} from './directive/materialize.directive'; // Materializecss 디렉티브 ( web/admin/angular.json - https://materializecss.com/ )

// service
import {
  HttpGuard
} from './services/http/http.guard'; // 세션확인, 데이터베이스 언어 조회 및 언어팩 적용
import {
  HttpService
} from './services/http/http.service'; // API 통신
import {
  EditorService
} from './services/editor/editor.service'; // 에디터 이벤트 처리
import {
  TranslatedService
} from './services/translated/translated.service'; // 언어팩 이벤트 처리
import {
  PaginationService
} from './services/pagination/pagination.service'; // 페이지 조회 이벤트 처리
import {
  NavigationService
} from './services/navigation/navigation.service'; // 메인 화면 상단 이벤트 처리

// pipe
import {
  Src,
  Url,
  Open,
  Link,
  Blank,
  Media,
  Insert,
  DateTime,
  Transparent
} from './pipe/pipe';

// page
import {
  SettingEulaComponent
} from './components/page/setting-eula/setting-eula.component'; // 설정 → 약관
import {
  SettingManagerComponent
} from './components/page/setting-manager/setting-manager.component'; // 설정 → 관리자
import {
  SettingCategoryComponent
} from './components/page/setting-category/setting-category.component'; // 설정 → 분류
import {
  PollComponent
} from './components/page/poll/poll.component'; // 여론조사 → 관리
import {
  BoardAdComponent
} from './components/page/board-ad/board-ad.component'; // 게시물 → 광고
import {
  BoardPostComponent
} from './components/page/board-post/board-post.component'; // 게시물 → 포스트
import {
  BoardPopupComponent
} from './components/page/board-popup/board-popup.component'; // 게시물 → 팝업
import {
  BoardBannerComponent
} from './components/page/board-banner/board-banner.component'; // 게시물 → 배너
import {
  UserComponent
} from './components/page/user/user.component'; // 회원 → 활성, 차단, 탈퇴
import {
  ReplyComponent
} from './components/page/reply/reply.component'; // 댓글 → 등록, 신고
import {
  ContactComponent
} from './components/page/contact/contact.component'; // 문의 → 내역
import {
  StatisticAdComponent
} from './components/page/statistic-ad/statistic-ad.component'; // 통계 → 광고
import {
  StatisticDashComponent
} from './components/page/statistic-dash/statistic-dash.component'; // 통계 → 요약
import {
  StatisticUserComponent
} from './components/page/statistic-user/statistic-user.component'; // 통계 → 사용자
import {
  StatisticPostComponent
} from './components/page/statistic-post/statistic-post.component'; // 통계 → 포스트
import {
  StatisticPollComponent
} from './components/page/statistic-poll/statistic-poll.component'; // 통계 → 여론조사
import {
  StatisticPopupComponent
} from './components/page/statistic-popup/statistic-popup.component'; // 통계 → 팝업
import {
  StatisticBannerComponent
} from './components/page/statistic-banner/statistic-banner.component'; // 통계 → 배너

// set route
const routes: Routes = [ {
    path: Config.base.sign.path,
    component: SignComponent
  },
  {
    path: Config.base.main.path,
    component: MainComponent,
    canActivate: [ HttpGuard ],
    children: [ {
        path: Config.page.setting.eula.path,
        component: SettingEulaComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.setting.manager.path,
        component: SettingManagerComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.setting.category.path,
        component: SettingCategoryComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.poll.manager.path,
        component: PollComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.board.ad.path,
        component: BoardAdComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.board.post.path,
        component: BoardPostComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.board.popup.path,
        component: BoardPopupComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.board.banner.path,
        component: BoardBannerComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.reply.create.path,
        component: ReplyComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.reply.report.path,
        component: ReplyComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.user.drop.path,
        component: UserComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.user.block.path,
        component: UserComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.user.active.path,
        component: UserComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.contact.request.path,
        component: ContactComponent,
        canActivate: [ HttpGuard ]
      },
      /*
      {
        path: Config.page.contact.receipt.path,
        component: ContactComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.contact.response.path,
        component: ContactComponent,
        canActivate: [ HttpGuard ]
      } */
      {
        path: Config.page.statistic.ad.path,
        component: StatisticAdComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.statistic.dash.path,
        component: StatisticDashComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.statistic.user.path,
        component: StatisticUserComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.statistic.post.path,
        component: StatisticPostComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.statistic.poll.path,
        component: StatisticPollComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.statistic.popup.path,
        component: StatisticPopupComponent,
        canActivate: [ HttpGuard ]
      },
      {
        path: Config.page.statistic.banner.path,
        component: StatisticBannerComponent,
        canActivate: [ HttpGuard ]
      }
    ]
  }
];

// export translation
export function createTranslateLoader( httpClient: HttpClient ) {

  return new TranslateHttpLoader( httpClient, Config.translate.path, Config.translate.type );
}

@NgModule( {
  declarations: [
    Src, Url, Open, Link, Blank, Media, Insert, DateTime, Transparent, // pipe
    EditorInit, HighchartInit, PaginationInit, MaterializeInit, // directive
    MainComponent, SignComponent, // root
    EditorComponent, PaginationComponent, // public
    MenuComponent, HeaderComponent, NavigationComponent, // main
    SettingEulaComponent, SettingManagerComponent, SettingCategoryComponent, // setting
    PollComponent, // poll
    BoardAdComponent, BoardPostComponent, BoardPopupComponent, BoardBannerComponent, // board
    UserComponent, // user
    ReplyComponent, // reply
    ContactComponent, // contact
    StatisticAdComponent, StatisticDashComponent, StatisticUserComponent, StatisticPostComponent, StatisticPollComponent, StatisticPopupComponent, StatisticBannerComponent // statistic
  ],
  providers: [
    HttpGuard,
    HttpService,
    EditorService,
    TranslatedService,
    PaginationService,
    NavigationService,
  ],
  imports: [
    HttpModule,
    FormsModule,
    EditorModule,
    BrowserModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule.forRoot( routes ),
    TranslateModule.forRoot( {
      loader: {
        deps: [ HttpClient ],
        provide: TranslateLoader,
        useFactory: ( createTranslateLoader )
      }
    } )
  ],
  exports: [
    RouterModule,
    TranslateModule
  ]
} )
export class AppRoutingModule {}