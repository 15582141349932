import {
  Injectable,
  EventEmitter
} from '@angular/core';
import {
  PollInfo
} from '../http/http.classes';

// emnum
export enum STATUS {

  TEXT = 0, IMAGE, VIDEO, POLL
}

// editor
@Injectable()
export class EditorService {

  // publi
  public push: EventEmitter < Editor > ;
  public pull: EventEmitter < EditorPull > ;

  constructor() {

    // event
    this.push = new EventEmitter();
    this.pull = new EventEmitter();
  }

  // set 
  onInit( editor: Editor ) {

    // check editor
    if ( editor.data === undefined ) return;

    // check media
    if ( !editor.media ) {

      // check empty
      if ( editor.data.empty() ) {

        editor.data.push( new EditorInfo() );
      } else {

        // check viewer
        if ( !editor.readonly ) {

          // get last item
          var pop = editor.data.pop();

          editor.data.push( pop );

          // check text add
          if ( this.onText( pop ) ) {

            editor.data.push( new EditorInfo() );
          }
        }
      }
    }

    this.push.emit( editor );
  }

  onText( pop: EditorInfo ) {

    return pop.type > STATUS.TEXT;
  }

  onSerialize( data: EditorInfo[] ) {

    var serialize = new Array < EditorInfo > ();

    data.forEach( ( item: EditorInfo ) => {

      if ( item.identity > 0 || ( item.content != null && item.content.length > 0 ) ) {

        serialize.push( item );
      }
    } );

    return serialize;
  }
}

export class Editor {

  data: EditorInfo[] = [];
  poll: PollInfo[] = [];
  uuid: string = null;
  limit: number = 9999;
  media: boolean = false;
  parent: Element = null;
  readonly: boolean = false;
  imageonly: boolean = false;
  videoonly: boolean = false;

  constructor( data ? : {

    data ? : EditorInfo[],
    poll ? : PollInfo[],
    uuid ? : string,
    limit ? : number,
    media ? : boolean,
    parent ? : Element,
    readonly ? : boolean
    imageonly ? : boolean,
    videoonly ? : boolean,
  } ) {

    if ( data ) {

      if ( data.data ) this.data = data.data;
      if ( data.poll ) this.poll = data.poll;
      if ( data.uuid ) this.uuid = data.uuid;
      if ( data.limit ) this.limit = data.limit;
      if ( data.media ) this.media = data.media;
      if ( data.parent ) this.parent = data.parent;
      if ( data.readonly ) this.readonly = data.readonly;
      if ( data.imageonly ) this.imageonly = data.imageonly;
      if ( data.videoonly ) this.videoonly = data.videoonly;
    }
  }
}

export class EditorInfo {

  type: number = 0; // 분류 0: 텍스트, 1: 이미지, 2: 유튜브, 3: 여론조사
  mark: boolean = true; // 마크 0: 없음, 1: 있음
  width: number = 0; // 넓이
  height: number = 0; // 높이
  content: string = null; // 내용
  caption: string = null; // 표제
  identity: number = 0;

  constructor( {
    type,
    mark,
    width,
    height,
    content,
    caption,
    identity,
  }: {
    type ? ,
    mark ? ,
    width ? ,
    height ? ,
    content ? ,
    caption ? ,
    identity ? ,
  } = {} ) {

    if ( type != undefined ) this.type = type;
    if ( mark != undefined ) this.mark = mark;
    if ( width != undefined ) this.width = width;
    if ( height != undefined ) this.height = height;
    if ( content != undefined ) this.content = content;
    if ( caption != undefined ) this.caption = caption;
    if ( identity != undefined ) this.identity = identity;
  }
}

export class EditorPull {

  uuid: string;
  data: EditorInfo[];

  constructor( uuid: string, data: EditorInfo[] ) {

    this.uuid = uuid;
    this.data = data;
  }
}

export class EditorOption {

  // init
  public tool: HTMLElement;
  public next: HTMLElement;
  public select: number;
  public current: HTMLElement;

  constructor() {

    this.tool = null;

    this.onInit();
  }

  // set
  onInit() {

    this.select = 0;
  }

  // get
  get getTool(): HTMLElement {

    // init
    this.onInit();

    // return tool
    return this.tool;
  }
}