import {
  OnInit,
  Component
} from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl
} from '@angular/forms';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  PollInfo,
  ItemInfo,
  SetPollRequest,
  DelPollRequest,
  UpdPollRequest,
  GetPollsRequest,
} from 'src/app/services/http/http.classes';
import {
  MaterializeClose
} from 'src/app/directive/materialize.directive';
import {
  EditorInfo,
  EditorService,
  EditorPull
} from 'src/app/services/editor/editor.service';
import {
  TranslatedService
} from 'src/app/services/translated/translated.service';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';
import {
  Config
} from 'src/app/app.config';
import {
  DateTime
} from 'src/app/pipe/pipe';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-poll',
  styleUrls: [ './poll.component.css' ],
  templateUrl: './poll.component.html'
} )
export class PollComponent implements OnInit {

  // data
  req: any;
  res: any;
  page: any;
  clone: any;
  checked: boolean;
  selected: number;
  subscription: any;

  // form
  pollForm: FormGroup;

  constructor(
    private httpService: HttpService,
    private editorService: EditorService,
    private translateService: TranslateService,
    private translatedService: TranslatedService
  ) {

    // data
    this.req = {

      setPoll: new SetPollRequest(),
      updPoll: new UpdPollRequest(),
      getPolls: new GetPollsRequest()
    }

    this.res = {

      pollInfos: new Array < PollInfo > ()
    }

    this.page = {

      content: new PagerData()
    }

    this.clone = {

      mediaInfos: new Array < EditorInfo > ()
    }

    // init
    this.checked = false;
    this.selected = 0;

    // form
    this.pollForm = new FormGroup( {

      checkBox: new FormArray( [] )
    } );


    // event
    this.subscription = {

      editor: this.editorService.pull.subscribe( ( pull: EditorPull ) => {


        this.getRequest.mediaInfos = pull.data;
      } ),

      translate: this.translatedService.pull.subscribe( () => {

        // get polls
        this.getPolls();
      } )
    }
  }

  ngOnInit() {

    // get polls
    this.getPolls();

    // set translate
    this.translatedService.setOpen( true );
  }

  ngOnDateSet() {

    setTimeout( () => {

      this.ngOnDateClose( new MaterializeClose( $( '#ended' ) ) );
      this.ngOnDateClose( new MaterializeClose( $( '#started' ) ) );
    }, 10 );
  }

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  ngOnDateClose( close: MaterializeClose ) {

    // set date
    var date = $( close.target ).val();

    // check id
    switch ( $( close.target ).attr( 'id' ) ) {

      case 'ended':
        {

          // set max date 
          M.Datepicker.getInstance( $( '#started' ) ).options.maxDate = new Date( date );
          break;
        }
      case 'started':
        {

          // set min date 
          M.Datepicker.getInstance( $( '#ended' ) ).options.minDate = new Date( date );
          break;
        }
    }
  }

  ngOnInterface() {

    this.pollForm.controls.checkBox = new FormArray( [] );

    // form array create
    this.res.pollInfos.forEach( () => {

      ( < FormArray > this.pollForm.controls.checkBox ).controls.push( new FormControl() );
    } );

    // init
    this.checked = false;
  }

  // get
  getOptionDate( init: Function ) {

    var months = this.translateService.instant( 'caption.date.month' );
    var weekdays = this.translateService.instant( 'caption.date.weekday' );

    init( {

      i18n: {

        done: this.translateService.instant( 'caption.button.done' ),
        clear: this.translateService.instant( 'caption.button.clear' ),
        cancel: this.translateService.instant( 'caption.button.cancel' ),
        months: months,
        monthsShort: months,
        weekdays: weekdays,
        weekdaysShort: weekdays,
        weekdaysAbbrev: weekdays,
      },
      format: 'yyyy-mm-dd',
      minDate: '',
      maxDate: ''
    } );
  }

  get getInsert() {

    return this.req.setPoll ? true : false;
  }

  get getRequest() {

    return this.req.setPoll ? this.req.setPoll : this.req.updPoll;
  }

  // http
  getPoll( pollInfo ? : PollInfo, selected ? : number ) {

    // check poll
    if ( pollInfo ) {

      // update
      this.selected = selected;
      this.req.updPoll = new UpdPollRequest();
      this.req.setPoll = null;

      // set data
      this.req.updPoll.onInit( pollInfo );
      this.req.updPoll.ended = new DateTime().transform( this.req.updPoll.ended, null, 'yyyy-MM-dd' );
      this.req.updPoll.started = new DateTime().transform( this.req.updPoll.started, null, 'yyyy-MM-dd' );

      // set media
      this.clone.mediaInfos = pollInfo.mediaInfos.clone();

      // set date
      this.ngOnDateSet();
    } else {

      // insert
      this.req.updPoll = null;
      this.req.setPoll = new SetPollRequest();
    }

    // open
    M.Modal.getInstance( $( '.modal' ) ).open();
  }

  getPolls( index: number = 1, limit: number = Config.limit ) {

    // set contents
    this.req.getPolls.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } );

    this.httpService.getPolls( this.req.getPolls ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.pollInfos = response.pollInfos;

      // set page
      this.page.content = new PagerData( response.pageInfo, this.req.getPolls.index );

      // update interface
      this.ngOnInterface();
    } );
  }

  setRemove() {

    if ( confirm( this.translateService.instant( 'message.remove' ) ) == false ) return;

    // init
    var pollInfos = new Array < PollInfo > ();

    ( < FormArray > this.pollForm.controls.checkBox ).controls.forEach( ( checkbox, index ) => {

      // checked
      if ( checkbox.value ) {

        // push poll
        pollInfos.push( this.res.pollInfos[ index ] );
      }
    } );

    // check poll
    if ( pollInfos.empty() ) return alert( this.translateService.instant( 'message.select' ) );

    // delete poll
    this.httpService.delPoll( new DelPollRequest( pollInfos ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      this.getPolls();
    } );
  }

  setSubmit( form: any ) {

    // check valid
    if (
      form.valid == false ||
      this.getRequest.itemInfos.empty()
    ) {

      return alert( this.translateService.instant( 'message.valid' ) );
    }

    // this.getRequest.mediaInfos.empty()

    // check insert
    if ( this.getInsert ) {

      this.httpService.setPoll( this.req.setPoll ).subscribe( response => {

        if ( response.code ) {

          return alert( response.message );
        }

        // close
        M.Modal.getInstance( $( '.modal' ) ).close();

        // get polls
        this.getPolls();
      } );

      return;
    }

    this.httpService.updPoll( this.req.updPoll ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // update
      this.res.pollInfos[ this.selected ] = response.pollInfo;

      // close
      M.Modal.getInstance( $( '.modal' ) ).close();
    } );
  }

  // action
  onCheckBox( event: any, total: boolean ) {

    let check: boolean = event.target.checked;

    for ( let checkbox of ( < FormArray > this.pollForm.controls.checkBox ).controls ) {

      if ( total ) {

        checkbox.setValue( event.target.checked );
      } else {

        if ( checkbox.value ) {

          check = true;
          break;
        }
      }
    }

    this.checked = check;
  }

  // media
  onMediaOver( event: Event ) {

    var target = ( < HTMLElement > event.target );

    // check anchor
    if ( target.lastElementChild instanceof HTMLAnchorElement ) return;

    target.classList.add( 'focus' );
  }

  onMediaLeave( event: Event ) {

    var target = ( < HTMLElement > event.target );

    // check anchor
    if ( target.lastElementChild instanceof HTMLAnchorElement ) return;

    target.classList.remove( 'focus' );
  }

  // chip
  onItemInsert() {

    this.getRequest.itemInfos.push( new ItemInfo() );
  }

  onItemRemove( itemInfo: ItemInfo ) {

    this.getRequest.itemInfos.splice( this.getRequest.itemInfos.indexOf( itemInfo ), 1 );
  }
}