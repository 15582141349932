import {
  OnInit,
  Component
} from '@angular/core';
import {
  Params,
  ActivatedRoute,
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  USER_BLOCK,
  USER_STATUS
} from 'src/app/enum/enum';
import {
  Config
} from 'src/app/app.config';
import {
  DateTime
} from 'src/app/pipe/pipe';
import {
  UserInfo,
  PollInfo,
  ReplyInfo,
  BoardInfo,
  GetUserRequest,
  GetUsersRequest,
  SetBlockRequest,
  DelBlockRequest,
  UpdBlockRequest,
} from 'src/app/services/http/http.classes';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  MaterializeClose
} from 'src/app/directive/materialize.directive';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-user',
  styleUrls: [ './user.component.css' ],
  templateUrl: './user.component.html'
} )
export class UserComponent implements OnInit {

  // data
  req: any;
  res: any;
  page: any;
  subscription: any;

  // enum 
  USER_BLOCK = USER_BLOCK;
  USER_STATUS = USER_STATUS;

  constructor(
    private route: ActivatedRoute,
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // data
    this.req = {

      getUser: new GetUserRequest(),
      getUsers: new GetUsersRequest(),
      setBlock: new SetBlockRequest(),
      updBlock: new UpdBlockRequest()
    }

    this.res = {

      userInfo: new UserInfo(),
      userInfos: new Array < UserInfo > (),
      pollInfos: new Array < PollInfo > (),
      markInfos: new Array < BoardInfo > (),
      replyInfos: new Array < ReplyInfo > (),
      reportInfos: new Array < ReplyInfo > ()
    }

    this.page = {

      content: new PagerData()
    }

    // get route
    this.subscription = {

      route: this.route.params.subscribe( ( params: Params ) => {

        // set users
        this.req.getUsers.type = Config.page.user[ params.status ].index;

        // get users
        this.getUsers();
      } )
    }
  }

  ngOnInit() {}

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  ngOnDateSet() {

    setTimeout( () => {

      this.ngOnDateClose( new MaterializeClose( this.getPicker( true ).block ) );
      this.ngOnDateClose( new MaterializeClose( this.getPicker( true ).search ) );
      this.ngOnDateClose( new MaterializeClose( this.getPicker( false ).block ) );
      this.ngOnDateClose( new MaterializeClose( this.getPicker( false ).search ) );
    }, 10 );
  }

  ngOnDateClose( close: MaterializeClose ) {

    // set date
    var date = $( close.target ).val();

    // check id
    switch ( $( close.target ).attr( 'id' ) ) {

      case 'blockEnded': {

        // set max date 
        M.Datepicker.getInstance( this.getPicker( true ).block ).options.maxDate = new Date( date );
        break;
      }
      case 'blockStarted': {

        // set min date 
        M.Datepicker.getInstance( this.getPicker( false ).block ).options.minDate = new Date( date );
        break;
      }
      case 'searchEnded': {

        // set max date 
        M.Datepicker.getInstance( this.getPicker( true ).search ).options.maxDate = new Date( date );

        // check update
        if ( close.update ) {

          // get user
          this.getUser();
        }
        break;
      }
      case 'searchStarted': {

        // set min date 
        M.Datepicker.getInstance( this.getPicker( false ).search ).options.minDate = new Date( date );

        // check update
        if ( close.update ) {

          // get user
          this.getUser();
        }
        break;
      }
    }
  }

  // get
  getPoll( pollInfo: PollInfo ) {

    if ( pollInfo.itemInfos.empty() ) return null;

    return pollInfo.itemInfos[ 0 ].manager;
  }

  getSearch( search: boolean ) {

    if ( search ) {

      $( '#search' ).removeClass( 'none' );

    } else {

      $( '#search' ).addClass( 'none' );
    }
  }

  getActive( type: USER_STATUS ) {

    return this.req.getUsers.type == type;
  }

  getPicker( start: boolean ) {

    return {

      block: start ? $( '#blockStarted' ) : $( '#blockEnded' ),
      search: start ? $( '#searchStarted' ) : $( '#searchEnded' )
    }
  }

  getOptionDate( init: Function ) {

    var months = this.translateService.instant( 'caption.date.month' );
    var weekdays = this.translateService.instant( 'caption.date.weekday' );

    init( {

      i18n: {

        done: this.translateService.instant( 'caption.button.done' ),
        clear: this.translateService.instant( 'caption.button.clear' ),
        cancel: this.translateService.instant( 'caption.button.cancel' ),
        months: months,
        monthsShort: months,
        weekdays: weekdays,
        weekdaysShort: weekdays,
        weekdaysAbbrev: weekdays,
      },
      format: 'yyyy-mm-dd',
      minDate: '',
      maxDate: ''
    } );
  }

  get getInsert() {

    return this.req.setUser ? true : false;
  }

  get getRequest() {

    return this.req.setUser ? this.req.setUser : this.req.updUser;
  }

  // http
  getUser( modal: boolean = false ) {

    this.httpService.getUser( this.req.getUser ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.userInfo = response.userInfo;
      this.res.pollInfos = response.pollInfos;
      this.res.markInfos = response.markInfos;
      this.res.replyInfos = response.replyInfos;
      this.res.reportInfos = response.reportInfos;

      // set date
      response.userInfo.blockInfo.ended = new DateTime().transform( response.userInfo.blockInfo.ended, null, 'yyyy-MM-dd' );
      response.userInfo.blockInfo.started = new DateTime().transform( response.userInfo.blockInfo.started, null, 'yyyy-MM-dd' );

      // set block
      this.req.setBlock.onInit( this.res.userInfo.blockInfo );

      // set date
      this.ngOnDateSet();

      // open modal
      if ( modal ) M.Modal.getInstance( $( '.modal' ) ).open();
    } );
  }

  getUsers( index: number = 1, limit: number = Config.limit ) {

    // set users
    this.req.getUsers.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } );

    this.httpService.getUsers( this.req.getUsers ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.userInfos = response.userInfos;

      // set page
      this.page.content = new PagerData( response.pageInfo, this.req.getUsers.index );
    } );
  }

  setBlock() {

    this.httpService.setBlock( this.req.setBlock ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // close
      M.Modal.getInstance( $( '.modal' ) ).close();

      // reload
      this.getUsers();
    } );
  }

  updBlock() {

    // set block
    this.req.updBlock.onInit( this.req.setBlock );

    this.httpService.updBlock( this.req.updBlock ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set date
      response.blockInfo.ended = new DateTime().transform( response.blockInfo.ended, null, 'yyyy-MM-dd' );
      response.blockInfo.started = new DateTime().transform( response.blockInfo.started, null, 'yyyy-MM-dd' );

      // set data
      this.res.userInfo.blockInfo = response.blockInfo;

      // set block
      this.req.setBlock.onInit( response.blockInfo );
    } );
  }

  delBlock( userId: number ) {

    this.httpService.delBlock( new DelBlockRequest( userId ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // close
      M.Modal.getInstance( $( '.modal' ) ).close();

      // reload
      this.getUsers();
    } );
  }

  // action
  onUser( userInfo ? : UserInfo ) {

    // set user
    this.req.getUser = new GetUserRequest( userInfo.userId );

    // set block
    this.req.setBlock = new SetBlockRequest( userInfo.userId );

    return this.getUser( true );
  }

  onSearch( form: any ) {

    if ( form.invalid ) return alert( this.translateService.instant( 'message.valid' ) )

    // set user 
    this.req.getUser.onInit( {

      ended: form.value.searchEnded,
      strated: form.value.searchStarted
    } );

    // get user
    return this.getUser();
  }

  onSubmit( form: any, type: USER_BLOCK ) {

    if ( form.invalid ) {

      return alert( this.translateService.instant( 'message.valid' ) )
    }

    switch ( type ) {

      case USER_BLOCK.INSERT: {

        this.setBlock();
        break;
      }
      case USER_BLOCK.UPDATE: {

        this.updBlock();
        break;
      }
      case USER_BLOCK.DELETE: {

        this.delBlock( form.value.userId );
        break;
      }
    }
  }
}