import { Config } from 'src/app/app.config';
import { Injectable } from '@angular/core';
import { Http, Headers, URLSearchParams } from '@angular/http';
import 'rxjs/add/operator/map';

import {
	GetTranslatesRequest, GetTranslatesResponse,
	GetInRequest, GetInResponse,
	GetOutRequest, GetOutResponse,
	GetCheckRequest, GetCheckResponse,
	GetEulasRequest, GetEulasResponse,
	UpdEulaRequest, UpdEulaResponse,
	GetManagersRequest, GetManagersResponse,
	SetManagerRequest, SetManagerResponse,
	UpdManagerRequest, UpdManagerResponse,
	DelManagerRequest, DelManagerResponse,
	GetUsersRequest, GetUsersResponse,
	GetUserRequest, GetUserResponse,
	SetBlockRequest, SetBlockResponse,
	UpdBlockRequest, UpdBlockResponse,
	DelBlockRequest, DelBlockResponse,
	GetBoardsRequest, GetBoardsResponse,
	GetBoardRequest, GetBoardResponse,
	SetBoardRequest, SetBoardResponse,
	UpdBoardRequest, UpdBoardResponse,
	DelBoardRequest, DelBoardResponse,
	OpeBoardRequest, OpeBoardResponse,
	GetRelationsRequest, GetRelationsResponse,
	GetCategoriesRequest, GetCategoriesResponse,
	SetCategoryRequest, SetCategoryResponse,
	UpdCategoryRequest, UpdCategoryResponse,
	GetBannersRequest, GetBannersResponse,
	SetBannerRequest, SetBannerResponse,
	UpdBannerRequest, UpdBannerResponse,
	DelBannerRequest, DelBannerResponse,
	OpeBannerRequest, OpeBannerResponse,
	GetPopupsRequest, GetPopupsResponse,
	SetPopupRequest, SetPopupResponse,
	UpdPopupRequest, UpdPopupResponse,
	DelPopupRequest, DelPopupResponse,
	OpePopupRequest, OpePopupResponse,
	GetAdsRequest, GetAdsResponse,
	SetAdRequest, SetAdResponse,
	UpdAdRequest, UpdAdResponse,
	DelAdRequest, DelAdResponse,
	OpeAdRequest, OpeAdResponse,
	GetRepliesRequest, GetRepliesResponse,
	DelReplyRequest, DelReplyResponse,
	OpeReplyRequest, OpeReplyResponse,
	GetContactsRequest, GetContactsResponse,
	SetContactRequest, SetContactResponse,
	UpdContactRequest, UpdContactResponse,
	DelContactRequest, DelContactResponse,
	GetIndexRequest, GetIndexResponse,
	GetPollsRequest, GetPollsResponse,
	SetPollRequest, SetPollResponse,
	UpdPollRequest, UpdPollResponse,
	DelPollRequest, DelPollResponse,
	StsDashRequest, StsDashResponse,
	StsUserRequest, StsUserResponse,
	StsPollRequest, StsPollResponse,
	StsBoardRequest, StsBoardResponse,
	StsPopupRequest, StsPopupResponse,
	StsBannerRequest, StsBannerResponse,
	StsAdRequest, StsAdResponse
} from './http.classes';
import {
	PreloaderService
} from 'src/app/services/preloader/preloader.service';
@Injectable()
export class HttpService {

	languageType: string;
	translateType: number;
	
	constructor(
		private http : Http,
		private preloaderService: PreloaderService
	) {
	
		this.languageType = Config.translate.base.code;
		this.translateType = Config.translate.base.type;
	}
	
	setLanguage( language: string ) {
	
		this.languageType = language;
	}
	
	setTranslate( translate: number ) {
	
		this.translateType = translate;
	}
	
	// 번역 목록
	getTranslates(req: GetTranslatesRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getTranslates', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetTranslatesResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 로그인
	getIn(req: GetInRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'email', encodeURIComponent( req.email || '' ) );
		params.set( 'password', encodeURIComponent( req.password || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/sign/getIn', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetInResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 로그아웃
	getOut(req: GetOutRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/sign/getOut', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetOutResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 로그인 확인
	getCheck(req: GetCheckRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/sign/getCheck', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetCheckResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 약관 정보
	getEulas(req: GetEulasRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getEulas', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetEulasResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 약관 수정
	updEula(req: UpdEulaRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'eulaId', req.eulaId ? req.eulaId.toString() : '0' );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'content', encodeURIComponent( req.content || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/updEula', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new UpdEulaResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 관리자 목록
	getManagers(req: GetManagersRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getManagers', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetManagersResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 관리자 등록
	setManager(req: SetManagerRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'email', encodeURIComponent( req.email || '' ) );
		params.set( 'password', encodeURIComponent( req.password || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/manager/setManager', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new SetManagerResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 관리자 수정
	updManager(req: UpdManagerRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'managerId', req.managerId ? req.managerId.toString() : '0' );
		params.set( 'salt', encodeURIComponent( req.salt || '' ) );
		params.set( 'email', encodeURIComponent( req.email || '' ) );
		params.set( 'password', encodeURIComponent( req.password || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/manager/updManager', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new UpdManagerResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 관리자 삭제
	delManager(req: DelManagerRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'managerInfos', encodeURIComponent( JSON.stringify( req.managerInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/delManager', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new DelManagerResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 사용자 목록
	getUsers(req: GetUsersRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'type', req.type ? req.type.toString() : '0' );
		params.set( 'index', req.index ? req.index.toString() : '0' );
		params.set( 'limit', req.limit ? req.limit.toString() : '0' );
		params.set( 'query', encodeURIComponent( req.query || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getUsers', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetUsersResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 사용자 정보
	getUser(req: GetUserRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'userId', req.userId ? req.userId.toString() : '0' );
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getUser', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetUserResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 차단 설정
	setBlock(req: SetBlockRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'userId', req.userId ? req.userId.toString() : '0' );
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		params.set( 'reason', encodeURIComponent( req.reason || '' ) );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/setBlock', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new SetBlockResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 차단 수정
	updBlock(req: UpdBlockRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'userId', req.userId ? req.userId.toString() : '0' );
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		params.set( 'reason', encodeURIComponent( req.reason || '' ) );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/updBlock', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new UpdBlockResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 차단 해제
	delBlock(req: DelBlockRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'userId', req.userId ? req.userId.toString() : '0' );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/delBlock', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new DelBlockResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 게시물 목록
	getBoards(req: GetBoardsRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'categoryType', req.categoryType ? req.categoryType.toString() : '0' );
		params.set( 'index', req.index ? req.index.toString() : '0' );
		params.set( 'limit', req.limit ? req.limit.toString() : '0' );
		params.set( 'query', encodeURIComponent( req.query || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getBoards', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetBoardsResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 게시물 정보
	getBoard(req: GetBoardRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'boardId', req.boardId ? req.boardId.toString() : '0' );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getBoard', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetBoardResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 게시물 등록
	setBoard(req: SetBoardRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'categoryType', req.categoryType ? req.categoryType.toString() : '0' );
		params.set( 'label', encodeURIComponent( req.label || '' ) );
		params.set( 'title', encodeURIComponent( req.title || '' ) );
		params.set( 'photo', encodeURIComponent( req.photo || '' ) );
		params.set( 'sorted', req.sorted ? req.sorted.toString() : '0' );
		params.set( 'opened', encodeURIComponent( req.opened || '' ) );
		params.set( 'editor', encodeURIComponent( req.editor || '' ) );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'manager', encodeURIComponent( req.manager || '' ) );
		params.set( 'pollInfos', encodeURIComponent( JSON.stringify( req.pollInfos ) ) );
		params.set( 'indexInfos', encodeURIComponent( JSON.stringify( req.indexInfos ) ) );
		params.set( 'mediaInfos', encodeURIComponent( JSON.stringify( req.mediaInfos ) ) );
		params.set( 'editorInfos', encodeURIComponent( JSON.stringify( req.editorInfos ) ) );
		params.set( 'relationInfos', encodeURIComponent( JSON.stringify( req.relationInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/setBoard', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new SetBoardResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 게시물 수정
	updBoard(req: UpdBoardRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'boardId', req.boardId ? req.boardId.toString() : '0' );
		params.set( 'label', encodeURIComponent( req.label || '' ) );
		params.set( 'title', encodeURIComponent( req.title || '' ) );
		params.set( 'photo', encodeURIComponent( req.photo || '' ) );
		params.set( 'sorted', req.sorted ? req.sorted.toString() : '0' );
		params.set( 'opened', encodeURIComponent( req.opened || '' ) );
		params.set( 'editor', encodeURIComponent( req.editor || '' ) );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'manager', encodeURIComponent( req.manager || '' ) );
		params.set( 'pollInfos', encodeURIComponent( JSON.stringify( req.pollInfos ) ) );
		params.set( 'indexInfos', encodeURIComponent( JSON.stringify( req.indexInfos ) ) );
		params.set( 'mediaInfos', encodeURIComponent( JSON.stringify( req.mediaInfos ) ) );
		params.set( 'editorInfos', encodeURIComponent( JSON.stringify( req.editorInfos ) ) );
		params.set( 'relationInfos', encodeURIComponent( JSON.stringify( req.relationInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/updBoard', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new UpdBoardResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 게시물 삭제
	delBoard(req: DelBoardRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'boardInfos', encodeURIComponent( JSON.stringify( req.boardInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/delBoard', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new DelBoardResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 게시물 공개
	opeBoard(req: OpeBoardRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'openType', req.openType ? req.openType.toString() : '0' );
		params.set( 'boardInfos', encodeURIComponent( JSON.stringify( req.boardInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/opeBoard', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new OpeBoardResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 연관 게시물 검색
	getRelations(req: GetRelationsRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'categoryType', req.categoryType ? req.categoryType.toString() : '0' );
		params.set( 'index', req.index ? req.index.toString() : '0' );
		params.set( 'limit', req.limit ? req.limit.toString() : '0' );
		params.set( 'query', encodeURIComponent( req.query || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getRelations', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetRelationsResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 범주 목록
	getCategories(req: GetCategoriesRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getCategories', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetCategoriesResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 범주 등록
	setCategory(req: SetCategoryRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'name', encodeURIComponent( req.name || '' ) );
		params.set( 'sorted', req.sorted ? req.sorted.toString() : '0' );
		params.set( 'manager', encodeURIComponent( req.manager || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/setCategory', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new SetCategoryResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 범주 수정
	updCategory(req: UpdCategoryRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'categoryType', req.categoryType ? req.categoryType.toString() : '0' );
		params.set( 'name', encodeURIComponent( req.name || '' ) );
		params.set( 'sorted', req.sorted ? req.sorted.toString() : '0' );
		params.set( 'manager', encodeURIComponent( req.manager || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/updCategory', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new UpdCategoryResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 배너 목록
	getBanners(req: GetBannersRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'index', req.index ? req.index.toString() : '0' );
		params.set( 'limit', req.limit ? req.limit.toString() : '0' );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getBanners', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetBannersResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 배너 등록
	setBanner(req: SetBannerRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'link', encodeURIComponent( req.link || '' ) );
		params.set( 'sorted', req.sorted ? req.sorted.toString() : '0' );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'mediaInfos', encodeURIComponent( JSON.stringify( req.mediaInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/setBanner', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new SetBannerResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 배너 수정
	updBanner(req: UpdBannerRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'bannerId', req.bannerId ? req.bannerId.toString() : '0' );
		params.set( 'link', encodeURIComponent( req.link || '' ) );
		params.set( 'sorted', req.sorted ? req.sorted.toString() : '0' );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'mediaInfos', encodeURIComponent( JSON.stringify( req.mediaInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/updBanner', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new UpdBannerResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 배너 삭제
	delBanner(req: DelBannerRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'bannerInfos', encodeURIComponent( JSON.stringify( req.bannerInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/delBanner', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new DelBannerResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 배너 공개
	opeBanner(req: OpeBannerRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'openType', req.openType ? req.openType.toString() : '0' );
		params.set( 'bannerInfos', encodeURIComponent( JSON.stringify( req.bannerInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/opeBanner', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new OpeBannerResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 팝업 목록
	getPopups(req: GetPopupsRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'index', req.index ? req.index.toString() : '0' );
		params.set( 'limit', req.limit ? req.limit.toString() : '0' );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getPopups', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetPopupsResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 팝업 등록
	setPopup(req: SetPopupRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'link', encodeURIComponent( req.link || '' ) );
		params.set( 'sorted', req.sorted ? req.sorted.toString() : '0' );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'mediaInfos', encodeURIComponent( JSON.stringify( req.mediaInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/setPopup', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new SetPopupResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 팝업 수정
	updPopup(req: UpdPopupRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'popupId', req.popupId ? req.popupId.toString() : '0' );
		params.set( 'link', encodeURIComponent( req.link || '' ) );
		params.set( 'sorted', req.sorted ? req.sorted.toString() : '0' );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'mediaInfos', encodeURIComponent( JSON.stringify( req.mediaInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/updPopup', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new UpdPopupResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 팝업 삭제
	delPopup(req: DelPopupRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'popupInfos', encodeURIComponent( JSON.stringify( req.popupInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/delPopup', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new DelPopupResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 팝업 공개
	opePopup(req: OpePopupRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'openType', req.openType ? req.openType.toString() : '0' );
		params.set( 'popupInfos', encodeURIComponent( JSON.stringify( req.popupInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/opePopup', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new OpePopupResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 광고 목록
	getAds(req: GetAdsRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'index', req.index ? req.index.toString() : '0' );
		params.set( 'limit', req.limit ? req.limit.toString() : '0' );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getAds', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetAdsResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 광고 등록
	setAd(req: SetAdRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'link', encodeURIComponent( req.link || '' ) );
		params.set( 'sorted', req.sorted ? req.sorted.toString() : '0' );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'mediaInfos', encodeURIComponent( JSON.stringify( req.mediaInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/setAd', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new SetAdResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 광고 수정
	updAd(req: UpdAdRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'adId', req.adId ? req.adId.toString() : '0' );
		params.set( 'link', encodeURIComponent( req.link || '' ) );
		params.set( 'sorted', req.sorted ? req.sorted.toString() : '0' );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'mediaInfos', encodeURIComponent( JSON.stringify( req.mediaInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/updAd', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new UpdAdResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 광고 삭제
	delAd(req: DelAdRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'adInfos', encodeURIComponent( JSON.stringify( req.adInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/delAd', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new DelAdResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 광고 공개
	opeAd(req: OpeAdRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'openType', req.openType ? req.openType.toString() : '0' );
		params.set( 'adInfos', encodeURIComponent( JSON.stringify( req.adInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/opeAd', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new OpeAdResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 댓글 목록
	getReplies(req: GetRepliesRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'type', req.type ? req.type.toString() : '0' );
		params.set( 'index', req.index ? req.index.toString() : '0' );
		params.set( 'limit', req.limit ? req.limit.toString() : '0' );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getReplies', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetRepliesResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 댓글 삭제
	delReply(req: DelReplyRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'replyInfos', encodeURIComponent( JSON.stringify( req.replyInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/delReply', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new DelReplyResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 댓글 공개
	opeReply(req: OpeReplyRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'openType', req.openType ? req.openType.toString() : '0' );
		params.set( 'replyInfos', encodeURIComponent( JSON.stringify( req.replyInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/opeReply', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new OpeReplyResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 문의 목록
	getContacts(req: GetContactsRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'type', req.type ? req.type.toString() : '0' );
		params.set( 'index', req.index ? req.index.toString() : '0' );
		params.set( 'limit', req.limit ? req.limit.toString() : '0' );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/contact/getContacts', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetContactsResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 문의 접수
	setContact(req: SetContactRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'contactInfos', encodeURIComponent( JSON.stringify( req.contactInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/contact/setContact', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new SetContactResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 문의 수정
	updContact(req: UpdContactRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'contactId', req.contactId ? req.contactId.toString() : '0' );
		params.set( 'type', req.type ? req.type.toString() : '0' );
		params.set( 'response', encodeURIComponent( req.response || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/contact/updContact', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new UpdContactResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 문의 접수 취소
	delContact(req: DelContactRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'contactInfos', encodeURIComponent( JSON.stringify( req.contactInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/contact/delContact', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new DelContactResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 인덱스 정보
	getIndex(req: GetIndexRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'query', encodeURIComponent( req.query || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getIndex', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetIndexResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 여론조사 목록
	getPolls(req: GetPollsRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'index', req.index ? req.index.toString() : '0' );
		params.set( 'limit', req.limit ? req.limit.toString() : '0' );
		params.set( 'query', encodeURIComponent( req.query || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/getPolls', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new GetPollsResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 여론조사 등록
	setPoll(req: SetPollRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		params.set( 'manager', encodeURIComponent( req.manager || '' ) );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'itemInfos', encodeURIComponent( JSON.stringify( req.itemInfos ) ) );
		params.set( 'mediaInfos', encodeURIComponent( JSON.stringify( req.mediaInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/setPoll', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new SetPollResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 여론조사 수정
	updPoll(req: UpdPollRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'pollId', req.pollId ? req.pollId.toString() : '0' );
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		params.set( 'manager', encodeURIComponent( req.manager || '' ) );
		params.set( 'subject', encodeURIComponent( req.subject || '' ) );
		params.set( 'itemInfos', encodeURIComponent( JSON.stringify( req.itemInfos ) ) );
		params.set( 'mediaInfos', encodeURIComponent( JSON.stringify( req.mediaInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/updPoll', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new UpdPollResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 여론조사 삭제
	delPoll(req: DelPollRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'pollInfos', encodeURIComponent( JSON.stringify( req.pollInfos ) ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/delPoll', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new DelPollResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 통계 요약
	stsDash(req: StsDashRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/stsDash', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new StsDashResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 통계 사용자
	stsUser(req: StsUserRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'statisticType', req.statisticType ? req.statisticType.toString() : '0' );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/stsUser', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new StsUserResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 통계 여론
	stsPoll(req: StsPollRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'pollId', req.pollId ? req.pollId.toString() : '0' );
		params.set( 'statisticType', req.statisticType ? req.statisticType.toString() : '0' );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/stsPoll', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new StsPollResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 통계 게시물
	stsBoard(req: StsBoardRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'boardId', req.boardId ? req.boardId.toString() : '0' );
		params.set( 'categoryType', req.categoryType ? req.categoryType.toString() : '0' );
		params.set( 'statisticType', req.statisticType ? req.statisticType.toString() : '0' );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/stsBoard', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new StsBoardResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 통계 팝업
	stsPopup(req: StsPopupRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'popupId', req.popupId ? req.popupId.toString() : '0' );
		params.set( 'statisticType', req.statisticType ? req.statisticType.toString() : '0' );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/stsPopup', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new StsPopupResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 통계 배너
	stsBanner(req: StsBannerRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'bannerId', req.bannerId ? req.bannerId.toString() : '0' );
		params.set( 'statisticType', req.statisticType ? req.statisticType.toString() : '0' );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/stsBanner', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new StsBannerResponse( res.json() ); }, error => alert( error ) );
	}
	
	// 통계 광고
	stsAd(req: StsAdRequest) {
		
		this.preloaderService.start();
		
		let params: URLSearchParams = new URLSearchParams();
		params.set( 'adId', req.adId ? req.adId.toString() : '0' );
		params.set( 'statisticType', req.statisticType ? req.statisticType.toString() : '0' );
		params.set( 'started', encodeURIComponent( req.started || '' ) );
		params.set( 'ended', encodeURIComponent( req.ended || '' ) );
		var headers = new Headers( {
			'Content-Type': 'application/x-www-form-urlencoded', 
			'Language-Type': this.languageType, 
			'Translate-Type': this.translateType
		} );
		
		return this.http.post( '/api/stsAd', params.toString(), { headers: headers })
			.map( res => { this.preloaderService.stop(); return new StsAdResponse( res.json() ); }, error => alert( error ) );
	}
	
}
