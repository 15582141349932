import {
  OnInit,
  Component
} from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl
} from '@angular/forms';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  ReplyInfo,
  DelReplyRequest,
  OpeReplyRequest,
  GetRepliesRequest
} from 'src/app/services/http/http.classes';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';
import {
  Params,
  ActivatedRoute
} from '@angular/router';

@Component( {
  selector: 'app-reply',
  styleUrls: [ './reply.component.css' ],
  templateUrl: './reply.component.html'
} )
export class ReplyComponent implements OnInit {

  // data
  req: any;
  res: any;
  page: any;
  checked: boolean;
  subscription: any;

  // form
  replyForm: FormGroup;

  constructor(
    private route: ActivatedRoute,
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // data
    this.req = {

      getReplies: new GetRepliesRequest()
    }

    this.res = {

      replyInfos: new Array < ReplyInfo > ()
    }

    this.page = {

      content: new PagerData()
    }

    // init
    this.checked = false;

    // form
    this.replyForm = new FormGroup( {

      checkBox: new FormArray( [] )
    } );

    // set event
    this.subscription = {

      route: this.route.params.subscribe( ( params: Params ) => {

        // set reply type
        this.req.getReplies.type = Config.page.reply[ params.status ].index

        // get reply
        this.getReplies();
      } )
    }
  }

  ngOnInit() {}

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  ngOnInterface() {

    this.replyForm.controls.checkBox = new FormArray( [] );

    // form array create
    this.res.replyInfos.forEach( () => {

      ( < FormArray > this.replyForm.controls.checkBox ).controls.push( new FormControl() );
    } );

    // init
    this.checked = false;
  }

  // check
  get isInsert() {

    return this.req.setReply ? true : false;
  }

  // get
  get getColumn() {

    return `caption.column.user.${ this.req.getReplies.type < 1 ? 'create': 'report' }`;
  }

  get getCorver() {

    if ( this.res.boardInfo.dataInfo.mediaInfos.empty() ) return null;

    return this.res.boardInfo.dataInfo.mediaInfos[ 1 ].content;
  }

  get getRequest() {

    return this.req.setReply ? this.req.setReply : this.req.updReply;
  }

  // http
  getReplies( index: number = 1, limit: number = Config.limit ) {

    // set contents
    this.req.getReplies.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } );

    this.httpService.getReplies( this.req.getReplies ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // set data
      this.res.replyInfos = response.replyInfos;

      // set page
      this.page.content = new PagerData( response.pageInfo, this.req.getReplies.index );

      // set interface
      this.ngOnInterface();
    } );
  }

  // set
  setRemove() {

    if ( confirm( this.translateService.instant( 'message.remove' ) ) == false ) {

      return;
    }

    // init
    var replyInfos = new Array < ReplyInfo > ();

    ( < FormArray > this.replyForm.controls.checkBox ).controls.forEach( ( checkbox, index ) => {

      // checked
      if ( checkbox.value ) {

        // push reply
        replyInfos.push( this.res.replyInfos[ index ] );
      }
    } );

    // check reply
    if ( replyInfos.empty() ) {

      return alert( this.translateService.instant( 'message.select' ) );
    }

    // delete reply
    this.httpService.delReply( new DelReplyRequest( replyInfos ) ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      this.getReplies();
    } );
  }

  setPrivate() {

    if ( !confirm( this.translateService.instant( 'message.private' ) ) ) {

      return;
    }

    // init
    var replyInfos = new Array < ReplyInfo > ();

    ( < FormArray > this.replyForm.controls.checkBox ).controls.forEach( ( checkbox, index ) => {

      // checked
      if ( checkbox.value ) {

        // push reply
        replyInfos.push( this.res.replyInfos[ index ] );
      }
    } );

    // check reply
    if ( replyInfos.empty() ) {

      return alert( this.translateService.instant( 'message.select' ) );
    }

    // open reply
    this.httpService.opeReply( new OpeReplyRequest( false, replyInfos ) ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      this.getReplies();
    } );
  }

  // action
  onReply( replyInfo: ReplyInfo ) {

    return window.open( Config.homepage + replyInfo.path, '_blank' );
  }

  onCheckBox( event: any, total: boolean ) {

    let check: boolean = event.target.checked;

    for ( let checkbox of ( < FormArray > this.replyForm.controls.checkBox ).controls ) {

      if ( total ) {

        checkbox.setValue( event.target.checked );
      } else {

        if ( checkbox.value ) {

          check = true;
          break;
        }
      }
    }

    this.checked = check;
  }
}