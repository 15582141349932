import {
  Pipe,
  PipeTransform
} from '@angular/core';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  DomSanitizer
} from '@angular/platform-browser';
import {
  DatePipe
} from '@angular/common';
import {
  EditorInfo
} from 'src/app/services/http/http.classes';
import {
  STATUS
} from 'src/app/services/editor/editor.service';

@Pipe( {
  name: 'src'
} )
export class Src implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) {}

  transform( data: any ) {

    if ( typeof data == 'string' ) {

      return this.sanitizer.bypassSecurityTrustResourceUrl( data );
    }

    if ( data && data.length > 0 ) {

      return this.sanitizer.bypassSecurityTrustResourceUrl( data[ 0 ].content || data[ 0 ].url );
    }

    return null;
  }
}

@Pipe( {
  name: 'url'
} )
export class Url implements PipeTransform {

  constructor( private sanitizer: DomSanitizer ) {}

  transform( data: any ) {

    if ( typeof data == 'string' ) {

      return this.sanitizer.bypassSecurityTrustStyle( `url(${ data })` );
    }

    if ( data && data.length > 0 ) {

      return this.sanitizer.bypassSecurityTrustStyle( `url(${ data[ 0 ].content || data[ 0 ].url })` );
    }

    return null;
  }
}

@Pipe( {
  name: 'open'
} )
export class Open implements PipeTransform {

  constructor( private translate: TranslateService, private sanitizer: DomSanitizer ) {}

  transform( open: boolean ) {

    var instant = this.translate.instant( open ? 'caption.button.open' : 'caption.button.private' );

    if ( open ) {

      return this.sanitizer.bypassSecurityTrustHtml( `<span class='active-color'>${ instant }</span>` );
    }

    return this.sanitizer.bypassSecurityTrustHtml( `<span class='invalid-color'>${ instant }</span>` )
  }
}

@Pipe( {
  name: 'link'
} )
export class Link implements PipeTransform {

  constructor( private translate: TranslateService, private sanitizer: DomSanitizer ) {}

  transform( text: string ) {

    return this.sanitizer.bypassSecurityTrustHtml( `<a class='point-color' target='_blank' href='${ text }'>${ this.translate.instant( 'caption.button.link' ) }</span>` );
  }
}

@Pipe( {
  name: 'blank'
} )
export class Blank implements PipeTransform {

  constructor( private translate: TranslateService, private sanitizer: DomSanitizer ) {}

  transform( text: string ) {

    var instant = this.translate.instant( 'caption.column.blank' );

    return text || this.sanitizer.bypassSecurityTrustHtml( `<span class='invalid-color'>${ instant }</span>` );
  }
}

@Pipe( {
  name: 'insert'
} )
export class Insert implements PipeTransform {

  constructor( private translate: TranslateService ) {}

  transform( insert: boolean ) {

    return this.translate.instant( insert ? 'caption.button.insert' : 'caption.button.update' );
  }
}

@Pipe( {
  name: 'media'
} )
export class Media implements PipeTransform {

  constructor( private translate: TranslateService, private sanitizer: DomSanitizer ) {}

  transform( editorInfos: Array < EditorInfo > ) {

    // check media
    if ( editorInfos.empty() ) return `<span class='invalid-color'>${ this.translate.instant( 'caption.column.blank' ) }</span>`;

    // get main media
    var editorInfo = editorInfos[ 0 ];

    switch ( editorInfo.type ) {

      case STATUS.IMAGE:
        {
          
          return this.sanitizer.bypassSecurityTrustHtml( `<div style='background-image: url( ${ editorInfo.content } )'></div>` );
        }
      case STATUS.VIDEO:
        {

          return this.sanitizer.bypassSecurityTrustHtml( `<a href='${ editorInfo.content }' target='_blank'><i class='material-icons'>play_circle_filled</i></a>` );
        }

      default:
        {

          return this.sanitizer.bypassSecurityTrustHtml( `<span class='invalid-color'>${ this.translate.instant( 'caption.column.blank' ) }</span>` );
        }
    }
  }
}

@Pipe( {
  name: 'datetime'
} )
export class DateTime implements PipeTransform {

  constructor( private pipe: DatePipe = new DatePipe( 'en-US' ) ) {}

  transform( date: any, time: string, format: string ) {

    if ( typeof date == 'string' ) {

      if ( date && time ) {

        return this.pipe.transform( date.replace( ' ', 'T' ) + ' ' + time, format );
      } else if ( date ) {

        return this.pipe.transform( date.replace( ' ', 'T' ), format );
      } else if ( time ) {

        return this.pipe.transform( time, format );
      }
    } else {

      return this.pipe.transform( date, format );
    }
  }
}

@Pipe( {
  name: 'transparent'
} )
export class Transparent implements PipeTransform {

  constructor() {}

  transform( image: any ) {

    if ( typeof image == 'string' ) {

      return 'transparent';
    }

    if ( image && image.length > 0 ) {

      return 'transparent';
    }

    return null;
  }
}