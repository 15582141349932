import {
  OnInit,
  Component
} from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl
} from '@angular/forms';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  BannerInfo,
  EditorInfo,
  SetBannerRequest,
  DelBannerRequest,
  UpdBannerRequest,
  OpeBannerRequest,
  GetBannersRequest
} from 'src/app/services/http/http.classes';
import {
  EditorPull,
  EditorService,
} from 'src/app/services/editor/editor.service';
import {
  TranslatedService
} from 'src/app/services/translated/translated.service';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-board-banner',
  styleUrls: [ './board-banner.component.css' ],
  templateUrl: './board-banner.component.html'
} )
export class BoardBannerComponent implements OnInit {

  // data
  req: any;
  res: any;
  page: any;
  clone: any;
  checked: boolean;
  selected: number;
  subscription: any;

  // form
  bannerForm: FormGroup;

  constructor(
    private httpService: HttpService,
    private editorService: EditorService,
    private translateService: TranslateService,
    private translatedService: TranslatedService
  ) {

    // data
    this.req = {

      setBanner: new SetBannerRequest(),
      updBanner: new UpdBannerRequest(),
      getBanners: new GetBannersRequest()
    }

    this.res = {

      bannerInfos: new Array < BannerInfo > (),
    }

    this.page = {

      content: new PagerData()
    }

    this.clone = {

      coverInfos: new Array < EditorInfo > ()
    }

    this.checked = false;
    this.selected = 0;

    // form
    this.bannerForm = new FormGroup( {

      checkBox: new FormArray( [] )
    } );

    // event
    this.subscription = {

      editor: this.editorService.pull.subscribe( ( pull: EditorPull ) => {

        switch ( pull.uuid ) {

          case $( 'app-editor#cover' ).data( 'uuid' ):
            {

              this.getRequest.mediaInfos = pull.data;
              break;
            }
        }
      } ),

      translate: this.translatedService.pull.subscribe( () => {

        // get banners
        this.getBanners();
      } )
    }
  }

  ngOnInit() {

    // get banners
    this.getBanners();

    // set translate
    this.translatedService.setOpen( true );
  }

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  ngOnInterface() {

    this.bannerForm.controls.checkBox = new FormArray( [] );

    // form array create
    this.res.bannerInfos.forEach( () => {

      ( < FormArray > this.bannerForm.controls.checkBox ).controls.push( new FormControl() );
    } );

    // init
    this.checked = false;
  }

  // check
  get isInsert() {

    return this.req.setBanner ? true : false;
  }

  // get
  get getRequest() {

    return this.req.setBanner ? this.req.setBanner : this.req.updBanner;
  }

  // http
  getBanner( bannerInfo ? : BannerInfo, selected ? : number ) {

    // check banner 
    if ( bannerInfo ) {

      // update
      this.selected = selected;
      this.req.updBanner = new UpdBannerRequest();
      this.req.setBanner = null;
      this.req.updBanner.onInit( bannerInfo );

      // set media
      this.clone.coverInfos = this.req.updBanner.mediaInfos;

      // open
      M.Modal.getInstance( $( '.modal' ) ).open();
    } else {

      // insert
      this.req.updBanner = null;
      this.req.setBanner = new SetBannerRequest();

      // set media
      this.clone.coverInfos = new Array < EditorInfo > ();

      // open
      M.Modal.getInstance( $( '.modal' ) ).open();
    }
  }

  getBanners( index: number = 1, limit: number = Config.limit ) {

    // set contents
    this.req.getBanners.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } );

    this.httpService.getBanners( this.req.getBanners ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // set data
      this.res.bannerInfos = response.bannerInfos;

      // set page
      this.page.content = new PagerData( response.pageInfo, this.req.getBanners.index );

      // set interface
      this.ngOnInterface();
    } );
  }

  // set
  setOpen( openType: number ) {

    if ( !confirm( this.translateService.instant( openType ? 'message.open' : 'message.private' ) ) ) {

      return;
    }

    // init
    var bannerInfos = new Array < BannerInfo > ();

    ( < FormArray > this.bannerForm.controls.checkBox ).controls.forEach( ( checkbox, index ) => {

      // checked
      if ( checkbox.value ) {

        // push banner
        bannerInfos.push( this.res.bannerInfos[ index ] );
      }
    } );

    // check banner
    if ( bannerInfos.empty() ) {

      return alert( this.translateService.instant( 'message.select' ) );
    }

    // open banner
    this.httpService.opeBanner( new OpeBannerRequest( openType, bannerInfos ) ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      this.getBanners();
    } );
  }

  setRemove() {

    if ( confirm( this.translateService.instant( 'message.remove' ) ) == false ) {

      return;
    }

    // init
    var bannerInfos = new Array < BannerInfo > ();

    ( < FormArray > this.bannerForm.controls.checkBox ).controls.forEach( ( checkbox, index ) => {

      // checked
      if ( checkbox.value ) {

        // push banner
        bannerInfos.push( this.res.bannerInfos[ index ] );
      }
    } );

    // check banner
    if ( bannerInfos.empty() ) {

      return alert( this.translateService.instant( 'message.select' ) );
    }

    // delete banner
    this.httpService.delBanner( new DelBannerRequest( bannerInfos ) ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      this.getBanners();
    } );
  }

  setSubmit( form: any ) {

    // check valid
    if ( form.valid == false ) return alert( this.translateService.instant( 'message.valid' ) );

    // check media
    if ( this.getRequest.mediaInfos.empty() ) return alert( this.translateService.instant( 'message.valid' ) );

    // check insert
    if ( this.isInsert ) {

      this.httpService.setBanner( this.req.setBanner ).subscribe( response => {

        if ( response.code ) {

          return alert( response.message );
        }

        // close
        M.Modal.getInstance( $( '.modal' ) ).close();

        // get banners
        this.getBanners();
      } );

      return;
    }

    this.httpService.updBanner( this.req.updBanner ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // update
      this.res.bannerInfos[ this.selected ] = response.bannerInfo;

      // close
      M.Modal.getInstance( $( '.modal' ) ).close();
    } );
  }

  // action
  onCheckBox( event: any, total: boolean ) {

    let check: boolean = event.target.checked;

    for ( let checkbox of ( < FormArray > this.bannerForm.controls.checkBox ).controls ) {

      if ( total ) {

        checkbox.setValue( event.target.checked );
      } else {

        if ( checkbox.value ) {

          check = true;
          break;
        }
      }
    }

    this.checked = check;
  }

  // media
  onMediaOver( event: Event ) {

    var target = ( < HTMLElement > event.target );

    // check anchor
    if ( target.lastElementChild instanceof HTMLAnchorElement ) return;

    target.classList.add( 'focus' );
  }

  onMediaLeave( event: Event ) {

    var target = ( < HTMLElement > event.target );

    // check anchor
    if ( target.lastElementChild instanceof HTMLAnchorElement ) return;

    target.classList.remove( 'focus' );
  }
}