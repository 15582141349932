import {
  OnInit,
  Component
} from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl
} from '@angular/forms';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  Result,
  CategoryInfo,
  UpdCategoryRequest,
  GetCategoriesRequest
} from 'src/app/services/http/http.classes';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-setting-category',
  styleUrls: [ './setting-category.component.css' ],
  templateUrl: './setting-category.component.html'
} )
export class SettingCategoryComponent implements OnInit {

  // data
  req: any;
  res: any;
  checked: boolean;
  selected: number;

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService ) {

    // data
    this.req = {

      updCategory: new UpdCategoryRequest()
    }

    this.res = {

      categoryInfos: new Array < CategoryInfo > ()
    }

    // init
    this.checked = false;
    this.selected = 0;
  }

  ngOnInit() {

    // get categories
    this.getCategories();
  }

  ngOnDestroy() {}

  // http
  getCategories() {

    this.httpService.getCategories( new GetCategoriesRequest() ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // set data
      this.res.categoryInfos = response.categoryInfos;
    } );
  }

  // action
  onSubmit( form: any ) {

    // check valid
    if ( form.valid == false ) {

      return;
    }

    this.httpService.updCategory( this.req.updCategory ).subscribe( response => {

      if ( response.code ) return alert( response.message );
      
      // update
      this.res.categoryInfos[ this.selected ] = response.categoryInfo;

      // close
      M.Modal.getInstance( $( '.modal' ) ).close();
    } );
  }

  onCategory( categoryInfo: CategoryInfo, selected ? : number ) {


    // update
    this.selected = selected;
    this.req.updCategory = new UpdCategoryRequest();
    this.req.updCategory.onInit( categoryInfo );
    this.req.setCategory = null;

    // open
    M.Modal.getInstance( $( '.modal' ) ).open();
  }
}