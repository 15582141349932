import {
  OnInit,
  Component,
} from '@angular/core';
import {
  NavigationService
} from 'src/app/services/navigation/navigation.service';
import {
  TranslateInfo
} from 'src/app/services/http/http.classes';
import {
  TranslatedService
} from 'src/app/services/translated/translated.service';

@Component( {
  selector: 'app-header',
  styleUrls: [ './header.component.css' ],
  templateUrl: './header.component.html'
} )

export class HeaderComponent implements OnInit {

  constructor(
    private translatedService: TranslatedService,
    private navigationService: NavigationService
  ) {

  }

  ngOnInit() {}

  ngOnDestory() {}

  // check
  get isOpen() {

    return this.translatedService.isOpen;
  }

  // get
  get getMenu() {

    return this.navigationService.getMenu();
  }

  get getTranslate(): TranslateInfo {

    return this.translatedService.getTranslate;
  }

  get getTranslateList(): Array< TranslateInfo > {

    return this.translatedService.getTranslateList;
  }

  // action
  onTranslate( translateInfo: TranslateInfo ) {

    this.translatedService.setTranslate( translateInfo );
  }
}