import {
  OnInit,
  Component
} from '@angular/core';
import {
  Config
} from 'src/app/app.config';
import {
  DateTime
} from 'src/app/pipe/pipe';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  StatisticInfo,
  StatisticInfos,
  StsUserRequest,
} from 'src/app/services/http/http.classes';
import {
  HIGH_FORMAT
} from 'src/app/directive/highchart.directive';
import {
  MaterializeClose
} from 'src/app/directive/materialize.directive';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-statistic-user',
  styleUrls: [ './statistic-user.component.css' ],
  templateUrl: './statistic-user.component.html'
} )
export class StatisticUserComponent implements OnInit {

  // enum
  HIGH_FORMAT = HIGH_FORMAT;

  // public
  public req: any;
  public res: any;

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // date
    var date = new DateTime().transform( new Date(), null, 'yyyy-MM-dd' );

    // data
    this.req = {

      getUser: new StsUserRequest( Config.index, date, date )
    }

    this.res = {

      statisticInfos: new Array < StatisticInfos > ()
    }
  }

  ngOnInit() {

    // get statistic
    this.getStatistic();
  }

  ngOnDateSet() {

    setTimeout( () => {

      this.ngOnDateClose( new MaterializeClose( $( '#started' ) ) );
      this.ngOnDateClose( new MaterializeClose( $( '#ended' ) ) );

    }, 10 );
  }

  ngOnDateClose( close: MaterializeClose ) {

    var date = $( close.target ).val();

    // check id
    switch ( $( close.target ).attr( 'id' ) ) {

      case 'ended': {

        // set max date 
        M.Datepicker.getInstance( $( '#started' ) ).options.maxDate = new Date( date );

        if ( close.update ) {

          this.getStatistic();
        }
        break;
      }
      case 'started': {

        // set min date 
        M.Datepicker.getInstance( $( '#ended' ) ).options.minDate = new Date( date );

        if ( close.update ) {

          this.getStatistic();
        }
        break;
      }
    }
  }

  // get 
  getTotal( statisticInfos: Array < StatisticInfo > ) {

    if ( statisticInfos.empty() ) return 0;

    return statisticInfos.reduce( function( prev: StatisticInfo, current: StatisticInfo ) {

      return new StatisticInfo( {

        counted: prev.counted + current.counted
      } )
    } ).counted;
  }

  get getStatus() {

    if ( typeof this.req.getUser.statisticType == 'string' ) {

      return parseInt( this.req.getUser.statisticType );
    }

    return this.req.getUser.statisticType;
  }

  getOptionDate( init: Function ) {

    var months = this.translateService.instant( 'caption.date.month' );
    var weekdays = this.translateService.instant( 'caption.date.weekday' );

    init( {

      i18n: {

        done: this.translateService.instant( 'caption.button.done' ),
        clear: this.translateService.instant( 'caption.button.clear' ),
        cancel: this.translateService.instant( 'caption.button.cancel' ),
        months: months,
        monthsShort: months,
        weekdays: weekdays,
        weekdaysShort: weekdays,
        weekdaysAbbrev: weekdays,
      },
      format: 'yyyy-mm-dd',
      minDate: '',
      maxDate: ''
    } );

    // set min & max date
    this.ngOnDateSet();
  }

  // http
  getStatistic() {

    this.httpService.stsUser( this.req.getUser ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.statisticInfos = response.statisticInfos;
    } );
  }
}