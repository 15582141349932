

export enum USER_BLOCK {

  INSERT = 0, DELETE, UPDATE
}

export enum USER_STATUS {

  ACTIVE = 0, BLOCK, DROP
}

export enum CONTACT_STATUS {
  
  REQUEST = 0, RECEIPT, RESPONSE
}