import {
  OnInit,
  Component
} from '@angular/core';
import {
  Config
} from 'src/app/app.config';
import {
  DateTime
} from 'src/app/pipe/pipe';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  PollInfo,
  StatisticInfo,
  StatisticInfos,
  StsPollRequest,
  GetPollsRequest,
} from 'src/app/services/http/http.classes';
import {
  HIGH_FORMAT,
  HighChartColumn
} from 'src/app/directive/highchart.directive';
import {
  MaterializeClose
} from 'src/app/directive/materialize.directive';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-statistic-poll',
  styleUrls: [ './statistic-poll.component.css' ],
  templateUrl: './statistic-poll.component.html'
} )
export class StatisticPollComponent implements OnInit {

  // enum
  HIGH_FORMAT = HIGH_FORMAT;

  // public
  public req: any;
  public res: any;
  public page: any;

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // data
    this.req = {

      getPoll: new StsPollRequest(),
      getPolls: new GetPollsRequest()
    }

    this.res = {

      pollInfo: new PollInfo( {

        manager: this.translateService.instant( 'caption.column.statistic.date.total' )
      } ),
      pollInfos: new Array < PollInfo > (),
      highchartInfos: new Array < HighChartColumn > (),
      statisticInfos: {
        date: new Array < StatisticInfos > (),
        item: new Array < StatisticInfos > (),
      }
    }

    this.page = {

      content: new PagerData()
    }

    // date
    var date = new DateTime().transform( new Date(), null, 'yyyy-MM-dd' );

    // init
    this.req.getPoll.onInit( {

      ended: date,
      started: date
    } );
  }

  ngOnInit() {

    // get polls
    this.getPolls();
  }

  ngOnDateSet() {

    setTimeout( () => {

      this.ngOnDateClose( new MaterializeClose( $( '#started' ) ) );
      this.ngOnDateClose( new MaterializeClose( $( '#ended' ) ) );

    }, 10 );
  }

  ngOnDateClose( close: MaterializeClose ) {

    var date = $( close.target ).val();

    // check id
    switch ( $( close.target ).attr( 'id' ) ) {

      case 'ended': {

        // set max date 
        M.Datepicker.getInstance( $( '#started' ) ).options.maxDate = new Date( date );

        if ( close.update ) {

          this.getStatistic();
        }
        break;
      }
      case 'started': {

        // set min date 
        M.Datepicker.getInstance( $( '#ended' ) ).options.minDate = new Date( date );

        if ( close.update ) {

          this.getStatistic();
        }
        break;
      }
    }
  }

  ngOnDateOption( init: Function ) {

    var months = this.translateService.instant( 'caption.date.month' );
    var weekdays = this.translateService.instant( 'caption.date.weekday' );

    init( {

      i18n: {

        done: this.translateService.instant( 'caption.button.done' ),
        clear: this.translateService.instant( 'caption.button.clear' ),
        cancel: this.translateService.instant( 'caption.button.cancel' ),
        months: months,
        monthsShort: months,
        weekdays: weekdays,
        weekdaysShort: weekdays,
        weekdaysAbbrev: weekdays,
      },
      format: 'yyyy-mm-dd',
      minDate: '',
      maxDate: ''
    } );

    // set min & max date
    this.ngOnDateSet();
  }

  ngOnChartSerialize( highchartInfos: Array < HighChartColumn > ) {

    // set data
    this.res.highchartInfos = highchartInfos;
  }

  // get
  getTotal( statisticInfos: Array < StatisticInfo > = null ) {

    // check data
    if ( statisticInfos ) {

      if ( statisticInfos.empty() ) return 0;

      return statisticInfos.reduce( function( prev: StatisticInfo, current: StatisticInfo ) {

        return new StatisticInfo( {

          counted: prev.counted + current.counted
        } )
      } ).counted;
    }

    if ( this.res.highchartInfos.empty() ) return 0;

    return this.res.highchartInfos.reduce( function( prev: HighChartColumn, current: HighChartColumn ) {

      return new HighChartColumn( null, prev.sum + current.sum );

    } ).sum;
  }

  get getStatus() {

    if ( typeof this.req.getPoll.statisticType == 'string' ) {

      return parseInt( this.req.getPoll.statisticType );
    }

    return this.req.getPoll.statisticType;
  }

  // http
  getPolls( index: number = 1, limit: number = Config.limit ) {

    Promise.resolve().then( () => {

      return new Promise( ( resolve, reject ) => {

        // set contents
        this.req.getPolls.onInit( {

          index: limit * ( index - 1 ),
          limit: limit
        } );

        this.httpService.getPolls( this.req.getPolls ).subscribe( response => {

          if ( response.code ) return reject( response.message );

          // set data
          this.res.pollInfos = response.pollInfos;

          // set request
          this.req.getPoll.onInit( {

            pollId: Config.index
          } );

          // set page
          this.res.page = new PagerData( response.pageInfo, this.req.getPolls.index );

          resolve();
        } );
      } );
    } ).then( () => {

      // set poll
      this.req.getPoll.pollId = Config.index;

      // set current
      this.res.pollInfo = new PollInfo( {

        manager: this.translateService.instant( 'caption.column.statistic.date.total' )
      } );

      // get statistic
      this.getStatistic();

    } ).catch( ( message: string ) => {

      if ( message ) alert( message );
    } );
  }

  getStatistic() {

    // get request
    this.httpService.stsPoll( this.req.getPoll ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.statisticInfos.date = response.dateInfos;
      this.res.statisticInfos.item = response.itemInfos;
    } );
  }

  // action
  onPoll( pollInfo: PollInfo = null ) {

    // set poll
    this.req.getPoll.pollId = pollInfo ? pollInfo.pollId : Config.index;

    // set current
    this.res.pollInfo = pollInfo ? pollInfo : new PollInfo( {

      manager: this.translateService.instant( 'caption.column.statistic.date.total' )
    } );

    // get statistic
    this.getStatistic();
  }
}