import {
  OnInit,
  Component
} from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl
} from '@angular/forms';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  DateTime
} from 'src/app/pipe/pipe';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  PollInfo,
  BoardInfo,
  IndexInfo,
  EditorInfo,
  CategoryInfo,
  RelationInfo,
  SetBoardRequest,
  DelBoardRequest,
  UpdBoardRequest,
  GetBoardRequest,
  GetIndexRequest,
  OpeBoardRequest,
  GetPollsRequest,
  GetBoardsRequest,
  GetCategoriesRequest,
} from 'src/app/services/http/http.classes';
import {
  STATUS,
  EditorPull,
  EditorService,
} from 'src/app/services/editor/editor.service';
import {
  TranslatedService
} from 'src/app/services/translated/translated.service';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-board-post',
  styleUrls: [ './board-post.component.css' ],
  templateUrl: './board-post.component.html'
} )
export class BoardPostComponent implements OnInit {

  // data
  req: any;
  res: any;
  page: any;
  clone: any;
  mained: boolean;
  checked: boolean;
  selected: number;
  subscription: any;

  // form
  postForm: FormGroup;

  constructor(
    private httpService: HttpService,
    private editorService: EditorService,
    private translateService: TranslateService,
    private translatedService: TranslatedService
  ) {

    // data
    this.req = {

      setBoard: new SetBoardRequest(),
      updBoard: new UpdBoardRequest(),
      getPolls: new GetPollsRequest(),
      getBoards: new GetBoardsRequest(),
      getRelations: new GetBoardsRequest()
    }

    this.res = {

      boardInfo: new BoardInfo(),
      pollInfos: new Array < PollInfo > (),
      mainInfos: new Array < EditorInfo > (),
      boardInfos: new Array < BoardInfo > (),
      coverInfos: new Array < EditorInfo > (),
      editorInfos: new Array < EditorInfo > (),
      relationInfos: new Array < RelationInfo > (),
      categoryInfos: new Array < CategoryInfo > (),
    }

    this.page = {

      poll: new PagerData(),
      content: new PagerData(),
      relation: new PagerData()
    }

    this.clone = {

      pollInfos: new Array < PollInfo > (),
      mainInfos: new Array < EditorInfo > (),
      coverInfos: new Array < EditorInfo > (),
      editorInfos: new Array < EditorInfo > (),
    }

    this.mained = false;
    this.checked = false;
    this.selected = 0;

    // form
    this.postForm = new FormGroup( {

      checkBox: new FormArray( [] )
    } );

    // event
    this.subscription = {

      editor: this.editorService.pull.subscribe( ( pull: EditorPull ) => {

        switch ( pull.uuid ) {

          case $( 'app-editor#main' ).data( 'uuid' ): {

            this.res.mainInfos = pull.data;
            break;
          }
          case $( 'app-editor#cover' ).data( 'uuid' ): {

            this.res.coverInfos = pull.data;
            break;
          }
          case $( 'app-editor#editor' ).data( 'uuid' ): {

            this.getRequest.editorInfos = pull.data;
            break;
          }
        }
      } ),

      translate: this.translatedService.pull.subscribe( () => {

        // get boards
        this.getBoards();
      } )
    }
  }

  ngOnInit() {

    // get chain
    this.getChain();

    // set translate
    this.translatedService.setOpen( true );
  }

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  ngOnInterface() {

    this.postForm.controls.checkBox = new FormArray( [] );

    // form array create
    this.res.boardInfos.forEach( () => {

      ( < FormArray > this.postForm.controls.checkBox ).controls.push( new FormControl() );
    } );

    // init
    this.checked = false;
  }

  // check
  get isPoll() {

    return this.req.getBoards.categoryType == Config.category.poll;
  }

  get isPost() {

    var categoryInfo = this.res.categoryInfos[ this.req.getBoards.categoryType - 1 ];

    return categoryInfo ? categoryInfo.menu : false;
  }

  get isCover() {

    return this.req.getBoards.categoryType != Config.category.poll && this.req.getBoards.categoryType != Config.category.tv
  }

  get isInsert() {

    return this.req.setBoard ? true : false;
  }

  get isImageOnly() {

    return this.req.getBoards.categoryType != Config.category.tv;
  }

  get isVideoOnly() {

    return this.req.getBoards.categoryType == Config.category.tv;
  }

  // get
  getCategory( categoryType: number = this.req.getBoards.categoryType ) {

    return this.res.categoryInfos[ categoryType - 1 ] ? this.res.categoryInfos[ categoryType - 1 ].name : null;
  }

  get getRequest() {

    return this.req.setBoard ? this.req.setBoard : this.req.updBoard;
  }

  // http
  getChain() {

    Promise.resolve().then( () => {

      // get category
      return this.getCategories();

    } ).then( () => {

      // get board
      this.getBoards();

    } ).catch( ( message: string ) => {

      // check message
      if ( message ) alert( message );
    } );
  }

  getPolls( index: number = 1, limit: number = Config.limit ) {

    // set contents
    this.req.getPolls.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } );

    this.httpService.getPolls( this.req.getPolls ).subscribe( response => {

      if ( response.code ) alert( response.message );

      // set data
      this.res.pollInfos = response.pollInfos;

      // set page
      this.page.poll = new PagerData( response.pageInfo, this.req.getPolls.index );
    } );
  }

  getIndex( input: any ) {

    let query = input.value.replace( /[^a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9]/g, '' );

    this.httpService.getIndex( new GetIndexRequest( query ) ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // check index
      if ( response.indexInfo ) {

        this.getRequest.indexInfos.push( response.indexInfo );
      } else {

        this.getRequest.indexInfos.push( new IndexInfo( {

          manager: query
        } ) );
      }

      // set clear
      input.value = null;
    } );
  }

  getBoard( boardInfo ? : BoardInfo, selected ? : number ) {

    // init
    this.res.pollInfos = new Array();
    this.res.relationInfos = new Array();

    this.req.getPolls.query = null;
    this.req.getRelations.query = null;

    // check board 
    if ( boardInfo ) {

      // update
      this.selected = selected;
      this.req.updBoard = new UpdBoardRequest();
      this.req.setBoard = null;

      // get board
      this.httpService.getBoard( new GetBoardRequest( boardInfo.boardId ) ).subscribe( response => {

        if ( response.code ) return alert( response.message );

        // set board
        this.req.updBoard.onInit( response );
        this.req.updBoard.onInit( response.boardInfo );
        this.req.updBoard.onInit( response.boardInfo.dataInfo );

        if ( this.isPoll ) {

          // set media
          this.res.mainInfos = this.req.updBoard.mediaInfos;

          // set media clone
          this.clone.mainInfos = this.req.updBoard.mediaInfos.clone();
        } else {

          // check media
          if ( this.req.updBoard.mediaInfos.length > 0 ) {

            // set media
            this.res.mainInfos = new Array( this.req.updBoard.mediaInfos[ 0 ] );
            this.res.coverInfos = this.req.updBoard.mediaInfos[ 1 ] ? new Array( this.req.updBoard.mediaInfos[ 1 ] ) : new Array();

            // set media clone
            this.clone.mainInfos = new Array( this.req.updBoard.mediaInfos[ 0 ] );
            this.clone.coverInfos = this.req.updBoard.mediaInfos[ 1 ] ? new Array( this.req.updBoard.mediaInfos[ 1 ] ) : new Array();
          }
        }

        // set editor
        this.clone.pollInfos = this.req.updBoard.pollInfos.clone();
        this.clone.editorInfos = this.req.updBoard.editorInfos.clone();

        // open
        M.Modal.getInstance( $( '.modal' ) ).open();
      } );
    } else {

      // insert
      this.req.updBoard = null;
      this.req.setBoard = new SetBoardRequest();
      this.req.setBoard.categoryType = this.req.getBoards.categoryType;

      // set media & editor
      this.clone.pollInfos = new Array < PollInfo > ();
      this.clone.mainInfos = new Array < EditorInfo > ();
      this.clone.coverInfos = new Array < EditorInfo > ();
      this.clone.editorInfos = new Array < EditorInfo > ();

      // open
      M.Modal.getInstance( $( '.modal' ) ).open();
    }
  }

  getBoards( index: number = 1, limit: number = Config.limit ) {

    // set contents
    this.req.getBoards.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } );

    this.httpService.getBoards( this.req.getBoards ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // set data
      this.res.boardInfos = response.boardInfos;

      // set page
      this.page.content = new PagerData( response.pageInfo, this.req.getBoards.index );

      // set interface
      this.ngOnInterface();
    } );
  }

  getRelations( index: number = 1, limit: number = Config.limit ) {

    // set contents
    this.req.getRelations.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } );

    this.httpService.getRelations( this.req.getRelations ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // set data
      this.res.relationInfos = response.relationInfos;

      // set page
      this.page.relation = new PagerData( response.pageInfo, this.req.getRelations.index );
    } );
  }

  getCategories() {

    return new Promise( ( resolve, rejected ) => {

      this.httpService.getCategories( new GetCategoriesRequest() ).subscribe( response => {

        if ( response.code ) {

          // set reject
          rejected( response.message );
        }

        // set data
        this.res.categoryInfos = response.categoryInfos;

        // set header type
        this.req.getBoards.categoryType = this.res.categoryInfos[ 0 ].categoryType;
        this.req.getRelations.categoryType = this.res.categoryInfos[ 0 ].categoryType;

        // set resolve
        resolve();
      } );
    } );
  }

  getOptionDate( init: Function ) {

    var months = this.translateService.instant( 'caption.date.month' );
    var weekdays = this.translateService.instant( 'caption.date.weekday' );

    init( {

      i18n: {

        done: this.translateService.instant( 'caption.button.done' ),
        clear: this.translateService.instant( 'caption.button.clear' ),
        cancel: this.translateService.instant( 'caption.button.cancel' ),
        months: months,
        monthsShort: months,
        weekdays: weekdays,
        weekdaysShort: weekdays,
        weekdaysAbbrev: weekdays,
      },
      format: 'yyyy-mm-dd',
      minDate: '',
      maxDate: ''
    } );
  }

  getOptionTime( init: Function ) {

    init( {
      i18n: {
        done: this.translateService.instant( 'caption.button.done' ),
        clear: this.translateService.instant( 'caption.button.clear' ),
        cancel: this.translateService.instant( 'caption.button.cancel' )
      }
    } );
  }

  // set
  setOpen( openType: number ) {

    if ( !confirm( this.translateService.instant( openType ? 'message.open' : 'message.private' ) ) ) {

      return;
    }

    // init
    var boardInfos = new Array < BoardInfo > ();

    ( < FormArray > this.postForm.controls.checkBox ).controls.forEach( ( checkbox, index ) => {

      // checked
      if ( checkbox.value ) {

        // push board
        boardInfos.push( this.res.boardInfos[ index ] );
      }
    } );

    // check board
    if ( boardInfos.empty() ) {

      return alert( this.translateService.instant( 'message.select' ) );
    }

    // open board
    this.httpService.opeBoard( new OpeBoardRequest( openType, boardInfos ) ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      this.getBoards();
    } );
  }

  setSort( value: number ) {

    this.getRequest.sorted = value;
  }

  setRemove() {

    if ( confirm( this.translateService.instant( 'message.remove' ) ) == false ) {

      return;
    }

    // init
    var boardInfos = new Array < BoardInfo > ();

    ( < FormArray > this.postForm.controls.checkBox ).controls.forEach( ( checkbox, index ) => {

      // checked
      if ( checkbox.value ) {

        // push board
        boardInfos.push( this.res.boardInfos[ index ] );
      }
    } );

    // check board
    if ( boardInfos.empty() ) {

      return alert( this.translateService.instant( 'message.select' ) );
    }

    // delete board
    this.httpService.delBoard( new DelBoardRequest( boardInfos ) ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      this.getBoards();
    } );
  }

  setSubmit( form: any ) {

    // check valid
    if ( form.valid == false ) return alert( this.translateService.instant( 'message.valid' ) );

    // check media
    if ( this.isPost ) {

      if ( this.res.mainInfos.empty() || this.getRequest.editorInfos.empty() ) {

        return alert( this.translateService.instant( 'message.valid' ) );
      }

      // set media
      this.getRequest.mediaInfos = this.res.mainInfos.concat( this.res.coverInfos );
    }

    // check poll
    if ( this.isPoll ) {

      var poll = this.getRequest.pollInfos.some( ( pollInfo: PollInfo ) => {

        return pollInfo.sorted > 0;
      } ) || false;

      if ( !poll ) return alert( this.translateService.instant( 'message.poll' ) );
    }

    // set relation
    this.getRequest.relationInfos.forEach( ( relationInfo: RelationInfo, sort: number ) => {

      relationInfo.sorted = sort;
    } );

    // set opened
    this.getRequest.opened = new DateTime().transform( $( '#open' ).val(), $( '#time' ).val(), 'yyyy-MM-dd HH:mm:ss' );

    // check opend
    if ( this.getRequest.opened == null ) return alert( this.translateService.instant( 'message.valid' ) );

    // check insert
    if ( this.isInsert ) {

      this.httpService.setBoard( this.req.setBoard ).subscribe( response => {

        if ( response.code ) {

          return alert( response.message );
        }

        // init
        this.req.setBoard = new SetBoardRequest();

        // close
        M.Modal.getInstance( $( '.modal' ) ).close();

        // get boards
        this.getBoards();
      } );

      return;
    }

    this.httpService.updBoard( this.req.updBoard ).subscribe( response => {

      if ( response.code ) {

        return alert( response.message );
      }

      // update
      this.res.boardInfos[ this.selected ] = response.boardInfo;

      // init
      this.req.updBoard = new UpdBoardRequest();

      // close
      M.Modal.getInstance( $( '.modal' ) ).close();
    } );
  }

  // action
  onCheckBox( event: any, total: boolean ) {

    let check: boolean = event.target.checked;

    for ( let checkbox of ( < FormArray > this.postForm.controls.checkBox ).controls ) {

      if ( total ) {

        checkbox.setValue( event.target.checked );
      } else {

        if ( checkbox.value ) {

          check = true;
          break;
        }
      }
    }

    this.checked = check;
  }

  // media
  onMediaOver( event: Event ) {

    var target = ( < HTMLElement > event.target );

    // check anchor
    if ( target.lastElementChild instanceof HTMLAnchorElement ) return;

    target.classList.add( 'focus' );
  }

  onMediaLeave( event: Event ) {

    var target = ( < HTMLElement > event.target );

    // check anchor
    if ( target.lastElementChild instanceof HTMLAnchorElement ) return;

    target.classList.remove( 'focus' );
  }

  // poll
  onPollInsert( pollInfo: PollInfo ) {

    if ( this.getRequest.pollInfos.some( ( _pollInfo: PollInfo ) => {

        return pollInfo.pollId == _pollInfo.pollId;
      } ) ) {

      return alert( this.translateService.instant( 'message.contain' ) );
    }

    // set sorted
    pollInfo.sorted = 0;

    // set poll
    this.getRequest.pollInfos.push( pollInfo );

    // set clone
    this.clone.pollInfos = this.getRequest.pollInfos.clone();
  }

  onPollSelect( pollInfo: PollInfo ) {

    this.getRequest.pollInfos.forEach( ( _pollInfo: PollInfo ) => {

      _pollInfo.sorted = pollInfo.pollId == _pollInfo.pollId ? 1 : 0;
    } );
  }

  onPollRemove( pollInfo: PollInfo ) {

    // remove poll
    this.getRequest.pollInfos.splice( this.getRequest.pollInfos.indexOf( pollInfo ), 1 );
    this.getRequest.editorInfos.forEach( ( editorInfo: EditorInfo, index: number ) => {

      // check poll
      if (
        editorInfo.type == STATUS.POLL &&
        editorInfo.identity == pollInfo.pollId
      ) {

        this.getRequest.editorInfos.splice( index, 1 );
      }
    } );

    // set clone
    this.clone.editorInfos = this.getRequest.editorInfos.clone();
    this.clone.pollInfos = this.getRequest.pollInfos.clone();
  }

  // chip
  onChipInsert( input: any ) {

    // set trim
    let query = input.value.replace( /[^a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9]/g, '' );

    // check length
    if ( query.length < 2 ) return alert( this.translateService.instant( 'message.minimum', {

      number: 1
    } ) );

    if ( query.length > 20 ) return alert( this.translateService.instant( 'message.maximum', {

      number: 20
    } ) );

    // check contain
    if ( this.getRequest.indexInfos.some( ( indexInfo: IndexInfo ) => {

        return indexInfo.manager == query;
      } ) ) {

      return alert( this.translateService.instant( 'message.contain' ) );
    }

    // get index 
    this.getIndex( input );
  }

  onChipSelect( indexInfo: IndexInfo ) {

    // set sorted
    this.getRequest.indexInfos.forEach( ( _indexInfo: IndexInfo ) => {

      if ( indexInfo == _indexInfo ) {

        _indexInfo.sorted = _indexInfo.sorted ? 0 : 1;
      }
    } );
  }

  onChipRemove( indexInfo: IndexInfo ) {

    var index = this.getRequest.indexInfos.indexOf( indexInfo );

    this.getRequest.indexInfos.splice( index, 1 );
  }

  // relation
  onRelationInsert( relationInfo: RelationInfo ) {

    if ( this.getRequest.relationInfos.some( ( _relationInfo: RelationInfo ) => {

        return relationInfo.relationId == _relationInfo.relationId;
      } ) ) {

      return alert( this.translateService.instant( 'message.contain' ) );
    }

    this.getRequest.relationInfos.push( relationInfo );
  }

  onRelationRemove( relationInfo: RelationInfo ) {

    this.getRequest.relationInfos.splice( this.getRequest.relationInfos.indexOf( relationInfo ), 1 );
  }
}