import {
  Injectable,
  EventEmitter
} from '@angular/core';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  TranslateInfo,
  GetTranslatesRequest
} from 'src/app/services/http/http.classes';

@Injectable()
export class TranslatedService {

  public pull: EventEmitter < any > = new EventEmitter();

  private res: any;
  private open: boolean = false;

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // init
    this.res = {

      translateInfo: new TranslateInfo(),
      translateInfos: new Array < TranslateInfo > ()
    }
  }

  // check
  get isOpen() {

    return this.open;
  }

  // get
  get getTranslate() {

    return this.res.translateInfo;
  }

  get getTranslateList() {

    return this.res.translateInfos;
  }

  // set
  setOpen( open: boolean ) {

    this.open = open;
  }

  setLanguage() {

    // set language
    this.httpService.setLanguage( this.translateService.getBrowserCultureLang() );

    // set translate
    this.translateService.use( Config.translate.base.code );
    this.translateService.setDefaultLang( Config.translate.base.code );
  }

  setTranslate( translateInfo: TranslateInfo ) {

    // set translate
    this.res.translateInfo = translateInfo;

    // set http 
    this.httpService.setTranslate( this.res.translateInfo.translateType );

    // set event
    this.pull.emit();
  }

  // http
  getTranslates() {

    return new Promise( ( resolve ) => {

      // check translate
      if ( this.getTranslateList.length > 0 ) {

        return resolve();
      }

      // get translate
      this.httpService.getTranslates( new GetTranslatesRequest() ).subscribe( response => {

        if ( response.code ) {

          alert( response.message );

          return resolve();
        }

        this.res.translateInfos = response.translateInfos;

        // set translate
        this.setLanguage();
        this.setTranslate( response.translateInfos[ 0 ] );

        return resolve();
      } );
    } )
  }
}