import {
  Component,
  OnInit
} from '@angular/core';
import {
  TranslateService
} from '@ngx-translate/core';

import {
  Config
} from 'src/app/app.config';

@Component( {
  selector: 'app-root',
  styleUrls: [ './app.component.css' ],
  templateUrl: './app.component.html'
} )

export class AppComponent implements OnInit {

  constructor(
    private translateService: TranslateService
  ) {

    // set translate
    this.translateService.setDefaultLang( Config.translate.base.code );
    this.translateService.use( Config.translate.base.code );
  }

  ngOnInit() {}
}
