import {
  OnInit,
  Component,
} from '@angular/core';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  StsDashRequest,
  StatisticInfos
} from 'src/app/services/http/http.classes';
import {
  HIGH_FORMAT
} from 'src/app/directive/highchart.directive';

@Component( {
  selector: 'app-statistic-dash',
  templateUrl: './statistic-dash.component.html',
  styleUrls: [ './statistic-dash.component.css' ]
} )
export class StatisticDashComponent implements OnInit {

  // enum
  public HIGH_FORMAT = HIGH_FORMAT;

  // public
  public res: any;

  constructor(
    private httpService: HttpService
  ) {

    this.res = {

      statisticInfos: new Array < StatisticInfos > ()
    }
  }

  ngOnInit() {

    // get statistic
    this.getStatistic();
  }

  // http
  getStatistic() {

    this.httpService.stsDash( new StsDashRequest() ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.statisticInfos = response.statisticInfos;
    } )
  }
}