import {
  OnInit,
  Component
} from '@angular/core';
import {
  Config
} from 'src/app/app.config';
import {
  DateTime
} from 'src/app/pipe/pipe';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  BannerInfo,
  StatisticInfo,
  StatisticInfos,
  StsBannerRequest,
  GetBannersRequest
} from 'src/app/services/http/http.classes';
import {
  HIGH_FORMAT
} from 'src/app/directive/highchart.directive';
import {
  MaterializeClose
} from 'src/app/directive/materialize.directive';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';

declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-statistic-banner',
  styleUrls: [ './statistic-banner.component.css' ],
  templateUrl: './statistic-banner.component.html'
} )
export class StatisticBannerComponent implements OnInit {

  // enum
  HIGH_FORMAT = HIGH_FORMAT;

  // public
  public req: any;
  public res: any;
  public page: any;

  constructor(
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // data
    this.req = {

      getBanner: new StsBannerRequest(),
      getBanners: new GetBannersRequest()
    }

    this.res = {

      bannerInfo: new BannerInfo( {

        subject: this.translateService.instant( 'caption.column.statistic.date.total' )
      } ),
      bannerInfos: new Array < BannerInfo > (),
      statisticInfos: new Array < StatisticInfos > ()
    }

    this.page = {

      content: new PagerData()
    }

    // date
    var date = new DateTime().transform( new Date(), null, 'yyyy-MM-dd' );

    // init
    this.req.getBanner.onInit( {

      ended: date,
      started: date
    } );
  }

  ngOnInit() {

    // get banners
    this.getBanners();
  }

  ngOnDateSet() {

    setTimeout( () => {

      this.ngOnDateClose( new MaterializeClose( $( '#started' ) ) );
      this.ngOnDateClose( new MaterializeClose( $( '#ended' ) ) );

    }, 10 );
  }

  ngOnDateClose( close: MaterializeClose ) {

    var date = $( close.target ).val();

    // check id
    switch ( $( close.target ).attr( 'id' ) ) {

      case 'ended': {

        // set max date 
        M.Datepicker.getInstance( $( '#started' ) ).options.maxDate = new Date( date );

        if ( close.update ) {

          this.getStatistic();
        }
        break;
      }
      case 'started': {

        // set min date 
        M.Datepicker.getInstance( $( '#ended' ) ).options.minDate = new Date( date );

        if ( close.update ) {

          this.getStatistic();
        }
        break;
      }
    }
  }

  ngOnDateOption( init: Function ) {

    var months = this.translateService.instant( 'caption.date.month' );
    var weekdays = this.translateService.instant( 'caption.date.weekday' );

    init( {

      i18n: {

        done: this.translateService.instant( 'caption.button.done' ),
        clear: this.translateService.instant( 'caption.button.clear' ),
        cancel: this.translateService.instant( 'caption.button.cancel' ),
        months: months,
        monthsShort: months,
        weekdays: weekdays,
        weekdaysShort: weekdays,
        weekdaysAbbrev: weekdays,
      },
      format: 'yyyy-mm-dd',
      minDate: '',
      maxDate: ''
    } );

    // set min & max date
    this.ngOnDateSet();
  }

  // get 
  getTotal( statisticInfos: Array < StatisticInfo > ) {

    if ( statisticInfos.empty() ) return 0;

    return statisticInfos.reduce( function( prev: StatisticInfo, current: StatisticInfo ) {

      return new StatisticInfo( {

        counted: prev.counted + current.counted
      } )
    } ).counted;
  }

  get getStatus() {

    if ( typeof this.req.getBanner.statisticType == 'string' ) {

      return parseInt( this.req.getBanner.statisticType );
    }

    return this.req.getBanner.statisticType;
  }

  // http
  getBanners( index: number = 1, limit: number = Config.limit ) {

    Promise.resolve().then( () => {

      return new Promise( ( resolve, reject ) => {

        // set contents
        this.req.getBanners.onInit( {

          index: limit * ( index - 1 ),
          limit: limit
        } );

        this.httpService.getBanners( this.req.getBanners ).subscribe( response => {

          if ( response.code ) return reject( response.message );

          // set data
          this.res.bannerInfos = response.bannerInfos;

          // set request
          this.req.getBanner.onInit( {

            bannerId: Config.index
          } );

          // set page
          this.res.page = new PagerData( response.pageInfo, this.req.getBanners.index );

          resolve();
        } );
      } );
    } ).then( () => {

      // set banner
      this.req.getBanner.bannerId = Config.index;

      // set current
      this.res.bannerInfo = new BannerInfo( {

        subject: this.translateService.instant( 'caption.column.statistic.date.total' )
      } );

      // get statistic
      this.getStatistic();

    } ).catch( ( message: string ) => {

      if ( message ) alert( message );
    } );
  }

  getStatistic() {

    // get request
    this.httpService.stsBanner( this.req.getBanner ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.statisticInfos = response.statisticInfos;
    } );
  }

  // action
  onBanner( bannerInfo: BannerInfo = null ) {

    // set banner
    this.req.getBanner.bannerId = bannerInfo ? bannerInfo.bannerId : Config.index;

    // set current
    this.res.bannerInfo = bannerInfo ? bannerInfo : new BannerInfo( {

      subject: this.translateService.instant( 'caption.column.statistic.date.total' )
    } );

    // get statistic
    this.getStatistic();
  }
}