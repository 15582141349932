import {
  Injectable
} from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import {
  Result
} from './http.classes';
import {
  HttpService
} from './http.service';
import {
  Config
} from 'src/app/app.config';
import {
  TranslatedService
} from 'src/app/services/translated/translated.service';

@Injectable()
export class HttpGuard implements CanActivate {
  constructor(
    private router: Router,
    private httpService: HttpService,
    private translatedService: TranslatedService
  ) {}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Promise < boolean > {

    return new Promise( ( resolve ) => {

      Promise.resolve().then( () => {

        return this.translatedService.getTranslates();
      } ).then( () => {

        this.httpService.getCheck( null ).subscribe( response => {

          if ( response.code === Result.ADMIN_SUCCESS ) {

            return resolve( true );
          } else {

            this.router.navigate( [ Config.base.sign.path ] );

            return resolve( false );
          }
        } );
      } );
    } );
  }
}