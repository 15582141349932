Array.prototype.clone = arrayClone;
Array.prototype.empty = arrayEmpty;

interface Array<T> {

  clone: typeof arrayClone;
  empty: typeof arrayEmpty;
}

function arrayEmpty() {

  return this.length == 0;
}

function arrayClone() {

  return this.concat( [] );
}