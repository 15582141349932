import {
  Input,
  Output,
  Directive,
  OnChanges,
  ElementRef,
  EventEmitter,
} from "@angular/core";
import {
  TranslateService
} from "@ngx-translate/core";
import {
  StatisticInfo,
  StatisticInfos
} from "../services/http/http.classes";

declare var $: any;
declare var Highcharts: any;

export enum HIGH_FORMAT {

  DAY = 0, MON, YEAR
}

@Directive( {
  selector: '[highchartInit], [highchartOption.name], [highchartOption.data], [highchartOption.date], [highchartOption.serialize], [highchartOption.rotation], [highchartOption.interval]'
} )
export class HighchartInit implements OnChanges {

  private _element: any;

  // input
  @Input() highchartInit: string;
  @Input( 'highchartOption.data' ) data: any;
  @Input( 'highchartOption.name' ) name: string;
  @Input( 'highchartOption.date' ) date: boolean = true;
  @Input( 'highchartOption.interval' ) format: HIGH_FORMAT;
  @Input( 'highchartOption.rotation' ) rotation: number = 0;

  // output
  @Output( 'highchartOption.serialize' ) serialize: EventEmitter < Array < HighChartColumn > > = new EventEmitter < Array < HighChartColumn > > ();

  constructor(
    private elementRef: ElementRef,
    private translateService: TranslateService
  ) {

    // get element
    this._element = this.elementRef.nativeElement;
  }

  ngOnChanges() {

    // check data
    if ( this.data.empty() ) return;

    // set highchart
    this.ngOnHighchart();
  }

  ngOnHighchart() {

    switch ( this.highchartInit ) {

      case 'line': {

        this.onLine();

        break;
      }
      case 'column': {

        this.onColumn();

        break;
      }
    }
  }

  // get 
  getUTC( time: number ) {

    var date = new Date( time );

    return Date.UTC( date.getFullYear(), date.getMonth(), date.getDate() );
  }

  getFormat(): HighChartFormat {

    switch ( this.format ) {

      case HIGH_FORMAT.YEAR:

        return new HighChartFormat( this.translateService.instant( 'highchart.format.year' ), 365 * 24 * 3600 * 1000 )


      case HIGH_FORMAT.MON:

        return new HighChartFormat( this.translateService.instant( 'highchart.format.month' ), 30 * 24 * 3600 * 1000 )


      case HIGH_FORMAT.DAY:

        return new HighChartFormat( this.translateService.instant( 'highchart.format.day' ), 1 * 24 * 3600 * 1000 )
    }
  }

  // action
  onLine() {

    // set struct
    var series = new Array();

    // sort data
    this.data.sort( function( a: StatisticInfo, b: StatisticInfo ) {

      if ( a.date < b.date ) return -1;
      if ( a.date > b.date ) return 1;

      return 0;
    } );

    // push data
    this.data.forEach( ( item: StatisticInfo ) => {

      // check date
      if ( item.date ) {

        series.push( [ this.getUTC( item.date ), item.counted ] );
      }
    } );

    // get format
    var format = this.getFormat();

    try {

      // set chart
      Highcharts.chart( this._element, {
        chart: {
          type: 'line'
        },
        title: {
          text: null
        },
        xAxis: {
          type: 'datetime',
          endOnTick: false,
          minTickInterval: format.interval,
          dateTimeLabelFormats: {
            day: format.date,
            hour: format.date,
            week: format.date,
            year: format.date,
            month: format.date
          },
          labels: {
            rotation: this.rotation
          }
        },
        yAxis: {
          title: {
            text: null
          },
          gridLineColor: '#e9e9e9'
        },
        legend: {
          enabled: false
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          valueDecimals: 0,
          dateTimeLabelFormats: {
            day: format.date,
            hour: format.date,
            week: format.date,
            year: format.date,
            month: format.date
          }
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        series: [ {
          data: series,
          type: 'line',
          name: this.name,
          color: '#90caf9',
          visible: true
        } ]
      } );

      // remove credits
      setTimeout( () => {

        $( this._element ).find( '.highcharts-credits' ).remove();

      }, 10 );

    } catch ( error ) {}
  }

  onColumn() {

    // set struct
    var series = new Array();
    var sorted = new Array();

    // set data
    this.data.forEach( ( statisticInfo: StatisticInfos ) => {

      // new column
      var column = new HighChartColumn( statisticInfo.name );

      // sort item
      statisticInfo.statisticInfos.sort( function( a: StatisticInfo, b: StatisticInfo ) {

        if ( a.date < b.date ) return -1;
        if ( a.date > b.date ) return 1;

        return 0;
      } );

      // push item
      statisticInfo.statisticInfos.forEach( ( statisticInfo: StatisticInfo ) => {

        column.sum += statisticInfo.counted;
        column.data.push( [ this.getUTC( statisticInfo.date ), statisticInfo.counted ] );
      } );

      // push sort
      sorted.push( column );
    } );

    // sort rank
    sorted.sort( function( a: HighChartColumn, b: HighChartColumn ) {

      if ( a.sum > b.sum ) return -1;
      if ( a.sum < b.sum ) return 1;

      return 0;
    } );

    // push series
    sorted.forEach( ( column: HighChartColumn, index: number ) => {

      series.push( {

        name: column.name,
        data: column.data,
        visible: index < 10
      } );
    } );

    // get format
    var format = this.getFormat();

    try {

      // set chart
      Highcharts.chart( this._element, {
        chart: {
          type: 'column'
        },
        title: {
          text: null
        },
        xAxis: {
          type: 'datetime',
          minTickInterval: format.interval,
          endOnTick: false,
          dateTimeLabelFormats: {
            day: format.date,
            hour: format.date,
            week: format.date,
            year: format.date,
            month: format.date
          },
          labels: {
            rotation: this.rotation
          }
        },
        yAxis: {
          title: {
            text: null
          },
          gridLineColor: '#e9e9e9'
        },
        tooltip: {
          shared: true,
          crosshairs: true,
          valueDecimals: 0,
          dateTimeLabelFormats: {
            day: format.date,
            hour: format.date,
            week: format.date,
            year: format.date,
            month: format.date
          }
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            borderWidth: 0,
            pointPadding: 0.2,
          }
        },
        series: series
      } );

      // remove credits
      setTimeout( () => {

        $( this._element ).find( '.highcharts-credits' ).remove();

        // set serialize
        if ( this.serialize ) {

          this.serialize.emit( sorted );
        }
      }, 10 );

    } catch ( error ) {}
  }
}

class HighChartFormat {

  public date: string;
  public interval: number;

  constructor( date: string, interval: number ) {

    this.date = date;
    this.interval = interval;
  }
}


export class HighChartColumn {

  public sum: number;
  public name: string;
  public data: Array < any > ;

  constructor( name: string, sum: number = 0 ) {

    this.sum = sum;
    this.name = name;
    this.data = new Array();
  }
}