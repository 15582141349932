import {
  Input,
  Output,
  Directive,
  OnChanges,
  ElementRef,
  EventEmitter
} from "@angular/core";

declare var $: any;
declare var M: any;

export class MaterializeClose {

  target: any;
  update: boolean;

  constructor( target: any, update: boolean = false ) {

    this.target = target;
    this.update = update;
  }
}

@Directive( {
  selector: '[materializeInit], [materializeOption.any], [materializeOption.data], [materializeOption.time], [materializeOption.date], [materializeOption.close], [materializeOption.cycle], [materializeOption.resize], [materializeOption.counter], [materializeOption.opacity]'
} )
export class MaterializeInit implements OnChanges {

  private _element: any;
  private _subscript: MutationObserver;

  // input
  @Input() materializeInit: string;
  @Input( 'materializeOption.any' ) any: any;
  @Input( 'materializeOption.data' ) data: any;
  @Input( 'materializeOption.resize' ) resize: boolean = false;
  @Input( 'materializeOption.counter' ) counter: boolean = false;
  @Input( 'materializeOption.opacity' ) opacity: number = 0.5;

  // output
  @Output( 'materializeOption.time' ) time: EventEmitter < Function > = new EventEmitter < Function > ();
  @Output( 'materializeOption.date' ) date: EventEmitter < Function > = new EventEmitter < Function > ();
  @Output( 'materializeOption.cycle' ) cycle: EventEmitter < Function > = new EventEmitter < Function > ();
  @Output( 'materializeOption.close' ) close: EventEmitter < MaterializeClose > = new EventEmitter < MaterializeClose > ();

  constructor( private elementRef: ElementRef ) {

    // get element
    this._element = this.elementRef.nativeElement;

    // set subscript 
    this._subscript = new MutationObserver( () => {

      // get name
      var name = this._element.tagName.toLowerCase();

      // check name
      if (
        ( this.materializeInit == 'select' && name == 'select' ) ||
        ( this.materializeInit == 'dropdown' )
      ) {

        // init materialize
        setTimeout( () => {

          this.ngOnMaterialize();
        }, 100 );

      } else if ( ( this.materializeInit == 'carousel' ) ) {

        setTimeout( () => {

          var height = 0;

          $( this._element ).find( 'a' ).each( ( index: number, element: any ) => {

            if ( height < $( element ).height() ) height = $( element ).height();
          } )

          if ( height > 0 ) {

            // set height
            $( this._element ).css( 'min-height', height + 'px' );

            // check cycle
            if ( this.cycle ) {

              this.cycle.emit( $( this._element ).find( '.carousel-item' ).eq( 0 ) );
            }
          }
        }, 100 )
      }
    } );

    // set observe
    this._subscript.observe( this._element, {

      childList: true
    } );
  }

  ngOnChanges() {

    // check name
    if (
      this.materializeInit == 'tabs' ||
      this.materializeInit == 'textarea' ||
      this.materializeInit == 'carousel'
    ) {

      // init textarea
      setTimeout( () => {

        this.ngOnMaterialize();

      }, 100 );
    }
  }

  ngOnDestroy() {

    this._subscript.disconnect();
  }

  ngAfterViewInit() {

    // update text
    setTimeout( () => {

      try {

        if ( M && M.updateTextFields ) {

          M.updateTextFields();
        }
      } catch ( error ) {

        return;
      }

      this.ngOnMaterialize();

    }, 100 );
  }

  ngOnMaterialize() {

    switch ( this.materializeInit ) {

      case 'tabs': {

        this.onTabs();
        break;
      }
      case 'modal': {

        this.onModal();
        break;
      }
      case 'chips': {

        this.onChips();
        break;
      }
      case 'select': {

        this.onSelect();
        break;
      }

      case 'sidenav': {

        this.onSideNav();
        break;
      }
      case 'parallax': {

        this.onParallax();
        break;
      }
      case 'tooltips': {

        this.onTooltips();
        break;
      }
      case 'dropdown': {

        this.onDropDown();
        break;
      }
      case 'textarea': {

        // check textarea
        if ( this._element.tagName.toLowerCase().indexOf( this.materializeInit ) > -1 ) {

          this.onTextArea( this._element );
          break;
        }

        // find textarea
        $( this._element ).find( 'textarea' ).each( ( index: number, target: any ) => {

          this.onTextArea( target );
        } );
        break;
      }
      case 'carousel': {

        this.onCarousel();
        break;
      }
      case 'datepicker': {

        this.onDatePicker();
        break;
      }
      case 'timepicker': {

        this.onTimePicker();
        break;
      }
    }
  }

  // set
  setDestroy( element: string ) {

    // get instance
    var instance = M[ element ].getInstance( this._element );

    // check instance
    if ( instance ) {

      // set destroy
      instance.destroy();

      return true;
    }

    return false;
  }

  // action
  onTabs() {

    // check data
    if ( this.data && this.data.empty() ) return;

    // check destroy 
    this.setDestroy( 'Tabs' );
  
    // init tabs 
    M.Tabs.init( this._element );
  }

  onModal() {

    // init modal
    M.Modal.init( this._element, {

      opacity: this.opacity,
      dismissible: false,

      onOpenEnd: () => {

        // update text
        if ( M && M.updateTextFields ) {

          M.updateTextFields();
        }

        // init select
        $( this._element ).find( 'select' ).each( function() {

          var instance = M.FormSelect.getInstance( this );

          if ( instance ) {

            instance.destroy();
          }

          M.FormSelect.init( this );
        } );

        // init tabs
        $( this._element ).find( '.tabs' ).each( function() {

          // set indicator
          $( this ).find( '.indicator' ).css( 'right', $( this ).width() + 'px' );
          $( this ).find( '.indicator' ).removeClass( 'none' );

          M.Tabs.getInstance( this ).updateTabIndicator();
        } );

        // init input
        $( this._element ).find( 'input[type=file]' ).each( function() {

          $( this ).val( null );
        } );

        // set scroll
        this._element.scrollTop = 0;
      },

      onOpenStart: () => {

        // init tabs
        $( this._element ).find( '.tabs' ).each( function() {

          // set indicator
          $( this ).find( '.indicator' ).addClass( 'none' );
        } );
      }
    } );
  }

  onChips() {

    // chips
    M.Chips.init( this._element, this.any );
  }

  onSelect() {

    // check destroy 
    this.setDestroy( 'FormSelect' );

    // select
    M.FormSelect.init( this._element );
  }

  onSideNav() {

    // sidenav
    M.Sidenav.init( this._element );
  }

  onParallax() {

    this.setDestroy( 'Parallax' );

    // parallax
    M.Parallax.init( this._element );
  }

  onTooltips() {

    // check data
    if ( !this.data ) {

      return;
    }

    // tooltips
    M.Tooltip.init( this._element, {

      html: this.data
    } );
  }

  onCarousel() {

    // check data
    if ( this.data.length < 1 ) return;

    // remove indicators
    $( this._element ).find( '.indicators' ).remove();

    // init carousel
    M.Carousel.init( this._element, Object.assign( {

      onCycleTo: ( element: any ) => {

        if ( this.cycle ) {

          this.cycle.emit( element );
        }
      },
    }, this.any ) );
  }

  onDropDown() {

    try {

      // check destroy 
      this.setDestroy( 'Dropdown' );

      // init dropdown
      M.Dropdown.init( this._element, Object.assign( {

        onOpenStart: () => {

          $( this._element ).children( 'span' ).addClass( 'active' );
        },
        onCloseStart: () => {

          $( this._element ).children( 'span' ).removeClass( 'active' );
        }
      }, this.any ) );
      
    } catch ( error ) {}
  }

  onTextArea( target: any = this._element ) {

    // resize
    if ( this.resize ) {

      // set resize
      M.textareaAutoResize( target );
    }

    // set counter
    if ( this.counter ) {

      // set counter
      $( target ).characterCounter();
    }
  }

  onDatePicker( format: string = 'yyyy-MM-dd' ) {

    // check destroy 
    this.setDestroy( 'Datepicker' );

    // set event
    this.date.emit( ( option: any ) => {

      // init datepicker
      M.Datepicker.init( this._element, Object.assign( option, {

        onClose: () => {

          this.close.emit( new MaterializeClose( this._element, true ) );
        }
      } ) );

      setTimeout( () => {

        // set change
        $( this._element ).on( 'change', () => {

          // set event
          this._element.dispatchEvent( new Event( 'input' ) );
        } );
      } );
    } );
  }

  onTimePicker( format: string = 'hh:mm aa' ) {

    // check destroy 
    this.setDestroy( 'Timepicker' );

    // init timepicker
    this.time.emit( ( option: any ) => {

      M.Timepicker.init( this._element, option );

      setTimeout( () => {

        // set change
        $( this._element ).on( 'change', () => {

          // set event
          this._element.dispatchEvent( new Event( 'input' ) );
        } );
      } );
    } );
  }
}