export let Config = {
  base: {
    main: {
      path: ''
    },
    sign: {
      path: 'sign'
    }
  },
  page: {
    setting: {
      eula: {
        path: 'setting/eula',
        route: null
      },
      manager: {
        path: 'setting/manager',
        route: null
      },
      category: {
        path: 'setting/category',
        route: null
      }
    },
    board: {
      post: {
        path: 'board/post',
        route: null
      },
      popup: {
        path: 'board/popup',
        route: null
      },
      banner: {
        path: 'board/banner',
        route: null
      },
      ad: {
        path: 'board/ad',
        route: null
      }
    },
    poll: {
      manager: {
        path: 'poll/manager',
        route: null
      }
    },
    user: {
      active: {
        path: 'user/:status',
        index: 0,
        route: [ 'user', 'active' ]
      },
      block: {
        path: 'user/:status',
        index: 1,
        route: [ 'user', 'block' ]
      },
      drop: {
        path: 'user/:status',
        index: 2,
        route: [ 'user', 'drop' ]
      },
    },
    reply: {
      create: {
        path: 'reply/:status',
        index: 0,
        route: [ 'reply', 'create' ]
      },
      report: {
        path: 'reply/:status',
        index: 1,
        route: [ 'reply', 'report' ]
      }
    },
    contact: {
      request: {
        path: 'contact/:status',
        index: 0,
        route: [ 'contact', 'request' ]
      }
      /*
      receipt: {
        path: 'contact/:status',
        index: 1,
        route: [ 'contact', 'receipt' ]
      },
      response: {
        path: 'contact/:status',
        index: 2,
        route: [ 'contact', 'response' ]
      } */
    },
    statistic: {
      dash: {
        path: 'statistic/dash',
        route: null
      },
      user: {
        path: 'statistic/user',
        route: null
      },
      post: {
        path: 'statistic/post',
        route: null
      },
      poll: {
        path: 'statistic/poll',
        route: null
      },
      popup: {
        path: 'statistic/popup',
        route: null
      },
      banner: {
        path: 'statistic/banner',
        route: null
      },
      ad: {
        path: 'statistic/ad',
        route: null
      }
    }
  },
  range: 5,
  index: 0,
  limit: 50,
  maximum: 9999,
  homepage: 'https://thestar.kr',
  category: {
    tv: 5,
    poll: 7
  },
  translate: {
    type: '.json',
    path: './assets/i18n/',
    base: {
      type: 1,
      code: 'ko'
    }
  }
}