import {
  EventEmitter
} from '@angular/core';

import {
  PageInfo
} from "src/app/services/http/http.classes";

// emnum
export enum PAGE_TYPE {

  //OPT : 선택적 ( optional ), NEC : 필수적 ( necessary)

  ARROW_NEC_NUM_NEC, // 화살표 필수적, 범위내 숫자 필수적. 예 ) < 11 12 13 14 15 >  - 14, 15는 disable, 오른쪽 화살표 disable
  ARROW_NEC_NUM_OPT, // 화살표 필수적, 범위내 숫자 선택적, 예 ) < 16 17 >  - 오른쪽 화살표 disable
  ARROW_OPT_NUM_NEC, // 화살표 선택적, 범위내 숫자 필수적, 예 ) < 6 7 8 9 10  - 9 , 10은 disable
  ARROW_OPT_NUM_OPT, // 화살표 선택적, 범위내 숫자 선택적, 예 ) < 6 7  
  ARROW_NONE_CENTER, // 화살표 없이 선택한 숫자가 가운데     예 ) 3 4 5 6 7 - 5번페이지 선택
}

export enum ARROW_TYPE {

  PREV,
  NEXT
}

// class
export class Pager {

  index: number;
  click: EventEmitter < number > ;
  target: any;
  identity: string;
  pageInfo: PageInfo;

  constructor( {

    index,
    click,
    target,
    pageInfo,
  }: {

    index ? : number,
    click ? : EventEmitter < number > ,
    target ? : any,
    pageInfo ? : PageInfo,
  } ) {

    if ( index ) this.index = index;
    if ( click ) this.click = click;
    if ( target ) this.target = target;
    if ( pageInfo ) this.pageInfo = pageInfo;
  }
}

export class PagerData {

  index: number;
  pageInfo: PageInfo;

  constructor( pageInfo: PageInfo = new PageInfo(), index: number = 0 ) {

    this.index = index;
    this.pageInfo = this.clone( pageInfo );
  }

  clone( object: any ) {

    try {

      return JSON.parse( JSON.stringify( object ) );
    } catch ( error ) {

      return null;
    }
  }
}

export class PaginationService {

  public push: any;
  public pageInfo: PageInfo;

  constructor() {

    this.push = {

      page: new EventEmitter()
    }
  }

  init( data: Pager ) {

    if ( data.pageInfo == undefined ) {

      return;
    }

    if ( data.index == null ) {

      data.index = 0;
    }

    // event
    this.push.page.emit( data );
  }
}