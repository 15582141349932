import {
  OnInit,
  Component
} from '@angular/core';
import {
  FormGroup,
  FormArray,
  FormControl
} from '@angular/forms';
import {
  Params,
  ActivatedRoute
} from '@angular/router';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  CONTACT_STATUS
} from 'src/app/enum/enum';
import {
  Config
} from 'src/app/app.config';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  ContactInfo,
  ManagerInfo,
  UpdContactRequest,
  SetContactRequest,
  DelContactRequest,
  GetContactsRequest,
  GetManagersRequest,
} from 'src/app/services/http/http.classes';
import {
  PagerData
} from 'src/app/services/pagination/pagination.service';


declare var $: any;
declare var M: any;

@Component( {
  selector: 'app-contact',
  styleUrls: [ './contact.component.css' ],
  templateUrl: './contact.component.html'
} )
export class ContactComponent implements OnInit {

  // data
  req: any;
  res: any;
  page: any;
  checked: boolean;
  subscription: any;

  // form
  contactForm: FormGroup;

  // enum
  CONTACT_STATUS = CONTACT_STATUS;

  constructor(
    private route: ActivatedRoute,
    private httpService: HttpService,
    private translateService: TranslateService
  ) {

    // data
    this.req = {

      updContact: new UpdContactRequest(),
      getContacts: new GetContactsRequest()
    }

    this.res = {

      contactInfo: new ContactInfo(),
      contactInfos: new Array < ContactInfo > (),
      managerInfos: new Array < ManagerInfo > (),
    }

    this.page = {

      content: new PagerData()
    }

    // form
    this.contactForm = new FormGroup( {

      checkBox: new FormArray( [] )
    } );

    // init
    this.checked = false;

    // set event
    this.subscription = {

      route: this.route.params.subscribe( ( params: Params ) => {

        // set contact type
        this.req.getContacts.type = Config.page.contact[ params.status ].index

        // get chain
        this.getChain();
      } )
    }
  }

  ngOnInit() {}

  ngOnDestroy() {

    // destroy event
    for ( let key in this.subscription ) {

      this.subscription[ key ].unsubscribe();
    }
  }

  ngOnInterface() {

    this.contactForm.controls.checkBox = new FormArray( [] );

    // form array create
    this.res.contactInfos.forEach( () => {

      ( < FormArray > this.contactForm.controls.checkBox ).controls.push( new FormControl() );
    } );

    // init
    this.checked = false;
  }

  // get 
  getActive( type: CONTACT_STATUS ) {

    return this.req.getContacts.type == type;
  }

  getManager( contactInfo: ContactInfo ) {

    var manager = this.res.managerInfos.find( ( managerInfo: ManagerInfo ) => {

      return managerInfo.managerId == contactInfo.managerId
    } );

    return manager ? manager.email : null;
  }

  // http
  getChain() {

    Promise.resolve().then( () => {

      // get manager
      return this.getManagers();
    } ).then( () => {

      // get contact
      this.getContacts();
    } ).catch( ( message: string ) => {

      // check message
      if ( message ) alert( message );
    } );
  }

  getManagers() {

    return new Promise( ( resolve, reject ) => {

      this.httpService.getManagers( new GetManagersRequest() ).subscribe( response => {

        if ( response.code ) reject( response.message );

        // set data
        this.res.managerInfos = response.managerInfos;

        resolve();
      } );
    } );
  }

  getContacts( index: number = 1, limit: number = Config.limit ) {

    // set contents
    this.req.getContacts.onInit( {

      index: limit * ( index - 1 ),
      limit: limit
    } );

    this.httpService.getContacts( this.req.getContacts ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // set data
      this.res.contactInfos = response.contactInfos;

      // set page
      this.page.content = new PagerData( response.pageInfo, this.req.getContacts.index );

      // set interface
      this.ngOnInterface();
    } );
  }

  setSubmit( type: CONTACT_STATUS ) {

    // set contact
    this.req.updContact.type = type;

    // set update
    this.httpService.updContact( this.req.updContact ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      // open
      M.Modal.getInstance( $( '.modal' ) ).close();

      // get contacts
      this.getContacts();
    } )
  }

  setContact() {

    if ( !confirm( this.translateService.instant( 'message.receipt' ) ) ) {

      return;
    }

    // init
    var contactInfos = new Array < ContactInfo > ();

    ( < FormArray > this.contactForm.controls.checkBox ).controls.forEach( ( checkbox, index ) => {

      // checked
      if ( checkbox.value ) {

        // push contact
        contactInfos.push( this.res.contactInfos[ index ] );
      }
    } );

    // check contact
    if ( contactInfos.empty() ) {

      return alert( this.translateService.instant( 'message.select' ) );
    }

    // open contact
    this.httpService.setContact( new SetContactRequest( contactInfos ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      this.getContacts();
    } );
  }

  delContact() {

    if ( !confirm( this.translateService.instant( 'message.receipt' ) ) ) {

      return;
    }

    // init
    var contactInfos = new Array < ContactInfo > ();

    ( < FormArray > this.contactForm.controls.checkBox ).controls.forEach( ( checkbox, index ) => {

      // checked
      if ( checkbox.value ) {

        // push contact
        contactInfos.push( this.res.contactInfos[ index ] );
      }
    } );

    // check contact
    if ( contactInfos.empty() ) {

      return alert( this.translateService.instant( 'message.select' ) );
    }

    // open contact
    this.httpService.delContact( new DelContactRequest( contactInfos ) ).subscribe( response => {

      if ( response.code ) return alert( response.message );

      this.getContacts();
    } );
  }

  // action
  onContact( contactInfo: ContactInfo ) {

    // set contact
    this.res.contactInfo = contactInfo;

    // set update
    this.req.updContact.onInit( contactInfo );

    // open
    M.Modal.getInstance( $( '.modal' ) ).open();
  }

  onCheckBox( event: any, total: boolean ) {

    let check: boolean = event.target.checked;

    for ( let checkbox of ( < FormArray > this.contactForm.controls.checkBox ).controls ) {

      if ( total ) {

        checkbox.setValue( event.target.checked );
      } else {

        if ( checkbox.value ) {

          check = true;
          break;
        }
      }
    }

    this.checked = check;
  }
}