import {
  Component,
  OnInit
} from '@angular/core';
import {
  Router
} from '@angular/router';

import {
  Config
} from 'src/app/app.config';
import {
  Result
} from 'src/app/services/http/http.classes';
import {
  HttpService
} from 'src/app/services/http/http.service';
import {
  PreloaderService
} from 'src/app/services/preloader/preloader.service'
import {
  NavigationService,
  Navigation
} from 'src/app/services/navigation/navigation.service'

@Component( {
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: [ './navigation.component.css' ]
} )

export class NavigationComponent implements OnInit {

  // init
  navigationInfos: Navigation[] = [];

  constructor(
    private router: Router,
    private httpService: HttpService,
    private navigationService: NavigationService,
    private preloaderService: PreloaderService ) {

    // get navigation map
    this.navigationInfos = this.navigationService.getNavigations();
  }

  ngOnInit() {

    // init select
    this.navigationInfos.forEach( ( navigation, navigationIndex ) => {

      navigation.menus.forEach( ( menu, menuIndex ) => {

        if ( this.router.url.indexOf( menu.router.join( '/' ) ) > -1 ) {

          this.navigationService.setIndexs( menuIndex, navigationIndex );
        }
      } );
    } );
  }

  // get
  getActive( index: number ) {

    return index == this.navigationService.navigationIndex;
  }

  // action
  onNavigationSelect( index: number ) {

    // set index
    this.navigationService.setIndexs( 0, index );

    // event emit
    this.navigationService.navigationEvent.emit( this.navigationService.getIndexs );
  }

  onNavigationSignOut() {

    this.httpService.getOut( null ).subscribe( response => {

      if ( response.code == Result.ADMIN_SUCCESS ) {

        this.router.navigate( [ Config.base.sign.path ] );
      }
    } );
  }
}