export class Result {

	public static readonly ADMIN_SUCCESS = 0; // { "ko": "성공" }
	public static readonly ADMIN_SUCCESS_IN = 10; // { "ko": "로그인에 성공했습니다." }
	public static readonly ADMIN_SUCCESS_OUT = 20; // { "ko": "로그아웃을 완료했습니다." }
	public static readonly ADMIN_ERROR_MANAGER_IN = 100; // { "ko": "등록되어 있는 관리자 정보와 일치하지 않습니다." }
	public static readonly ADMIN_ERROR_MANAGER_ADD = 110; // { "ko": "이미 등록되어 있는 관리자 이메일 입니다" }
	public static readonly ADMIN_ERROR_MANAGER_DELETE = 120; // { "ko": "최소 한명의 관리자는 유지되어야 합니다." }
	public static readonly ADMIN_ERROR_USER_BLOCK = 200; // { "ko": "이미 차단된 사용자입니다." }
	public static readonly ADMIN_ERROR_USER_BLOCKED = 210; // { "ko": "차단된 사용자가 아닙니다." }
	public static readonly ADMIN_ERROR_NAME = 300; // { "ko": "이미 등록된 이름입니다." }
	public static readonly ADMIN_ERROR_SUBJECT = 320; // { "ko": "이미 등록된 제목입니다." }
	public static readonly ADMIN_ERROR_RECEIPT = 400; // { "ko": "이미 접수된 문의 입니다." }
	public static readonly ADMIN_ERROR_RESPONE = 410; // { "ko": "이미 완료된 답변 입니다." }
	public static readonly ADMIN_FAILED_SESSION = 99997; // { "ko": "잘못된 접근입니다. 기술 지원팀에 문의해주세요." }
	public static readonly ADMIN_FAILED_UPLOADED = 99998; // { "ko": "클라우드에 이미지 업로드를 실패햇습니다. 기술 지원팀에 문의해주세요." }
	public static readonly ADMIN_FAILED_DATABASE = 99999; // { "ko": "데이터베이스 연결에 실패했습니다. 기술 지원팀에 문의해주세요." }

  code: number;
  message: string;

  constructor( data: any ) {

    this.code = data.code;
    this.message = data.message;
  }


	public static getMessage( code: any, language: string ) : string {
	
		code = parseInt( code );
		
		
		if ( language.toLowerCase().indexOf('ko') > -1 ) {
		
			switch ( code ) {
			
				case Result.ADMIN_SUCCESS: return '성공';
				case Result.ADMIN_SUCCESS_IN: return '로그인에 성공했습니다.';
				case Result.ADMIN_SUCCESS_OUT: return '로그아웃을 완료했습니다.';
				case Result.ADMIN_ERROR_MANAGER_IN: return '등록되어 있는 관리자 정보와 일치하지 않습니다.';
				case Result.ADMIN_ERROR_MANAGER_ADD: return '이미 등록되어 있는 관리자 이메일 입니다';
				case Result.ADMIN_ERROR_MANAGER_DELETE: return '최소 한명의 관리자는 유지되어야 합니다.';
				case Result.ADMIN_ERROR_USER_BLOCK: return '이미 차단된 사용자입니다.';
				case Result.ADMIN_ERROR_USER_BLOCKED: return '차단된 사용자가 아닙니다.';
				case Result.ADMIN_ERROR_NAME: return '이미 등록된 이름입니다.';
				case Result.ADMIN_ERROR_SUBJECT: return '이미 등록된 제목입니다.';
				case Result.ADMIN_ERROR_RECEIPT: return '이미 접수된 문의 입니다.';
				case Result.ADMIN_ERROR_RESPONE: return '이미 완료된 답변 입니다.';
				case Result.ADMIN_FAILED_SESSION: return '잘못된 접근입니다. 기술 지원팀에 문의해주세요.';
				case Result.ADMIN_FAILED_UPLOADED: return '클라우드에 이미지 업로드를 실패햇습니다. 기술 지원팀에 문의해주세요.';
				case Result.ADMIN_FAILED_DATABASE: return '데이터베이스 연결에 실패했습니다. 기술 지원팀에 문의해주세요.';
				default: return 'Unknown error';
			}
		}
	}
}

function setAttribute( parent: any, data: any ) {

  for ( let key in data ) {

    if ( data[ key ] === undefined ) continue;
    if ( parent[ key ] === undefined ) continue;

    if ( ( typeof data[ key ] ).indexOf( typeof Object ) < 0 ) {

      parent[ key ] = data[ key ];
    } else {

      setAttribute( parent, data[ key ] );
    }
  }
}


// 페이지 정보
export class PageInfo {
	
	count: number = 0; // 갯수
	maximum: number = 0; // 페이지
	
	constructor( data? : { count?, maximum ? } ) {
		
		setAttribute( this, data );
	}
}

// 번역 정보
export class TranslateInfo {
	
	translateType: number = 0; // 번역 고유번호
	manager: string = null; // 관리 이름
	code: string = null; // 코드
	
	constructor( data? : { translateType?, manager?, code ? } ) {
		
		setAttribute( this, data );
	}
}

// 약관 정보
export class EulaInfo {
	
	eulaId: string = null; // 약관 고유번호
	name: string = null; // 분류명
	subject: string = null; // 제목
	content: string = null; // 내용
	
	constructor( data? : { eulaId?, name?, subject?, content ? } ) {
		
		setAttribute( this, data );
	}
}

// 관리자 정보
export class ManagerInfo {
	
	managerId: number = 0; // 관리자 고유번호
	email: string = null; // 이메일
	created: string = null; // 등록일
	deleted: string = null; // 삭제일
	accessed: string = null; // 마지막 접속일
	
	constructor( data? : { managerId?, email?, created?, deleted?, accessed ? } ) {
		
		setAttribute( this, data );
	}
}

// 사용자 정보
export class UserInfo {
	
	userId: number = 0; // 사용자 고유번호
	email: string = null; // 이메일
	name: string = null; // 이름
	created: string = null; // 등록일
	dropInfo: DropInfo = new DropInfo(); // JSON | 탈퇴 정보
	blockInfo: BlockInfo = new BlockInfo(); // JSON | 차단 정보
	confirmInfo: ConfirmInfo = new ConfirmInfo(); // JSON | 인증 정보
	
	constructor( data? : { userId?, email?, name?, created?, dropInfo?, blockInfo?, confirmInfo ? } ) {
		
		setAttribute( this, data );
	}
}

// 탈퇴 정보
export class DropInfo {
	
	created: string = null; // 탈퇴일
	expiration: string = null; // 만료일
	
	constructor( data? : { created?, expiration ? } ) {
		
		setAttribute( this, data );
	}
}

// 차단 정보
export class BlockInfo {
	
	ended: string = null; // 종료일
	reason: string = null; // 사유
	started: string = null; // 시작일
	created: string = null; // 등록일
	
	constructor( data? : { ended?, reason?, started?, created ? } ) {
		
		setAttribute( this, data );
	}
}

// 인증 정보
export class ConfirmInfo {
	
	expired: string = null; // 만료일
	confirmed: string = null; // 인증일
	requested: string = null; // 요청일
	
	constructor( data? : { expired?, confirmed?, requested ? } ) {
		
		setAttribute( this, data );
	}
}

// 분야 정보
export class CategoryInfo {
	
	categoryType: number = 0; // 분야 분류
	menu: number = 0; // 메뉴
	name: string = null; // 제목
	sorted: number = 0; // 정렬
	manager: string = null; // 관리제목
	
	constructor( data? : { categoryType?, menu?, name?, sorted?, manager ? } ) {
		
		setAttribute( this, data );
	}
}

// 데이터 정보
export class DataInfo {
	
	label: string = null; // 라벨
	title: string = null; // 라벨내용
	photo: string = null; // 작가
	opened: string = null; // 공개일
	editor: string = null; // 편집자
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	editorInfos: EditorInfo[] = []; // JSON | 편집 목록
	
	constructor( data? : { label?, title?, photo?, opened?, editor?, subject?, mediaInfos?, editorInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 상태 정보
export class StatusInfo {
	
	marked: number = 0; // 마크수
	replied: number = 0; // 댓글수
	counted: number = 0; // 조회수
	reported: number = 0; // 신고수
	
	constructor( data? : { marked?, replied?, counted?, reported ? } ) {
		
		setAttribute( this, data );
	}
}

// 편집 정보
export class EditorInfo {
	
	type: number = 0; // 분류 0: 텍스트, 1: 이미지, 2: 유튜브, 3: 여론조사
	mark: number = 0; // 마크 0: 없음 1: 있음
	width: number = 0; // 넓이
	height: number = 0; // 높이
	content: string = null; // 내용
	caption: string = null; // 표제
	identity: number = 0; // 고유번호
	
	constructor( data? : { type?, mark?, width?, height?, content?, caption?, identity ? } ) {
		
		setAttribute( this, data );
	}
}

// 인덱스 정보
export class IndexInfo {
	
	indexType: number = 0; // 인덱스 분류
	sorted: number = 0; // 정렬
	counted: number = 0; // 갯수
	manager: string = null; // 이름
	
	constructor( data? : { indexType?, sorted?, counted?, manager ? } ) {
		
		setAttribute( this, data );
	}
}

// 게시물 정보
export class BoardInfo {
	
	boardId: number = 0; // 게시물 고유번호
	relationId: number = 0; // 연관 고유번호
	openType: number = 0; // 공개 분류
	categoryType: number = 0; // 범주 분류
	sorted: number = 0; // 대표 순서
	deleted: string = null; // 삭제일
	created: string = null; // 등록일
	manager: string = null; // 관리 이름
	dataInfo: DataInfo = new DataInfo(); // JSON | 데이터 정보
	statusInfo: StatusInfo = new StatusInfo(); // JSON | 상태 목록
	
	constructor( data? : { boardId?, relationId?, openType?, categoryType?, sorted?, deleted?, created?, manager?, dataInfo?, statusInfo ? } ) {
		
		setAttribute( this, data );
	}
}

// 댓글 정보
export class ReplyInfo {
	
	replyId: number = 0; // 댓글 고유번호
	boardId: number = 0; // 게시물 고유번호
	managerId: number = 0; // 관리자 고유번호
	openType: number = 0; // 공개 분류
	path: string = null; // 경로
	content: string = null; // 내용
	deleted: string = null; // 삭제일
	created: string = null; // 등록일
	userInfo: UserInfo = new UserInfo(); // JSON | 사용자 정보
	statusInfo: StatusInfo = new StatusInfo(); // JSON | 상태 정보
	
	constructor( data? : { replyId?, boardId?, managerId?, openType?, path?, content?, deleted?, created?, userInfo?, statusInfo ? } ) {
		
		setAttribute( this, data );
	}
}

// 연관 정보
export class RelationInfo {
	
	relationId: number = 0; // 연관 고유번호
	categoryType: number = 0; // 범주 분류
	sorted: number = 0; // 정렬
	dataInfo: DataInfo = new DataInfo(); // JSON | 데이터 정보
	
	constructor( data? : { relationId?, categoryType?, sorted?, dataInfo ? } ) {
		
		setAttribute( this, data );
	}
}

// 배너 정보
export class BannerInfo {
	
	bannerId: number = 0; // 배너 고유번호
	openType: number = 0; // 공개 분류
	link: string = null; // 링크
	sorted: number = 0; // 정렬
	subject: string = null; // 제목
	created: string = null; // 등록일
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( data? : { bannerId?, openType?, link?, sorted?, subject?, created?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 팝업 정보
export class PopupInfo {
	
	popupId: number = 0; // 팝업 고유번호
	openType: number = 0; // 공개 분류
	link: string = null; // 링크
	sorted: number = 0; // 정렬
	subject: string = null; // 제목
	created: string = null; // 등록일
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( data? : { popupId?, openType?, link?, sorted?, subject?, created?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 광고 정보
export class AdInfo {
	
	adId: number = 0; // 광고 고유번호
	openType: number = 0; // 공개 분류
	link: string = null; // 링크
	sorted: number = 0; // 정렬
	subject: string = null; // 제목
	created: string = null; // 등록일
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( data? : { adId?, openType?, link?, sorted?, subject?, created?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 여론 정보
export class PollInfo {
	
	pollId: number = 0; // 여론 고유번호
	ended: string = null; // 종료일
	sorted: number = 0; // 대표 여부
	started: string = null; // 시작일
	deleted: string = null; // 삭제일
	created: string = null; // 등록일
	manager: string = null; // 관리 제목
	subject: string = null; // 제목
	itemInfos: ItemInfo[] = []; // JSON | 항목 목록
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( data? : { pollId?, ended?, sorted?, started?, deleted?, created?, manager?, subject?, itemInfos?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 항목 정보
export class ItemInfo {
	
	itemId: number = 0; // 항목 고유번호
	subject: string = null; // 제목
	manager: string = null; // 관리 제목
	
	constructor( data? : { itemId?, subject?, manager ? } ) {
		
		setAttribute( this, data );
	}
}

// 문의 정보
export class ContactInfo {
	
	contactId: number = 0; // 문의 고유번호
	managerId: number = 0; // 관리자 고유번호
	phone: string = null; // 전화번호
	content: string = null; // 내용
	created: string = null; // 등록일
	receipted: string = null; // 접수일
	responsed: string = null; // 응답일
	userInfo: UserInfo = new UserInfo(); // JSON | 사용자 정보
	
	constructor( data? : { contactId?, managerId?, phone?, content?, created?, receipted?, responsed?, userInfo ? } ) {
		
		setAttribute( this, data );
	}
}

// 통계 정보
export class StatisticInfo {
	
	date: number = 0; // 날짜
	name: string = null; // 이름
	counted: number = 0; // 합계
	
	constructor( data? : { date?, name?, counted ? } ) {
		
		setAttribute( this, data );
	}
}

// 통계 목록 정보
export class StatisticInfos {
	
	name: string = null; // 이름
	statisticInfos: StatisticInfo[] = []; // JSON | 통계 목록
	
	constructor( data? : { name?, statisticInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 번역 목록
export class GetTranslatesRequest {
	
	
	constructor(   ) {
		
		setAttribute( this, {  } );
	}
	
	onInit( data? : {   } ) {
		
		setAttribute( this, data );
	}
}

// 번역 목록
export class GetTranslatesResponse extends Result {
	
	translateInfos: TranslateInfo[] = []; // JSON | 번역 정보
	
	constructor( data? : { translateInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 로그인
export class GetInRequest {
	
	email: string = null; // 이메일
	password: string = null; // 비밀번호
	
	constructor( email?, password ? ) {
		
		setAttribute( this, { email: email, password: password,  } );
	}
	
	onInit( data? : { email?, password ? } ) {
		
		setAttribute( this, data );
	}
}

// 로그인
export class GetInResponse extends Result {
	
	managerId: number = 0; // 관리자 고유번호
	password: string = null; // 비밀번호
	salt: string = null; // 소금값
	
	constructor( data? : { managerId?, password?, salt ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 로그아웃
export class GetOutRequest {
	
	
	constructor(   ) {
		
		setAttribute( this, {  } );
	}
	
	onInit( data? : {   } ) {
		
		setAttribute( this, data );
	}
}

// 로그아웃
export class GetOutResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 로그인 확인
export class GetCheckRequest {
	
	
	constructor(   ) {
		
		setAttribute( this, {  } );
	}
	
	onInit( data? : {   } ) {
		
		setAttribute( this, data );
	}
}

// 로그인 확인
export class GetCheckResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 약관 정보
export class GetEulasRequest {
	
	
	constructor(   ) {
		
		setAttribute( this, {  } );
	}
	
	onInit( data? : {   } ) {
		
		setAttribute( this, data );
	}
}

// 약관 정보
export class GetEulasResponse extends Result {
	
	eulaInfos: EulaInfo[] = []; // JSON | 약관 목록
	
	constructor( data? : { eulaInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 약관 수정
export class UpdEulaRequest {
	
	eulaId: number = 0; // 약관 고유번호
	subject: string = null; // 약관 제목
	content: string = null; // 약관 내용
	
	constructor( eulaId?, subject?, content ? ) {
		
		setAttribute( this, { eulaId: eulaId, subject: subject, content: content,  } );
	}
	
	onInit( data? : { eulaId?, subject?, content ? } ) {
		
		setAttribute( this, data );
	}
}

// 약관 수정
export class UpdEulaResponse extends Result {
	
	eulaInfo: EulaInfo = new EulaInfo(); // 약관 정보
	
	constructor( data? : { eulaInfo ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 관리자 목록
export class GetManagersRequest {
	
	
	constructor(   ) {
		
		setAttribute( this, {  } );
	}
	
	onInit( data? : {   } ) {
		
		setAttribute( this, data );
	}
}

// 관리자 목록
export class GetManagersResponse extends Result {
	
	managerInfos: ManagerInfo[] = []; // 관리자 목록
	
	constructor( data? : { managerInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 관리자 등록
export class SetManagerRequest {
	
	email: string = null; // 이메일
	password: string = null; // 비밀번호
	
	constructor( email?, password ? ) {
		
		setAttribute( this, { email: email, password: password,  } );
	}
	
	onInit( data? : { email?, password ? } ) {
		
		setAttribute( this, data );
	}
}

// 관리자 등록
export class SetManagerResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 관리자 수정
export class UpdManagerRequest {
	
	managerId: number = 0; // 관리자 고유번호
	salt: string = null; // 소금값
	email: string = null; // 이메일
	password: string = null; // 비밀번호
	
	constructor( managerId?, salt?, email?, password ? ) {
		
		setAttribute( this, { managerId: managerId, salt: salt, email: email, password: password,  } );
	}
	
	onInit( data? : { managerId?, salt?, email?, password ? } ) {
		
		setAttribute( this, data );
	}
}

// 관리자 수정
export class UpdManagerResponse extends Result {
	
	managerInfo: ManagerInfo = new ManagerInfo(); // 관리자 정보
	
	constructor( data? : { managerInfo ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 관리자 삭제
export class DelManagerRequest {
	
	managerInfos: ManagerInfo[] = []; // JSON | 관리자 목록
	
	constructor( managerInfos ? ) {
		
		setAttribute( this, { managerInfos: managerInfos,  } );
	}
	
	onInit( data? : { managerInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 관리자 삭제
export class DelManagerResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 사용자 목록
export class GetUsersRequest {
	
	type: number = 0; // 분류 - 0: 일반, 1: 차단, 2: 탈퇴
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	query: string = null; // 검색어
	
	constructor( type?, index?, limit?, query ? ) {
		
		setAttribute( this, { type: type, index: index, limit: limit, query: query,  } );
	}
	
	onInit( data? : { type?, index?, limit?, query ? } ) {
		
		setAttribute( this, data );
	}
}

// 사용자 목록
export class GetUsersResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // 페이지 정보
	userInfos: UserInfo[] = []; // JSON | 사용자 목록
	
	constructor( data? : { pageInfo?, userInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 사용자 정보
export class GetUserRequest {
	
	userId: number = 0; // 사용자 고유번호
	ended: string = null; // 종료일
	started: string = null; // 시작일
	
	constructor( userId?, ended?, started ? ) {
		
		setAttribute( this, { userId: userId, ended: ended, started: started,  } );
	}
	
	onInit( data? : { userId?, ended?, started ? } ) {
		
		setAttribute( this, data );
	}
}

// 사용자 정보
export class GetUserResponse extends Result {
	
	userInfo: UserInfo = new UserInfo(); // 사용자 정보
	pollInfos: PollInfo[] = []; // JSON | 투표 목록
	markInfos: BoardInfo[] = []; // JSON | 마크 목록
	replyInfos: ReplyInfo[] = []; // JSON | 댓글 목록
	reportInfos: BoardInfo[] = []; // JSON | 신고 목록
	
	constructor( data? : { userInfo?, pollInfos?, markInfos?, replyInfos?, reportInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 차단 설정
export class SetBlockRequest {
	
	userId: number = 0; // 사용자 고유번호
	ended: string = null; // 종료일
	reason: string = null; // 사유
	started: string = null; // 시작일
	
	constructor( userId?, ended?, reason?, started ? ) {
		
		setAttribute( this, { userId: userId, ended: ended, reason: reason, started: started,  } );
	}
	
	onInit( data? : { userId?, ended?, reason?, started ? } ) {
		
		setAttribute( this, data );
	}
}

// 차단 설정
export class SetBlockResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 차단 수정
export class UpdBlockRequest {
	
	userId: number = 0; // 사용자 고유번호
	ended: string = null; // 종료일
	reason: string = null; // 사유
	started: string = null; // 시작일
	
	constructor( userId?, ended?, reason?, started ? ) {
		
		setAttribute( this, { userId: userId, ended: ended, reason: reason, started: started,  } );
	}
	
	onInit( data? : { userId?, ended?, reason?, started ? } ) {
		
		setAttribute( this, data );
	}
}

// 차단 수정
export class UpdBlockResponse extends Result {
	
	blockInfo: BlockInfo = new BlockInfo(); // 차단 정보
	
	constructor( data? : { blockInfo ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 차단 해제
export class DelBlockRequest {
	
	userId: number = 0; // 사용자 고유번호
	
	constructor( userId ? ) {
		
		setAttribute( this, { userId: userId,  } );
	}
	
	onInit( data? : { userId ? } ) {
		
		setAttribute( this, data );
	}
}

// 차단 해제
export class DelBlockResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 게시물 목록
export class GetBoardsRequest {
	
	categoryType: number = 0; // 범주 분류
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	query: string = null; // 검색어
	
	constructor( categoryType?, index?, limit?, query ? ) {
		
		setAttribute( this, { categoryType: categoryType, index: index, limit: limit, query: query,  } );
	}
	
	onInit( data? : { categoryType?, index?, limit?, query ? } ) {
		
		setAttribute( this, data );
	}
}

// 게시물 목록
export class GetBoardsResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // 페이지 정보
	boardInfos: BoardInfo[] = []; // JSON | 게시물 목록
	
	constructor( data? : { pageInfo?, boardInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 게시물 정보
export class GetBoardRequest {
	
	boardId: number = 0; // 게시물 고유번호
	
	constructor( boardId ? ) {
		
		setAttribute( this, { boardId: boardId,  } );
	}
	
	onInit( data? : { boardId ? } ) {
		
		setAttribute( this, data );
	}
}

// 게시물 정보
export class GetBoardResponse extends Result {
	
	boardInfo: BoardInfo = new BoardInfo(); // JSON | 게시물 정보
	pollInfos: PollInfo[] = []; // JSON | 여론 목록
	indexInfos: IndexInfo[] = []; // JSON | 인덱스 목록
	relationInfos: RelationInfo[] = []; // JSON | 연관 목록
	
	constructor( data? : { boardInfo?, pollInfos?, indexInfos?, relationInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 게시물 등록
export class SetBoardRequest {
	
	categoryType: number = 0; // 범주 분류
	label: string = null; // 라벨
	title: string = null; // 라벨내용
	photo: string = null; // 작가
	sorted: number = 0; // 대표 순서
	opened: string = null; // 공개일
	editor: string = null; // 편집자
	subject: string = null; // 제목
	manager: string = null; // 관리 이름
	pollInfos: PollInfo[] = []; // JSON | 여론 목록
	indexInfos: IndexInfo[] = []; // JSON | 인덱스 목록
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	editorInfos: EditorInfo[] = []; // JSON | 편집 목록
	relationInfos: RelationInfo[] = []; // JSON | 연관 목록
	
	constructor( categoryType?, label?, title?, photo?, sorted?, opened?, editor?, subject?, manager?, pollInfos?, indexInfos?, mediaInfos?, editorInfos?, relationInfos ? ) {
		
		setAttribute( this, { categoryType: categoryType, label: label, title: title, photo: photo, sorted: sorted, opened: opened, editor: editor, subject: subject, manager: manager, pollInfos: pollInfos, indexInfos: indexInfos, mediaInfos: mediaInfos, editorInfos: editorInfos, relationInfos: relationInfos,  } );
	}
	
	onInit( data? : { categoryType?, label?, title?, photo?, sorted?, opened?, editor?, subject?, manager?, pollInfos?, indexInfos?, mediaInfos?, editorInfos?, relationInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 게시물 등록
export class SetBoardResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 게시물 수정
export class UpdBoardRequest {
	
	boardId: number = 0; // 게시물 고유번호
	label: string = null; // 라벨
	title: string = null; // 라벨내용
	photo: string = null; // 작가
	sorted: number = 0; // 대표 순서
	opened: string = null; // 공개일
	editor: string = null; // 편집자
	subject: string = null; // 제목
	manager: string = null; // 관리 이름
	pollInfos: PollInfo[] = []; // JSON | 여론 목록
	indexInfos: IndexInfo[] = []; // JSON | 인덱스 목록
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	editorInfos: EditorInfo[] = []; // JSON | 편집 목록
	relationInfos: RelationInfo[] = []; // JSON | 연관 목록
	
	constructor( boardId?, label?, title?, photo?, sorted?, opened?, editor?, subject?, manager?, pollInfos?, indexInfos?, mediaInfos?, editorInfos?, relationInfos ? ) {
		
		setAttribute( this, { boardId: boardId, label: label, title: title, photo: photo, sorted: sorted, opened: opened, editor: editor, subject: subject, manager: manager, pollInfos: pollInfos, indexInfos: indexInfos, mediaInfos: mediaInfos, editorInfos: editorInfos, relationInfos: relationInfos,  } );
	}
	
	onInit( data? : { boardId?, label?, title?, photo?, sorted?, opened?, editor?, subject?, manager?, pollInfos?, indexInfos?, mediaInfos?, editorInfos?, relationInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 게시물 수정
export class UpdBoardResponse extends Result {
	
	boardInfo: BoardInfo = new BoardInfo(); // JSON | 게시물 정보
	
	constructor( data? : { boardInfo ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 게시물 삭제
export class DelBoardRequest {
	
	boardInfos: BoardInfo[] = []; // JSON | 게시물 목록
	
	constructor( boardInfos ? ) {
		
		setAttribute( this, { boardInfos: boardInfos,  } );
	}
	
	onInit( data? : { boardInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 게시물 삭제
export class DelBoardResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 게시물 공개
export class OpeBoardRequest {
	
	openType: number = 0; // 공개 분류
	boardInfos: BoardInfo[] = []; // JSON | 게시물 목록
	
	constructor( openType?, boardInfos ? ) {
		
		setAttribute( this, { openType: openType, boardInfos: boardInfos,  } );
	}
	
	onInit( data? : { openType?, boardInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 게시물 공개
export class OpeBoardResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 연관 게시물 검색
export class GetRelationsRequest {
	
	categoryType: number = 0; // 범주 분류
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	query: string = null; // 검색어
	
	constructor( categoryType?, index?, limit?, query ? ) {
		
		setAttribute( this, { categoryType: categoryType, index: index, limit: limit, query: query,  } );
	}
	
	onInit( data? : { categoryType?, index?, limit?, query ? } ) {
		
		setAttribute( this, data );
	}
}

// 연관 게시물 검색
export class GetRelationsResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // 페이지 정보
	relationInfos: RelationInfo[] = []; // JSON | 연관 게시물 목록
	
	constructor( data? : { pageInfo?, relationInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 범주 목록
export class GetCategoriesRequest {
	
	
	constructor(   ) {
		
		setAttribute( this, {  } );
	}
	
	onInit( data? : {   } ) {
		
		setAttribute( this, data );
	}
}

// 범주 목록
export class GetCategoriesResponse extends Result {
	
	categoryInfos: CategoryInfo[] = []; // JSON | 범주 목록
	
	constructor( data? : { categoryInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 범주 등록
export class SetCategoryRequest {
	
	name: string = null; // 이름
	sorted: number = 0; // 정렬
	manager: string = null; // 관리 이름
	
	constructor( name?, sorted?, manager ? ) {
		
		setAttribute( this, { name: name, sorted: sorted, manager: manager,  } );
	}
	
	onInit( data? : { name?, sorted?, manager ? } ) {
		
		setAttribute( this, data );
	}
}

// 범주 등록
export class SetCategoryResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 범주 수정
export class UpdCategoryRequest {
	
	categoryType: number = 0; // 범주 분류
	name: string = null; // 이름
	sorted: number = 0; // 정렬
	manager: string = null; // 관리 이름
	
	constructor( categoryType?, name?, sorted?, manager ? ) {
		
		setAttribute( this, { categoryType: categoryType, name: name, sorted: sorted, manager: manager,  } );
	}
	
	onInit( data? : { categoryType?, name?, sorted?, manager ? } ) {
		
		setAttribute( this, data );
	}
}

// 범주 수정
export class UpdCategoryResponse extends Result {
	
	categoryInfo: CategoryInfo = new CategoryInfo(); // JSON | 범주 정보
	
	constructor( data? : { categoryInfo ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 배너 목록
export class GetBannersRequest {
	
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	
	constructor( index?, limit ? ) {
		
		setAttribute( this, { index: index, limit: limit,  } );
	}
	
	onInit( data? : { index?, limit ? } ) {
		
		setAttribute( this, data );
	}
}

// 배너 목록
export class GetBannersResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // 페이지 정보
	bannerInfos: BannerInfo[] = []; // JSON | 배너 정보
	
	constructor( data? : { pageInfo?, bannerInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 배너 등록
export class SetBannerRequest {
	
	link: string = null; // 링크
	sorted: number = 0; // 정렬
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( link?, sorted?, subject?, mediaInfos ? ) {
		
		setAttribute( this, { link: link, sorted: sorted, subject: subject, mediaInfos: mediaInfos,  } );
	}
	
	onInit( data? : { link?, sorted?, subject?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 배너 등록
export class SetBannerResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 배너 수정
export class UpdBannerRequest {
	
	bannerId: number = 0; // 배너 고유번호
	link: string = null; // 링크
	sorted: number = 0; // 정렬
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( bannerId?, link?, sorted?, subject?, mediaInfos ? ) {
		
		setAttribute( this, { bannerId: bannerId, link: link, sorted: sorted, subject: subject, mediaInfos: mediaInfos,  } );
	}
	
	onInit( data? : { bannerId?, link?, sorted?, subject?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 배너 수정
export class UpdBannerResponse extends Result {
	
	bannerInfo: BannerInfo = new BannerInfo(); // JSON | 배너 정보
	
	constructor( data? : { bannerInfo ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 배너 삭제
export class DelBannerRequest {
	
	bannerInfos: BannerInfo[] = []; // JSON | 배너 목록
	
	constructor( bannerInfos ? ) {
		
		setAttribute( this, { bannerInfos: bannerInfos,  } );
	}
	
	onInit( data? : { bannerInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 배너 삭제
export class DelBannerResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 배너 공개
export class OpeBannerRequest {
	
	openType: number = 0; // 공개 분류
	bannerInfos: BannerInfo[] = []; // JSON | 배너 목록
	
	constructor( openType?, bannerInfos ? ) {
		
		setAttribute( this, { openType: openType, bannerInfos: bannerInfos,  } );
	}
	
	onInit( data? : { openType?, bannerInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 배너 공개
export class OpeBannerResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 팝업 목록
export class GetPopupsRequest {
	
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	
	constructor( index?, limit ? ) {
		
		setAttribute( this, { index: index, limit: limit,  } );
	}
	
	onInit( data? : { index?, limit ? } ) {
		
		setAttribute( this, data );
	}
}

// 팝업 목록
export class GetPopupsResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // 페이지 정보
	popupInfos: PopupInfo[] = []; // JSON | 팝업 정보
	
	constructor( data? : { pageInfo?, popupInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 팝업 등록
export class SetPopupRequest {
	
	link: string = null; // 링크
	sorted: number = 0; // 정렬
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( link?, sorted?, subject?, mediaInfos ? ) {
		
		setAttribute( this, { link: link, sorted: sorted, subject: subject, mediaInfos: mediaInfos,  } );
	}
	
	onInit( data? : { link?, sorted?, subject?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 팝업 등록
export class SetPopupResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 팝업 수정
export class UpdPopupRequest {
	
	popupId: number = 0; // 팝업 고유번호
	link: string = null; // 링크
	sorted: number = 0; // 정렬
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( popupId?, link?, sorted?, subject?, mediaInfos ? ) {
		
		setAttribute( this, { popupId: popupId, link: link, sorted: sorted, subject: subject, mediaInfos: mediaInfos,  } );
	}
	
	onInit( data? : { popupId?, link?, sorted?, subject?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 팝업 수정
export class UpdPopupResponse extends Result {
	
	popupInfo: PopupInfo = new PopupInfo(); // JSON | 팝업 정보
	
	constructor( data? : { popupInfo ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 팝업 삭제
export class DelPopupRequest {
	
	popupInfos: PopupInfo[] = []; // JSON | 팝업 목록
	
	constructor( popupInfos ? ) {
		
		setAttribute( this, { popupInfos: popupInfos,  } );
	}
	
	onInit( data? : { popupInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 팝업 삭제
export class DelPopupResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 팝업 공개
export class OpePopupRequest {
	
	openType: number = 0; // 공개 분류
	popupInfos: PopupInfo[] = []; // JSON | 팝업 목록
	
	constructor( openType?, popupInfos ? ) {
		
		setAttribute( this, { openType: openType, popupInfos: popupInfos,  } );
	}
	
	onInit( data? : { openType?, popupInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 팝업 공개
export class OpePopupResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 광고 목록
export class GetAdsRequest {
	
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	
	constructor( index?, limit ? ) {
		
		setAttribute( this, { index: index, limit: limit,  } );
	}
	
	onInit( data? : { index?, limit ? } ) {
		
		setAttribute( this, data );
	}
}

// 광고 목록
export class GetAdsResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // 페이지 정보
	adInfos: AdInfo[] = []; // JSON | 광고 정보
	
	constructor( data? : { pageInfo?, adInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 광고 등록
export class SetAdRequest {
	
	link: string = null; // 링크
	sorted: number = 0; // 정렬
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( link?, sorted?, subject?, mediaInfos ? ) {
		
		setAttribute( this, { link: link, sorted: sorted, subject: subject, mediaInfos: mediaInfos,  } );
	}
	
	onInit( data? : { link?, sorted?, subject?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 광고 등록
export class SetAdResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 광고 수정
export class UpdAdRequest {
	
	adId: number = 0; // 광고 고유번호
	link: string = null; // 링크
	sorted: number = 0; // 정렬
	subject: string = null; // 제목
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( adId?, link?, sorted?, subject?, mediaInfos ? ) {
		
		setAttribute( this, { adId: adId, link: link, sorted: sorted, subject: subject, mediaInfos: mediaInfos,  } );
	}
	
	onInit( data? : { adId?, link?, sorted?, subject?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 광고 수정
export class UpdAdResponse extends Result {
	
	adInfo: AdInfo = new AdInfo(); // JSON | 광고 정보
	
	constructor( data? : { adInfo ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 광고 삭제
export class DelAdRequest {
	
	adInfos: AdInfo[] = []; // JSON | 광고 목록
	
	constructor( adInfos ? ) {
		
		setAttribute( this, { adInfos: adInfos,  } );
	}
	
	onInit( data? : { adInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 광고 삭제
export class DelAdResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 광고 공개
export class OpeAdRequest {
	
	openType: number = 0; // 공개 분류
	adInfos: AdInfo[] = []; // JSON | 광고 목록
	
	constructor( openType?, adInfos ? ) {
		
		setAttribute( this, { openType: openType, adInfos: adInfos,  } );
	}
	
	onInit( data? : { openType?, adInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 광고 공개
export class OpeAdResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 댓글 목록
export class GetRepliesRequest {
	
	type: number = 0; // 분류 - 0: 일반, 1: 신고
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	
	constructor( type?, index?, limit ? ) {
		
		setAttribute( this, { type: type, index: index, limit: limit,  } );
	}
	
	onInit( data? : { type?, index?, limit ? } ) {
		
		setAttribute( this, data );
	}
}

// 댓글 목록
export class GetRepliesResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // JSON | 페이지 정보
	replyInfos: ReplyInfo[] = []; // JSON | 댓글 목록
	
	constructor( data? : { pageInfo?, replyInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 댓글 삭제
export class DelReplyRequest {
	
	replyInfos: ReplyInfo[] = []; // JSON | 댓글 목록
	
	constructor( replyInfos ? ) {
		
		setAttribute( this, { replyInfos: replyInfos,  } );
	}
	
	onInit( data? : { replyInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 댓글 삭제
export class DelReplyResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 댓글 공개
export class OpeReplyRequest {
	
	openType: number = 0; // 공개 분류
	replyInfos: ReplyInfo[] = []; // JSON | 댓글 목록
	
	constructor( openType?, replyInfos ? ) {
		
		setAttribute( this, { openType: openType, replyInfos: replyInfos,  } );
	}
	
	onInit( data? : { openType?, replyInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 댓글 공개
export class OpeReplyResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 문의 목록
export class GetContactsRequest {
	
	type: number = 0; // 문의분류 0: 접수, 1: 완료
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	
	constructor( type?, index?, limit ? ) {
		
		setAttribute( this, { type: type, index: index, limit: limit,  } );
	}
	
	onInit( data? : { type?, index?, limit ? } ) {
		
		setAttribute( this, data );
	}
}

// 문의 목록
export class GetContactsResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // JSON | 페이지 정보
	contactInfos: ContactInfo[] = []; // JSON | 문의 목록
	
	constructor( data? : { pageInfo?, contactInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 문의 접수
export class SetContactRequest {
	
	contactInfos: ContactInfo[] = []; // JSON | 문의 목록
	
	constructor( contactInfos ? ) {
		
		setAttribute( this, { contactInfos: contactInfos,  } );
	}
	
	onInit( data? : { contactInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 문의 접수
export class SetContactResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 문의 수정
export class UpdContactRequest {
	
	contactId: number = 0; // 문의 고유번호
	type: number = 0; // 분류 0: 해제, 1: 접수, 2: 답변
	response: string = null; // 답변
	
	constructor( contactId?, type?, response ? ) {
		
		setAttribute( this, { contactId: contactId, type: type, response: response,  } );
	}
	
	onInit( data? : { contactId?, type?, response ? } ) {
		
		setAttribute( this, data );
	}
}

// 문의 수정
export class UpdContactResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 문의 접수 취소
export class DelContactRequest {
	
	contactInfos: ContactInfo[] = []; // JSON | 문의 목록
	
	constructor( contactInfos ? ) {
		
		setAttribute( this, { contactInfos: contactInfos,  } );
	}
	
	onInit( data? : { contactInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 문의 접수 취소
export class DelContactResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 인덱스 정보
export class GetIndexRequest {
	
	query: string = null; // 검색어
	
	constructor( query ? ) {
		
		setAttribute( this, { query: query,  } );
	}
	
	onInit( data? : { query ? } ) {
		
		setAttribute( this, data );
	}
}

// 인덱스 정보
export class GetIndexResponse extends Result {
	
	indexInfo: IndexInfo = new IndexInfo(); // JSON | 인덱스 정보
	
	constructor( data? : { indexInfo ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 여론조사 목록
export class GetPollsRequest {
	
	index: number = 0; // 현재값
	limit: number = 0; // 최대값
	query: string = null; // 검색어
	
	constructor( index?, limit?, query ? ) {
		
		setAttribute( this, { index: index, limit: limit, query: query,  } );
	}
	
	onInit( data? : { index?, limit?, query ? } ) {
		
		setAttribute( this, data );
	}
}

// 여론조사 목록
export class GetPollsResponse extends Result {
	
	pageInfo: PageInfo = new PageInfo(); // 페이지 정보
	pollInfos: PollInfo[] = []; // JSON | 여론조사 목록
	
	constructor( data? : { pageInfo?, pollInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 여론조사 등록
export class SetPollRequest {
	
	ended: string = null; // 종료일
	started: string = null; // 시작일
	manager: string = null; // 관리 제목
	subject: string = null; // 제목
	itemInfos: ItemInfo[] = []; // JSON | 항목 목록
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( ended?, started?, manager?, subject?, itemInfos?, mediaInfos ? ) {
		
		setAttribute( this, { ended: ended, started: started, manager: manager, subject: subject, itemInfos: itemInfos, mediaInfos: mediaInfos,  } );
	}
	
	onInit( data? : { ended?, started?, manager?, subject?, itemInfos?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 여론조사 등록
export class SetPollResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 여론조사 수정
export class UpdPollRequest {
	
	pollId: number = 0; // 여론 고유번호
	ended: string = null; // 종료일
	started: string = null; // 시작일
	manager: string = null; // 관리 제목
	subject: string = null; // 제목
	itemInfos: ItemInfo[] = []; // JSON | 항목 목록
	mediaInfos: EditorInfo[] = []; // JSON | 미디어 목록
	
	constructor( pollId?, ended?, started?, manager?, subject?, itemInfos?, mediaInfos ? ) {
		
		setAttribute( this, { pollId: pollId, ended: ended, started: started, manager: manager, subject: subject, itemInfos: itemInfos, mediaInfos: mediaInfos,  } );
	}
	
	onInit( data? : { pollId?, ended?, started?, manager?, subject?, itemInfos?, mediaInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 여론조사 수정
export class UpdPollResponse extends Result {
	
	pollInfo: PollInfo = new PollInfo(); // JSON | 여론조사 정보
	
	constructor( data? : { pollInfo ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 여론조사 삭제
export class DelPollRequest {
	
	pollInfos: PollInfo[] = []; // JSON | 여론조사 목록
	
	constructor( pollInfos ? ) {
		
		setAttribute( this, { pollInfos: pollInfos,  } );
	}
	
	onInit( data? : { pollInfos ? } ) {
		
		setAttribute( this, data );
	}
}

// 여론조사 삭제
export class DelPollResponse extends Result {
	
	
	constructor( data? : {   } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 통계 요약
export class StsDashRequest {
	
	
	constructor(   ) {
		
		setAttribute( this, {  } );
	}
	
	onInit( data? : {   } ) {
		
		setAttribute( this, data );
	}
}

// 통계 요약
export class StsDashResponse extends Result {
	
	statisticInfos: StatisticInfos[] = []; // JSON | 통계 목록
	
	constructor( data? : { statisticInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 통계 사용자
export class StsUserRequest {
	
	statisticType: number = 0; // 통계 분류 - 0: 일, 1: 월 2: 년
	started: string = null; // 시작일
	ended: string = null; // 종료일
	
	constructor( statisticType?, started?, ended ? ) {
		
		setAttribute( this, { statisticType: statisticType, started: started, ended: ended,  } );
	}
	
	onInit( data? : { statisticType?, started?, ended ? } ) {
		
		setAttribute( this, data );
	}
}

// 통계 사용자
export class StsUserResponse extends Result {
	
	statisticInfos: StatisticInfos[] = []; // JSON | 통계 목록
	
	constructor( data? : { statisticInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 통계 여론
export class StsPollRequest {
	
	pollId: number = 0; // 여론 고유번호
	statisticType: number = 0; // 통계 분류 - 0: 일, 1: 월 2: 년
	started: string = null; // 시작일
	ended: string = null; // 종료일
	
	constructor( pollId?, statisticType?, started?, ended ? ) {
		
		setAttribute( this, { pollId: pollId, statisticType: statisticType, started: started, ended: ended,  } );
	}
	
	onInit( data? : { pollId?, statisticType?, started?, ended ? } ) {
		
		setAttribute( this, data );
	}
}

// 통계 여론
export class StsPollResponse extends Result {
	
	dateInfos: StatisticInfos[] = []; // JSON | 통계 목록
	itemInfos: StatisticInfos[] = []; // JSON | 통계 목록
	
	constructor( data? : { dateInfos?, itemInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 통계 게시물
export class StsBoardRequest {
	
	boardId: number = 0; // 게시물 고유번호
	categoryType: number = 0; // 분야 분류
	statisticType: number = 0; // 통계 분류 - 0: 일, 1: 월 2: 년
	started: string = null; // 시작일
	ended: string = null; // 종료일
	
	constructor( boardId?, categoryType?, statisticType?, started?, ended ? ) {
		
		setAttribute( this, { boardId: boardId, categoryType: categoryType, statisticType: statisticType, started: started, ended: ended,  } );
	}
	
	onInit( data? : { boardId?, categoryType?, statisticType?, started?, ended ? } ) {
		
		setAttribute( this, data );
	}
}

// 통계 게시물
export class StsBoardResponse extends Result {
	
	statisticInfos: StatisticInfos[] = []; // JSON | 통계 목록
	
	constructor( data? : { statisticInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 통계 팝업
export class StsPopupRequest {
	
	popupId: number = 0; // 팝업 고유번호
	statisticType: number = 0; // 통계 분류 - 0: 일, 1: 월 2: 년
	started: string = null; // 시작일
	ended: string = null; // 종료일
	
	constructor( popupId?, statisticType?, started?, ended ? ) {
		
		setAttribute( this, { popupId: popupId, statisticType: statisticType, started: started, ended: ended,  } );
	}
	
	onInit( data? : { popupId?, statisticType?, started?, ended ? } ) {
		
		setAttribute( this, data );
	}
}

// 통계 팝업
export class StsPopupResponse extends Result {
	
	statisticInfos: StatisticInfos[] = []; // JSON | 통계 목록
	
	constructor( data? : { statisticInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 통계 배너
export class StsBannerRequest {
	
	bannerId: number = 0; // 배너 고유번호
	statisticType: number = 0; // 통계 분류 - 0: 일, 1: 월 2: 년
	started: string = null; // 시작일
	ended: string = null; // 종료일
	
	constructor( bannerId?, statisticType?, started?, ended ? ) {
		
		setAttribute( this, { bannerId: bannerId, statisticType: statisticType, started: started, ended: ended,  } );
	}
	
	onInit( data? : { bannerId?, statisticType?, started?, ended ? } ) {
		
		setAttribute( this, data );
	}
}

// 통계 배너
export class StsBannerResponse extends Result {
	
	statisticInfos: StatisticInfos[] = []; // JSON | 통계 목록
	
	constructor( data? : { statisticInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
// 통계 광고
export class StsAdRequest {
	
	adId: number = 0; // 광고 고유번호
	statisticType: number = 0; // 통계 분류 - 0: 일, 1: 월 2: 년
	started: string = null; // 시작일
	ended: string = null; // 종료일
	
	constructor( adId?, statisticType?, started?, ended ? ) {
		
		setAttribute( this, { adId: adId, statisticType: statisticType, started: started, ended: ended,  } );
	}
	
	onInit( data? : { adId?, statisticType?, started?, ended ? } ) {
		
		setAttribute( this, data );
	}
}

// 통계 광고
export class StsAdResponse extends Result {
	
	statisticInfos: StatisticInfos[] = []; // JSON | 통계 목록
	
	constructor( data? : { statisticInfos ? } ) {
	
		super( data );
		
		setAttribute( this, data );
	}
}
