import {
  OnInit,
  Component,
  ElementRef
} from '@angular/core';
import {
  isNumber,
  isObject
} from 'util';
import {
  TranslateService
} from '@ngx-translate/core';
import {
  STATUS
} from 'src/app/services/editor/editor.service';
import {
  Editor,
  EditorInfo,
  EditorPull,
  EditorOption,
  EditorService
} from 'src/app/services/editor/editor.service';
import {
  PollInfo
} from 'src/app/services/http/http.classes';

@Component( {
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: [ './editor.component.css' ]
} )
export class EditorComponent implements OnInit {

  // enum
  public STATUS = STATUS;
  public CONFIG = {
    height: 400,
    menubar: false,
    plugins: 'table link paste',
    toolbar: [
      'undo redo',
      'fontsizeselect',
      'forecolor backcolor bold italic underline strikethrough removeformat',
      'outdent indent blockquote',
      'alignleft aligncenter alignright alignjustify alignnone',
      'cut copy',
      'link unlink',
      'table'
    ],
    skin_url: '/assets/tinymce/skins/ui/oxide',
    statusbar: false,
    content_css: '/assets/tinymce/skins/content/default/content.min.css',
    toolbar_drawer: 'sliding',
    paste_as_text: false
  }

  // private
  private edit: boolean;
  private init: boolean;
  private focus: number;
  private editor: Editor;
  private option: EditorOption;
  private youtube: string;
  private subscription: any;

  constructor(
    private element: ElementRef,
    private editorService: EditorService,
    private translateService: TranslateService
  ) {

    // init
    this.edit = true;
    this.init = true;
    this.focus = -1;
    this.editor = new Editor();
    this.option = new EditorOption();
    this.youtube = 'https://www.youtube.com/embed/';

    // event
    this.subscription = {

      editor: this.editorService.push.subscribe( ( data: Editor ) => {

        // check element
        if ( this.element.nativeElement == data.parent ) {

          // set data
          this.editor = new Editor( data );

          // set init
          this.edit = false;
          this.init = false;

          // set poll
          this.setPoll();
        }
      } )
    }
  }

  ngOnInit() {}

  ngOnDestroy() {

    // event
    this.subscription.editor.unsubscribe();
  }

  ngAfterViewChecked() {

    try {

      // check init
      if ( this.init ) return this.setEdit();

      // get tool
      let tool = this.option.getTool;

      if ( tool ) {

        this.getElement( '.editor' ).insertBefore( tool, null );
      }

      this.init = true;

    } catch ( error ) {}
  }

  // check
  isMax( index: number ) {

    return index + 1 == this.editor.data.length;
  }

  isStatus( type: STATUS, item: EditorInfo = this.getCurrent ) {

    try {

      // check poll
      if ( item.type == STATUS.POLL ) {

        return item.type == type && this.editor.poll.length > 0;
      }

      return item.type == type;
    } catch ( error ) {

      return false;
    }
  }

  isCaption( item: EditorInfo ) {

    return item.caption && item.caption.length > 0;
  }

  get isEdit() {

    return this.editor.readonly == false;
  }

  get isPoll() {

    return this.isStatus( STATUS.POLL, this.getCurrent );
  }

  get isMedia() {

    return this.editor.media;
  }

  get isImage() {

    if ( this.editor.videoonly ) return false;

    return true;
  }

  get isVideo() {

    if ( this.editor.imageonly ) return false;

    return true;
  }

  get isEditor() {

    return this.editor.media == false;
  }

  get isMobile() {

    return /iphone|ipad|ipod|android/i.test( navigator.userAgent.toLowerCase() );
  }

  get isReadOnly() {

    return this.editor.readonly;
  }

  get isExplorer() {

    return /msie|trident|edge/i.test( navigator.userAgent.toLowerCase() );
  }

  get isImageOnly() {

    return this.editor.imageonly;
  }

  get isVideoOnly() {

    return this.editor.videoonly;
  }

  // set
  setInit() {

    // get tool
    let tool = this.option.getTool;

    if ( tool ) this.getElement( '.editor' ).appendChild( tool );
  }

  setEdit() {

    if ( this.edit ) return;

    var tool = this.getElement( '.tool' );
    var size = this.getElements( 'li', tool ).length * 30;

    // set box
    if ( this.isMedia ) {

      this.getElement( 'ul', tool ).style.minWidth = this.getStyle( size );
    } else {

      this.getElement( 'ul', tool ).style.minHeight = this.getStyle( size );
    }

    // set tool
    if ( tool ) this.option.tool = tool;

    // check readonly
    if ( this.editor.readonly ) return this.option.tool.remove();

    // check data
    if ( this.getLength < 1 ) {

      // set parent
      this.getElement( '.editor' ).appendChild( this.option.tool );
    } else {

      // get item 
      this.getElements( '.item' ).item( this.option.select ).insertBefore( this.option.tool, null );
    }

    // edit
    this.edit = true;

    // event
    this.onEditor();
  }

  setPoll() {

    for ( var poll of this.editor.poll ) {

      // get item
      var item = this.editor.data.find( ( item: EditorInfo ) => {

        return this.isStatus( STATUS.POLL, item ) && item.identity == poll.pollId;
      } )

      // check item
      if ( item ) continue;

      // insert item
      this.editor.data.splice( 0, 0, new EditorInfo( {

        type: STATUS.POLL,
        identity: poll.pollId
      } ) );
    }

    // event
    this.onEditor();
  }

  setFocus( index: number ) {

    // check focus
    if ( this.isEdit && this.isEditor && this.focus != index ) {

      // remove
      var item = this.getElements( '.item' );

      // set class
      if ( item.item( this.option.select ) ) item.item( this.option.select ).classList.remove( 'focus' );
      if ( item.item( index ) ) item.item( index ).classList.add( 'focus' );

      // set focus
      this.focus = index;
    }
  }

  // get
  getMark( item: EditorInfo ) {

    return item.content.indexOf( 'data:image' ) > -1;
  }

  getPoll( item: EditorInfo ) {

    return this.editor.poll.find( ( pollInfo: PollInfo ) => {

      return pollInfo.pollId == item.identity;
    } )
  }

  getStyle( attribute: any ) {

    // check object
    if ( isObject( attribute ) ) {

      var css = [];

      for ( let key of Object.keys( attribute ) ) {

        var value = attribute[ key ];

        if ( isNumber( value ) ) {

          css.push( `${ key }: ${ value }px` );
        } else {

          css.push( `${ key }: ${ value }` );
        }
      }

      return css.join( ';' );
    }

    return isNumber( attribute ) ? `${ attribute }px` : attribute;
  }

  getWidth( item: EditorInfo ) {

    if ( this.isMedia ) {

      return item.width > item.height ? '100%' : 'auto';
    }

    return this.getElement( '.content' ).clientWidth < item.width ? '40%' : this.getStyle( item.width );
  }

  getHeight( item: EditorInfo ) {

    if ( this.isMedia ) {

      return item.width > item.height ? 'auto' : '100%';
    }

    return 'auto';
  }

  getElement( name: string, parent: Element = this.editor.parent ): HTMLElement {

    var collection: HTMLCollection = null;

    if ( name.indexOf( '.' ) > -1 ) {

      collection = parent.getElementsByClassName( name.replace( '.', '' ) );
    } else {

      collection = parent.getElementsByTagName( name );
    }

    if ( collection.length > 0 ) {

      return <HTMLElement > collection.item( 0 );
    }

    return null;
  }

  getElements( name: string, parent: Element = this.editor.parent ): HTMLCollectionOf < Element > {

    var collection: HTMLCollection = null;

    if ( name.indexOf( '.' ) > -1 ) {

      collection = parent.getElementsByClassName( name.replace( '.', '' ) );
    } else {

      collection = parent.getElementsByTagName( name );
    }

    return collection;
  }

  get getData() {

    return this.editor.data;
  }

  get getLength() {

    try {

      return this.editor.data.length;
    } catch ( error ) {

      return 0;
    }
  }

  get getCurrent() {

    return this.editor.data[ this.option.select ];
  }

  // action
  onMark( item: EditorInfo ) {

    item.mark = item.mark ? false : true;
  }

  onEditUp() {

    // set index
    var index = this.option.select - 1;

    // check min
    if ( index < 0 ) {

      return;
    }

    // replace content
    this.editor.data.splice( index, 0, this.editor.data.splice( this.option.select, 1 )[ 0 ] );

    // set box
    this.onEditBox( index );
  }

  onEditBox( index: number ) {

    var focus = Math.min( this.getLength - 1, index );

    // set focus
    this.setFocus( focus );

    // set select
    this.option.select = focus;

    // set edit init
    this.edit = false;
  }

  // tool
  onEditDown() {

    // set index
    var index = this.option.select + 1;

    // check max
    if ( index > this.getLength - 1 ) {

      return;
    }

    // replace content
    this.editor.data.splice( index, 0, this.editor.data.splice( this.option.select, 1 )[ 0 ] );

    // set box
    this.onEditBox( index );
  }

  onEditText() {

    // insert text
    this.editor.data.splice( ++this.option.select, 0, new EditorInfo() );

    // set box
    this.onEditBox( this.option.select );
  }

  onEditVideo( insert: boolean ) {

    // get video
    var link = this.translateService.instant( 'editor.message.youtube' );
    var video = prompt( link );

    // check video
    if ( video == null || video == undefined ) return;

    // check valid
    if ( video.length != 11 ) return alert( link );

    // check insert
    if ( insert ) {

      this.editor.data.splice( ++this.option.select, 0, new EditorInfo( {

        type: STATUS.VIDEO,
        content: this.youtube + video
      } ) );
    } else {

      this.editor.data[ this.option.select ].content = this.youtube + video;
    }

    // check max item
    if ( this.isMax( this.option.select ) && this.isEditor ) {

      this.editor.data.push( new EditorInfo() );

      // increase
      this.option.select += 1;
    }

    // set box
    this.onEditBox( this.option.select );
  }

  onEditImage( input: HTMLElement ) {

    // check limit
    if ( this.editor.limit < this.editor.data.length ) {

      return alert( this.translateService.instant( 'editor.message.limit', {

        number: this.editor.limit
      } ) );
    }

    input.click();
  }

  onEditRemove() {

    // set index
    var next = this.option.select;
    var index = this.option.select;

    // check min
    if ( this.getLength < 1 ) {

      return;
    }

    // check max
    if ( this.isMax( next ) ) {

      next = next - 1;
    }

    // set init
    this.setInit();

    // remove content
    this.editor.data.splice( index, 1 );

    // set box
    this.onEditBox( next );
  }

  async onFileChange( input: any, replace: boolean = false ) {

    // set function
    var size = ( file: any ) => {

      // check size
      if ( file.size > 1024 * 1024 * 100 ) {

        alert( this.translateService.instant( 'editor.message.size' ) );

        return false;
      }

      return true;
    };

    var file = ( file: any, parent: any ) => {

      return new Promise( ( resolve ) => {

        // set image data
        var reader = new FileReader();

        reader.onload = ( readerFile: any ) => {

          // set editor data
          var image = new Image();

          image.src = readerFile.target.result;
          image.onload = () => {

            if ( replace ) {

              // replace 
              parent[ this.option.select ].mark = this.isEditor;
              parent[ this.option.select ].width = image.width;
              parent[ this.option.select ].height = image.height;
              parent[ this.option.select ].content = image.src;
            } else {

              // push
              parent.splice( ++this.option.select, 0, new EditorInfo( {

                mark: this.isEditor,
                type: STATUS.IMAGE,
                width: image.width,
                height: image.height,
                content: image.src
              } ) );
            }

            return resolve();
          }
        }

        reader.readAsDataURL( file );
      } );
    };

    // check limit
    if ( this.editor.limit < this.getLength + input.files.length ) {

      return alert( this.translateService.instant( 'editor.message.limit', {
        number: this.editor.limit
      } ) );
    }

    // get files
    for ( var item of input.files ) {

      // check size
      if ( !size( item ) ) return;

      // set file
      await file( item, this.editor.data );
    }

    // set text
    if ( this.isMax( this.option.select ) && this.isEditor ) {

      this.editor.data.push( new EditorInfo() );

      // increase
      this.option.select += 1;
    }

    // set box
    this.onEditBox( this.option.select );
  }

  // drag
  onDragEnd() {

    // set opacity
    if ( this.option.next ) this.option.next.classList.remove( 'active' );
    if ( this.option.current ) this.option.current.classList.remove( 'drag' );

    // check current
    if ( this.option.current && this.option.next ) {

      if ( this.option.current.isSameNode( this.option.next ) ) return;

      // set init
      this.setInit();

      // replace item
      var to = parseInt( this.option.next.getAttribute( 'data-index' ) );
      var from = parseInt( this.option.current.getAttribute( 'data-index' ) );
      var item = new EditorInfo( this.editor.data[ from ] );

      this.editor.data.splice( from, 1 );
      this.editor.data.splice( to, 0, item );

      this.onEditBox( to );
    }
  }

  onDragOver( event: DragEvent ) {

    // get current
    var current = < HTMLElement > ( < HTMLElement > event.target ).closest( '.item' );

    // set next
    if ( this.option.next ) {

      this.option.next.classList.remove( 'active' );
    }

    this.option.next = current;
    this.option.next.classList.add( 'active' );
  }

  onDragStart( event: DragEvent ) {

    // get current
    var current = < HTMLElement > ( < HTMLElement > event.target ).closest( '.item' );

    // set current
    this.option.current = current;

    // set opacity
    this.option.current.classList.add( 'drag' );
  }

  // editor
  onEditor() {

    this.editorService.pull.emit( new EditorPull( this.editor.uuid, this.editorService.onSerialize( this.editor.data ) ) );
  }

  onEditorFocus( index: number ) {

    // set box
    this.onEditBox( index );

    // update
    this.setEdit();
  }
}